export enum ErrorCode {
  // Functional error codes
  USER_HAS_NO_EMAIL = "USER_HAS_NO_EMAIL",
  PATIENT_OR_DEPENDENT_NOT_LINKED_TO_DOCTOR = "PATIENT_OR_DEPENDENT_NOT_LINKED_TO_DOCTOR",
  DOCTOR_PRATICE_MISMATCH = "DOCTOR_PRATICE_MISMATCH",
  LEAD_TO_PATIENT_CONVERSION_EXCEPTION = "LEAD_TO_PATIENT_CONVERSION_EXCEPTION",
  LEAD_TO_PATIENT_CONVERSION_EXCEPTION_WITH_FIELDS = "LEAD_TO_PATIENT_CONVERSION_EXCEPTION_WITH_FIELDS",
  BAD_PHONE_NUMBER = "BAD_PHONE_NUMBER",

  // Technical error codes

  // Uncategorized error code
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}
