import * as React from "react";
import { ReactNode, RefObject } from "react";
import Modal from "./Modal";

interface ModalPopupProps {
  children: ReactNode;
  closeModal: () => any;
  fullscreen?: boolean;
  noPadding?: boolean;
  id?: string;
  class?: string;
  hideCloseIcon?: boolean;
  loading?: boolean;
}

export default class ModalPopup extends React.Component<ModalPopupProps, {}> {
  private backgroundRef: RefObject<HTMLDivElement> = React.createRef();

  constructor(props: ModalPopupProps) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(): void {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount(): void {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.defaultPrevented) {
      return;
    }
    if (e.key == "Escape") {
      this.props.closeModal();
      e.preventDefault();
    }
  }

  private handleClick(e: React.MouseEvent) {
    if (e.target == this.backgroundRef.current) {
      this.props.closeModal();
    }
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal}>
        <div
          id={this.props.id}
          onClick={(e) => e.stopPropagation()}
          className={
            "modal" +
            (this.props.fullscreen ? " full-width full-height" : "") +
            (this.props.noPadding ? " no-padding" : "") +
            (this.props.class ? " " + this.props.class : "")
          }
        >
          {!this.props.hideCloseIcon && (
            <div className="panel-control">
              <i
                className={`ic_close ${this.props.loading ? "disabled" : ""}`}
                onClick={() => {
                  if (!this.props.loading) {
                    this.props.closeModal();
                  }
                }}
              />
            </div>
          )}
          {this.props.children}
        </div>
      </Modal>
    );
  }
}
