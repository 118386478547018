import Option from "./Option";

export default class SelectableOption extends Option {
  selected: boolean;
  disabled: boolean;

  constructor(value: string, label: any, selected: boolean, disabled?: boolean) {
    super(value, label);
    this.selected = selected;
    this.disabled = disabled;
  }
}
