import * as React from "react";
import Step, { StepProps } from "../Step";
import Form from "../../commons/components/form/Form";
import TextAreaInput from "../../commons/components/form/input/TextAreaInput";
import InputState from "../../commons/model/form/InputState";
import { required } from "../../commons/model/form/Validators";
import sfetch from "../../commons/utils/super-fetch";
import Event from "../../../../api/Event";
import { v7 as uuidv7 } from "uuid";
import { technicalFlowError } from "../FlowLauncher";
import StepId from "../StepId";

export default function PatientExpectationsStep(props: StepProps) {
  const uploadPatientAnswers = async (form: { [key: string]: InputState }) => {
    const payload = {};
    Object.keys(form).forEach((uid) => (payload[uid] = form[uid].value)); // Can we use getPayload here?
    await sfetch(
      `${props.flowContext.urlReportRoot}/${props.flowContext.reportId}/patient-expectation?practiceToken=${props.flowContext.practiceToken}`,
      {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          "X-Idempotency-Token": uuidv7(),
        },
      },
    );
    props.flowContext.sendEvent(Event.PATIENT_QUESTIONNAIRE_SUBMITTED);
    props.onComplete(
      {
        answersUploadedTimestamp: new Date().getTime(),
      },
      ["answersUploadedTimestamp"],
    );
  };

  return (
    <Step
      init={async () => {
        try {
          const res = await sfetch(
            `${props.flowContext.urlPracticeRoot}/${props.flowContext.practiceToken}/questions`,
            { method: "GET" },
          );
          const selectedPracticeQuestions = await res.json();
          return { selectedPracticeQuestions };
        } catch (e) {
          props.flowContext.setError(technicalFlowError);
          throw e;
        }
      }}
      {...props}
      outputs={["answersUploadedTimestamp"]}
    >
      <Form
        submit={uploadPatientAnswers}
        submitLabel={i18n["Global.Next"]}
        titleLabel={i18n["Page.ScanUploader.PatientExpectation.Title"]}
        className="text-form"
        backLink={props.backLink}
      >
        <div className="instruction">{i18n["Page.ScanUploader.PatientExpectation.Intro"]}</div>
        {props.flowContext.selectedPracticeQuestions?.map((question) => (
          <TextAreaInput
            name={question.uid}
            key={question.uid}
            label={question.value}
            validators={[required()]}
          />
        ))}
      </Form>
    </Step>
  );
}

PatientExpectationsStep.initialSteps = [StepId.QUESTIONS_SUB_STEP];
