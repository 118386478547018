import * as React from "react";
import { ReactNode } from "react";
import "./FormConfirmation.scss";
import { renderHtmlText } from "../../utils/text-rendering";

export default function FormConfirmation({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children?: ReactNode[] | ReactNode;
}) {
  return (
    <div className="form-confirmation react-form text-form">
      <div className="confirmation-title">{title}</div>
      <div className="description">{renderHtmlText(description)}</div>
      {children && <div className="content">{children}</div>}
    </div>
  );
}
