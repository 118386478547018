import * as React from "react";
import { buildClasses } from "../utils/classes";

interface AlertProps {
  className?: string;
}

function Alert(
  props: React.PropsWithChildren<{
    typeClassName: string;
    className?: string;
  }>
) {
  return (
    <div className={buildClasses("message", props.typeClassName, props.className)}>
      {props.children}
    </div>
  );
}

export function Warning(props: React.PropsWithChildren<AlertProps>) {
  return (
    <Alert typeClassName={"warning"} className={props.className}>
      {props.children}
    </Alert>
  );
}

export function Danger(props: React.PropsWithChildren<AlertProps>) {
  return (
    <Alert typeClassName={"danger"} className={props.className}>
      {props.children}
    </Alert>
  );
}

export function Success(props: React.PropsWithChildren<AlertProps>) {
  return (
    <Alert typeClassName={"valid"} className={props.className}>
      {props.children}
    </Alert>
  );
}

export function Info(props: React.PropsWithChildren<AlertProps>) {
  return (
    <Alert typeClassName={"info"} className={props.className}>
      {props.children}
    </Alert>
  );
}
