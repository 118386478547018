import * as React from "react";
import InputProps from "../../../model/form/InputProps";
import { zipCodeValidator } from "../../../model/form/Validators";
import TextInput from "./TextInput";
import Country from "../../../model/address/Country";

export interface ZipCodeInputProps extends InputProps {
  country: Country;
}

export default class ZipCodeInput extends React.PureComponent<ZipCodeInputProps> {
  render() {
    return (
      <TextInput
        {...this.props}
        validators={[
          ...(this.props.validators ?? []),
          zipCodeValidator(
            i18n["Form.Error.InvalidZipCode"],
            this.props.country.code,
            this.props.messageLevel
          ),
        ]}
      />
    );
  }
}
