import * as React from "react";
import "./TextAction.scss";

export interface TextActionProps {
  key?: string;
  id?: string;
  onClick?: (e) => any;
  href?: string;
  icon?: string;
  label?: string;
  additionalClass?: string;
  disabled?: boolean;
}

export default function TextAction({
  key,
  id,
  onClick,
  href,
  icon,
  label,
  additionalClass,
  disabled,
}: TextActionProps) {
  const content = (
    <>
      <i className={icon} />
      <span>{label}</span>
    </>
  );
  const className =
    "text-action" + (disabled && !href ? " disabled " : " ") + (additionalClass || "");

  return href ? (
    <a key={key} id={id} className={className} href={href} onClick={onClick}>
      {content}
    </a>
  ) : (
    <div key={key} id={id} className={className} onClick={onClick}>
      {content}
    </div>
  );
}
