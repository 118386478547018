import * as React from "react";
import Form from "../../commons/components/form/Form";
import { renderMultilineText } from "../../commons/utils/text-rendering";
import ScanImageInput from "../../commons/components/form/input/ScanImageInput";
import { required } from "../../commons/model/form/Validators";
import Step, { StepProps } from "../Step";
import Event from "../../../../api/Event";
import ScanOpenMouthVideoThumbnail from "../../../resources/images/scan-example/open_mouth_video_thumbnail.jpg";
import ScanExampleBottom from "../../../resources/images/scan-example/bottom.jpg";
import ScanExampleTop from "../../../resources/images/scan-example/top.jpg";
import getUploadPhoto from "./getUploadPhoto";
import "./PhotosStep.scss";
import PhotoConfiguration from "../utils/PhotoConfiguration";
import YoutubeVideoPanel from "../utils/YoutubeVideoPanel";
import StepId from "../StepId";

const openedMouthPhotoConfiguration: PhotoConfiguration[] = [
  {
    name: "OPENED_TOP",
    sample: ScanExampleTop,
    label: "Page.ScanUploader.OpenedMouthPhotos.Photo.Top",
  },
  {
    name: "OPENED_BOTTOM",
    sample: ScanExampleBottom,
    label: "Page.ScanUploader.OpenedMouthPhotos.Photo.Bottom",
  },
];

export default function OpenedMouthPhotosStep(props: StepProps) {
  const uploadPhoto = getUploadPhoto(props.flowContext);

  return (
    <Step {...props} outputs={["photoMouthOpenedSubmittedTimestamp"]}>
      <Form
        className="form-max-width"
        submit={async () => {
          props.flowContext.sendEvent(Event.PHOTO_OPENED_SUBMITTED);
          props.onComplete(
            {
              photoMouthOpenedSubmittedTimestamp: new Date().getTime(),
            },
            ["photoMouthOpenedSubmittedTimestamp"]
          );
        }}
        submitLabel={i18n["Global.Submit"]}
        titleLabel={i18n["Page.ScanUploader.OpenedMouthPhotos.Title"]}
        backLink={props.backLink}
      >
        <div className="instruction">
          {renderMultilineText(i18n["Page.ScanUploader.OpenedMouthPhotos.Instruction"])}
        </div>
        {props.flowContext.openedMouthVideoUrl && (
          <YoutubeVideoPanel
            thumbnail={ScanOpenMouthVideoThumbnail}
            url={props.flowContext.openedMouthVideoUrl}
          />
        )}
        <div className="image-input-container">
          {openedMouthPhotoConfiguration.map((it) => (
            <ScanImageInput
              key={it.name}
              name={it.name}
              label={i18n[it.label]}
              sample={it.sample}
              uploadFunction={uploadPhoto}
              validators={[required()]}
            />
          ))}
        </div>
      </Form>
    </Step>
  );
}

OpenedMouthPhotosStep.initialSteps = [StepId.PHOTOS_OPENED_SUB_STEP];
