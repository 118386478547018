import { Practice } from "../practice-management/Practice";
import GeoCoordinates from "../commons/model/address/GeoCoordinates";
import Question from "../commons/model/Question";
import Field from "../commons/model/form/Field";
import { LegalConsent } from "../legal/LegalResource";
import AppointmentDetails, { ConsultationType } from "../booking/AppointmentDetails";
import * as React from "react";
import { FlowError } from "./FlowLauncher";
import DeferredAction from "./utils/DeferredAction";
import { PatientSubStepId } from "./steps/PatientStep";
import { PatientCardWithCapabilities } from "./steps/PatientSelectorStep";
import Country from "../commons/model/address/Country";
import { VirtualConsultationConfiguration } from "../reports/Report";
import { Doctor, PracticeSearchConfiguration } from "./steps/PracticeSelectorStep";
import CompletedStep from "./utils/CompletedStep";

export default interface FlowContext {
  availablePractices: Practice[];
  availablePatients: PatientCardWithCapabilities[];
  countries: Country[];
  googleApiKey: string;
  patientGeoCoordinates: GeoCoordinates;
  practiceSearchConfiguration: PracticeSearchConfiguration;
  practiceToken: string;
  selectedCountry: Country;
  selectedPractice: Practice;
  selectedDoctor?: Doctor;
  selectedPatientId: string;
  selectedPracticeQuestions: Question[];
  urlPracticeRoot: string;
  urlReportRoot: string;
  urlPatientRoot: string;
  openedMouthVideoUrl: string;
  closedMouthVideoUrl: string;
  urlAuthenticatedFlow: string;
  reportId: string;
  answersUploadedTimestamp: number;
  photoFaceSubmittedTimestamp: number;
  photoMouthOpenedSubmittedTimestamp: number;
  photoMouthClosedSubmittedTimestamp: number;
  analysisStartedTimestamp: number;
  faceSmilingRequested: boolean;
  virtualConsultationConfiguration?: VirtualConsultationConfiguration;
  patientFields: Field[];
  patientContactInfoFields: Field[];
  patientContactInfo: any;
  responsiblePartyContactInfo: any;
  responsiblePartyFields: Field[];
  patientLegalConsent: LegalConsent;
  patientObfuscatedEmails: string[];
  patientCreationTimestamp: number;
  consultationType: ConsultationType;
  bookedAppointment: AppointmentDetails;
  plannedDeferredActions: DeferredAction[];
  bookingActionSetTimestamp: number;
  reportActionSetTimestamp: number;
  reportCreationTimestamp: number;
  expirationTimeoutInSeconds: number;
  setError: React.Dispatch<React.SetStateAction<FlowError>>;
  isPrimaryActive: boolean;
  flowId: string;
  patientSubStepId: PatientSubStepId;
  isNotAuthenticated: boolean;
  showDsoPhoneNumber: boolean;
  steps: string[];
  backupSteps: string[];
  stepsCompleted: CompletedStep[];
  backupStepsCompleted: CompletedStep[];
  alreadyRunDeferredActions: string[];
  hidePracticeReminder: boolean;
  patientCanSelectPractice: boolean;
  sendEvent: (Event) => any;
  patientCanBeAssociatedUrl: string;
  canUserAddDependent: boolean;
  currentPatientUid: string;
  initiatorCountry: Country;
  dsoPrivacyPolicyCustomText: string;
  patientMarketingEmailConsentText: string;
}

export const getPracticeToken = (flowContext: FlowContext): string => {
  return flowContext.selectedPractice
    ? flowContext.selectedPractice.practiceToken
    : flowContext.practiceToken;
};
