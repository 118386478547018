import FormConfirmation from "../../commons/components/form/FormConfirmation";
import * as React from "react";
import { useEffect } from "react";
import Step, { StepProps } from "../Step";
import Event from "../../../../api/Event";
import FlowContext from "../FlowContext";
import CompletedStep from "../utils/CompletedStep";
import StepId from "../StepId";
import { getLocalStorageAccess } from "../utils/localStorage";

export default function ReportConfirmationStep(props: StepProps) {
  useEffect(() => {
    props.setFlowContext((ctx: FlowContext) => {
      return {
        ...ctx,
        stepsCompleted: [
          ...ctx.stepsCompleted,
          new CompletedStep(StepId.VIRTUAL_CONSULTATION_CONFIRMATION_STEP, []),
        ],
      };
    });
    getLocalStorageAccess(props.flowContext.flowId).resetLocalStorageContext();
  }, []);

  return (
    <Step
      outputs={null}
      {...props}
      init={async () => {
        props.flowContext.sendEvent(Event.FLOW_COMPLETE);
      }}
    >
      <FormConfirmation
        title={i18n["Page.ScanUploader.Success.Title"]}
        description={i18n["Page.ScanUploader.Success.Content.Html"]}
      />
    </Step>
  );
}

ReportConfirmationStep.initialSteps = [];
