import * as React from "react";
import Step, { StepProps } from "../Step";
import Form from "../../commons/components/form/Form";
import GooglePlaceAutocompleteInput, {
  Usage,
} from "../../commons/components/form/input/GooglePlaceAutocompleteInput";
import { required } from "../../commons/model/form/Validators";
import TextInput from "../../commons/components/form/input/TextInput";
import GeoCoordinates from "../../commons/model/address/GeoCoordinates";
import StepId from "../StepId";
import Event from "../../../../api/Event";

export default function LocationStep(props: StepProps) {
  const googleApiKeyDisabled = document.location.host === "localhost:8080";

  return (
    <Step {...props} outputs={["patientGeoCoordinates"]} showAlreadyHaveAnAccount={true}>
      <Form
        submit={async (form) => {
          const coordinates: GeoCoordinates = form["location"].value;
          props.onComplete(
            {
              patientGeoCoordinates: coordinates,
            },
            ["patientGeoCoordinates"]
          );
          props.flowContext.sendEvent(Event.LOCATION_SEARCH);
        }}
        submitLabel={i18n["Form.VirtualConsultation.LocationSelector.Submit"]}
        titleLabel={i18n["Form.VirtualConsultation.LocationSelector.Title"]}
        className="text-form"
        backLink={props.backLink}
      >
        {googleApiKeyDisabled ? (
          <DummyAutocompleteInput />
        ) : (
          <GooglePlaceAutocompleteInput
            googleApiKey={props.flowContext.googleApiKey}
            countries={props.flowContext.countries}
            countryRestriction={
              props.flowContext.countries.length == 1 && props.flowContext.countries[0]
            }
            usage={Usage.COORDINATES}
            name="location"
            validators={[required()]}
            label={i18n["Form.VirtualConsultation.LocationSelector.Field.Location.Label"]}
            placeholder={
              i18n["Form.VirtualConsultation.LocationSelector.Field.Location.Placeholder"]
            }
            selectedLabel={
              i18n["Form.VirtualConsultation.LocationSelector.Field.SelectedLocation.Label"]
            }
          />
        )}
      </Form>
    </Step>
  );
}

function DummyAutocompleteInput() {
  return (
    <TextInput
      name="location"
      disabled={true}
      label={i18n["Form.VirtualConsultation.LocationSelector.Field.Location.Label"]}
      placeholder={i18n["Form.VirtualConsultation.LocationSelector.Field.Location.Placeholder"]}
      initialValue={{
        latitude: 0,
        longitude: 0,
      }}
    />
  );
}

LocationStep.initialSteps = [StepId.LOCATION_STEP];
