import * as React from "react";
import { ReactNode } from "react";
import ModalPopup from "./ModalPopup";
import AsyncAction from "../../../AsyncAction";

export interface ModalActionProps {
  children?: ReactNode;
  title: string;
  action: () => Promise<any>;
  cancel?: () => void;
  actionButtonLabel?: string;
  cancelButtonLabel?: string;
  actionDisabled?: boolean;
  cancelEnabled?: boolean;
  dialogClass?: string;
}

interface ModalActionStyleProps extends ModalActionProps {
  dialogClass?: string;
  actionButtonClass?: string;
  fontIcon?: string;
  iconElement?: string;
  hideCloseIcon: boolean;
}

interface ModalActionState {
  cancelEnabled: boolean;
}

export default class ModalAction extends React.Component<ModalActionStyleProps, ModalActionState> {
  constructor(props: ModalActionStyleProps) {
    super(props);
    this.state = {
      cancelEnabled: props.cancelEnabled ?? true,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<ModalActionStyleProps>,
    prevState: Readonly<ModalActionState>,
    snapshot?: any
  ) {
    if (prevProps.cancelEnabled !== this.props.cancelEnabled) {
      this.setState({
        cancelEnabled: this.props.cancelEnabled ?? true,
      });
    }
  }

  render() {
    return (
      <ModalPopup
        closeModal={this.props.cancel}
        class={"confirmation-dialog " + this.props.dialogClass}
        hideCloseIcon={this.props.hideCloseIcon}
      >
        {this.props.iconElement && (
          <div className="header h-flex centered">
            <i className={this.props.iconElement} />
          </div>
        )}
        <div className="title">{this.props.title}</div>
        <div className="content">{this.props.children}</div>
        <div
          className={
            "buttons h-flex full-width" + (this.props.cancel ? " space-between" : " centered")
          }
        >
          {this.props.cancel && (
            <button
              className="button-secondary cancel filled"
              onClick={this.props.cancel}
              disabled={!this.state.cancelEnabled}
            >
              {this.props.cancelButtonLabel ? this.props.cancelButtonLabel : i18n["Global.Cancel"]}
            </button>
          )}
          <AsyncAction
            buttonType={"button-primary " + this.props.actionButtonClass}
            buttonLabel={this.props.actionButtonLabel}
            promise={this.props.action}
            disabled={this.props.actionDisabled}
            fontIcon={this.props.fontIcon}
          />
        </div>
      </ModalPopup>
    );
  }
}
