import * as React from "react";
import "./IconWithTooltip.scss";
import { sanitize } from "../utils/sanitize";

interface IconWithTooltipProps {
  icon: string;
  tooltipText: string;
}

export default function IconWithTooltip({ icon, tooltipText }: IconWithTooltipProps) {
  return (
    <div className={"tooltip"}>
      <i className={"icon " + icon} />
      <span
        className={"tooltip-text"}
        dangerouslySetInnerHTML={{
          __html: sanitize(tooltipText),
        }}
      ></span>
    </div>
  );
}
