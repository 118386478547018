import FlowContext from "../FlowContext";
import { deferredActions } from "./DeferredAction";

export function getLocalStorageAccess(localStorageName): LocalStorageAccess {
  let inMemoryLocalStorageValue;
  const inMemoryLocalStorage = {
    getItem: () => inMemoryLocalStorageValue,
    setItem: (name, value) => (inMemoryLocalStorageValue = value),
    removeItem: () => (inMemoryLocalStorageValue = undefined),
  };

  function protectedLocalStorageAccess() {
    try {
      return window.localStorage;
    } catch (e) {
      console.warn("Cannot access localstorage.", e);
      return inMemoryLocalStorage;
    }
  }

  function getLocalStorageContext(): FlowContext | undefined {
    const lsJson = protectedLocalStorageAccess().getItem(localStorageName);
    if (lsJson) {
      let ls: LocalStorage;
      try {
        ls = JSON.parse(lsJson);
      } catch (e) {}
      if (ls && ls.expiration >= new Date().getTime()) {
        const context = ls?.context;
        if (context) {
          context.plannedDeferredActions = context.plannedDeferredActionIds.map(
            (name) => deferredActions[name]
          );
        }
        return context;
      } else {
        resetLocalStorageContext();
      }
    }
  }

  function setLocalStorageContext(context: FlowContext, expirationTimeoutInSeconds: number) {
    const lsContext: LocalStorageContext = {
      ...context,
      plannedDeferredActionIds: context.plannedDeferredActions.map((it) => it.name),
    };
    protectedLocalStorageAccess().setItem(
      localStorageName,
      JSON.stringify({
        context: lsContext,
        expiration: new Date().getTime() + expirationTimeoutInSeconds * 1000,
      } as LocalStorage)
    );
  }

  function resetLocalStorageContext() {
    protectedLocalStorageAccess().removeItem(localStorageName);
  }

  return {
    getLocalStorageContext,
    setLocalStorageContext,
    resetLocalStorageContext,
  };
}

export interface LocalStorageAccess {
  getLocalStorageContext: () => FlowContext | undefined;
  setLocalStorageContext: (context: FlowContext, expirationTimeoutInSeconds: number) => any;
  resetLocalStorageContext: () => any;
}

interface LocalStorage {
  context: LocalStorageContext;
  expiration: number;
}

interface LocalStorageContext extends FlowContext {
  plannedDeferredActionIds: string[];
}
