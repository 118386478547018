import InputState from "../model/form/InputState";
import Field, { FieldType } from "../model/form/Field";

export function getPayload(form: { [key: string]: InputState }, fields: string[]): {} {
  return fields.reduce(
    (acc, field) => ((acc[field] = form[field] && getValue(form[field])), acc),
    {}
  );
}

export function getValue(formField: InputState) {
  const formFieldValue = formField.value
  let value = formFieldValue || formFieldValue === false ? formFieldValue : undefined;
  if (value instanceof Date) {
    return formatDate(value);
  }
  return value;
}

export function formatDate(value: Date) {
  return (
    value.getFullYear() +
    "-" +
    (value.getMonth() < 9 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1) +
    "-" +
    (value.getDate() < 10 ? "0" + value.getDate() : value.getDate())
  );
}

export function readInitialValuesFromQueryString(fields: Field[]) {
  const queryParams = new URLSearchParams(window.location.search);
  return fields.reduce((acc, field) => {
    let value: any = queryParams.get(field.key);
    switch (field.type) {
      case FieldType.DATE:
        if (value) {
          value = new Date(isNaN(value) ? value : Number(value));
          if (isNaN(value.getTime())) {
            value = null;
          }
        }
        break;
      case FieldType.PHONE_NUMBER:
        if (value && value.startsWith(" ")) {
          // handle + in phone number url that represents a whitespace. + should be represented as url %2B
          value = "+" + value.substring(1);
        }
        break;
    }
    acc[field.name] = value;
    return acc;
  }, {});
}
