import * as React from "react";
import { useEffect, useRef } from "react";
import { Practice } from "../../practice-management/Practice";
import { Loader } from "@googlemaps/js-api-loader";
import "./PracticeMap.scss";

export default function PracticeMap({
  apiKey,
  practices,
}: {
  apiKey: string;
  practices: Practice[];
}) {
  const mapContainerRef = useRef<any>();
  const mapRef = useRef<google.maps.Map>();
  const mapBoundsRef = useRef<google.maps.LatLngBounds>();
  const practiceLocationsRef = useRef<{
    [key: string]: {
      practice: Practice;
      position: { lat: number; lng: number };
      infoWindow: google.maps.InfoWindow;
      marker: google.maps.marker.AdvancedMarkerElement;
    };
  }>(
    practices.reduce((acc, practice) => {
      if (practice.address.coordinates) {
        acc[practice.practiceToken] = {
          practice,
          position: {
            lat: practice.address.coordinates.latitude,
            lng: practice.address.coordinates.longitude,
          },
        };
      }
      return acc;
    }, {})
  );
  const practiceLocations = practiceLocationsRef.current;

  useEffect(() => {
    const loader = new Loader({
      apiKey,
      version: "3.58",
      libraries: ["core"],
    });

    loader
      .importLibrary("maps")
      .then(({ Map, InfoWindow }) => {
        mapBoundsRef.current = new google.maps.LatLngBounds();
        mapRef.current = new Map(mapContainerRef.current, {
          mapId: "DEMO_MAP_ID",
        });

        google.maps.event.addListener(mapRef.current, "zoom_changed", () => {
          if (mapRef.current["protectZoom"]) {
            const onBoundChanged = google.maps.event.addListener(
              mapRef.current,
              "bounds_changed",
              () => {
                if (mapRef.current.getZoom() > 16) mapRef.current.setZoom(16);
                mapRef.current["protectZoom"] = false;
                google.maps.event.removeListener(onBoundChanged);
              }
            );
          }
        });

        loader
          .importLibrary("marker")
          .then(({ AdvancedMarkerElement }) => {
            Object.keys(practiceLocations).forEach((practiceToken) => {
              const { position } = practiceLocations[practiceToken];
              practiceLocations[practiceToken].marker = new AdvancedMarkerElement({
                position,
                map: mapRef.current,
              });
              practiceLocations[practiceToken].infoWindow = new InfoWindow({
                content: buildInfoWindowContent(practiceLocations[practiceToken].practice),
              });
              mapBoundsRef.current.extend(position);
            });
            mapRef.current["protectZoom"] = true;
            mapRef.current.fitBounds(mapBoundsRef.current);
          })
          .catch((e) => {
            console.log("Error loading marker library: " + e);
          });
      })
      .catch((e) => {
        console.log("Error loading maps library: " + e);
      });
  }, []);

  return <div className="map-container" ref={mapContainerRef} />;
}

function buildInfoWindowContent(practice: Practice) {
  let html = "<div class='in-map-info'>";
  html += `<div class="name">${practice.name}</div>`;
  html += `<div class="address">${practice.address.displayAddress}</div>`;
  html += `<div class="text-align-center"><div id="in-map-select" class="small button-primary">${i18n["Page.ScanUploader.SelectPractice.Submit"]}</div></div>`;
  html += "</div>";
  return html;
}
