import * as React from "react";
import { sanitize } from "./sanitize";
import moment from "moment";
import { dateWithOptionalTime } from "./date";

export function renderMultilineText(text = "") {
  return (
    <>
      {text.split("\n").map((line, i) => (
        <p key={i}>{line}</p>
      ))}
    </>
  );
}

export function unescapePathOf(path: string) {
  return decodeURIComponent(decodeURIComponent(path));
}

export function renderHtmlText(html = "") {
  return <div dangerouslySetInnerHTML={{ __html: sanitize(html) }}></div>;
}

export function fillTemplateString(templateString: string, parameters: { [key: string]: string }) {
  return templateString.replace(/{{([a-zA-Z0-9_-]+)}}/g, (match, argument) =>
    parameters[argument] !== undefined ? parameters[argument] : match
  );
}

export function getLocalePrice(amount: number, currency: string): String {
  return new Intl.NumberFormat(lang, {
    style: "currency",
    currency: currency,
  }).format(amount);
}

export function getLocalePercentage(amount: number): String {
  return new Intl.NumberFormat(lang, {
    style: "percent",
  }).format(amount * 0.01);
}

export function formatBirthDateAndAge(birthDate: string) {
  if (birthDate == null) {
    return undefined;
  }
  const age = fillTemplateString(i18n["Global.Age"], {
    age: moment().diff(moment(birthDate), "years").toString(),
  });
  return `${dateWithOptionalTime(birthDate)} (${age})`;
}
