import { FormBackLinkProps } from "../commons/components/form/FormBackLink";
import StepId from "./StepId";
import FlowContext, { getPracticeToken } from "./FlowContext";
import DeferredAction from "./utils/DeferredAction";
import * as React from "react";
import { useEffect, useState } from "react";
import { requestContainerResizing } from "./utils/events";
import { buildClasses } from "../commons/utils/classes";
import ForceLoginPopupModal from "./utils/ForceLoginPopupModal";
import openAuthenticatedFlow from "./utils/openAuthenticatedFlow";
import ProgressBar from "./utils/ProgressBar";
import { PracticeDetailsCard } from "../practice-management/PracticeDetailsCard";
import LoadingSpinner from "./utils/LoadingSpinner";
import LocationStep from "./steps/LocationStep";
import PracticeSelectorStep from "./steps/PracticeSelectorStep";
import PatientSelectorStep from "./steps/PatientSelectorStep";
import VirtualConsultationStep from "./steps/VirtualConsultationStep";
import PatientExpectationsStep from "./steps/PatientExpectationsStep";
import FacePhotosStep from "./steps/FacePhotosStep";
import OpenedMouthPhotosStep from "./steps/OpenedMouthPhotosStep";
import ClosedMouthPhotosStep from "./steps/ClosedMouthPhotosStep";
import PatientStep from "./steps/PatientStep";
import VirtualConsultationConfirmationStep from "./steps/ReportConfirmationStep";
import AlreadyHaveAnAccount from "./utils/AlreadyHaveAnAccount";
import CompletedStep from "./utils/CompletedStep";

interface FlowProps {
  active: boolean;
  switchAction?: FormBackLinkProps;
  stepIds: StepId[];
  flowContext: FlowContext;
  setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>;
  postComplete?: (flowContextUpdate: Partial<FlowContext>, outputs) => any;
  deferredAction?: DeferredAction;
  onStepChange?: (step: StepId) => any;
  isRootFlow?: boolean;
  preventLSOnComplete?: boolean;
}

export default function Flow({
  active,
  switchAction,
  stepIds,
  flowContext,
  setFlowContext,
  deferredAction,
  postComplete,
  onStepChange,
  preventLSOnComplete,
  isRootFlow = true,
}: FlowProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState<ActiveStep>({ id: stepIds[0] });
  const CurrentStep = stepComponents[activeStep.id];
  const practiceToken = getPracticeToken(flowContext);

  useEffect(() => {
    if (isRootFlow && active && !flowContext.steps) {
      const steps: string[] = stepIds.reduce((acc, stepId) => {
        let initialSteps = [];
        if (stepComponents[stepId].computeInitialSteps !== undefined) {
          initialSteps = stepComponents[stepId].computeInitialSteps(flowContext);
        } else {
          initialSteps = stepComponents[stepId].initialSteps;
        }
        return [...acc, ...initialSteps];
      }, []);
      setFlowContext((ctx) => ({ ...ctx, steps }));
    }
  }, [active]);

  useEffect(() => {
    let firstStep;
    if (CurrentStep.computeInitialSteps !== undefined) {
      firstStep = CurrentStep.computeInitialSteps(flowContext)[0];
    } else {
      firstStep = CurrentStep.initialSteps[0];
    }
    const isScroll =
      activeStep.id != stepIds[0] ||
      (flowContext.patientSubStepId && firstStep != flowContext.patientSubStepId);
    requestContainerResizing(isScroll);
    onStepChange && onStepChange(activeStep.id);
  }, [activeStep.id]);

  const canSwitchToStep = (stepId: StepId): boolean => {
    switch (stepId) {
      case StepId.VIRTUAL_CONSULTATION_STEP:
      case StepId.QUESTIONS_SUB_STEP:
      case StepId.PATIENT_STEP:
        return false;
      default:
        return true;
    }
  };
  const showLoginPopupState = useState<boolean>();
  const [showLoginPopup, setShowLoginPopup] = showLoginPopupState;

  const computePreviousStepIds = (activeStepId: StepId, acc?: StepId[]) => {
    let previousStepIds = acc ?? [];
    const previousStepId = stepIds[stepIds.indexOf(activeStepId) - 1];
    previousStepIds = [...previousStepIds, previousStepId];
    if (
      stepIds[0] !== previousStepId &&
      flowContext.stepsCompleted
        .filter((step) => step.autoCompleted)
        .map((step) => step.id)
        .includes(previousStepId)
    ) {
      return computePreviousStepIds(previousStepId, previousStepIds);
    } else {
      return previousStepIds;
    }
  };

  let backLink: FormBackLinkProps;
  if (stepIds[0] === activeStep.id) {
    backLink = switchAction;
  } else {
    const previousStepIds: StepId[] = computePreviousStepIds(activeStep.id);
    if (canSwitchToStep(stepIds[stepIds.indexOf(previousStepIds[previousStepIds.length - 1])])) {
      backLink = {
        onClick: () => {
          setIsLoading(true);

          const flowContextUpdate = {};
          const outputsToDelete = previousStepIds.reduce(
            (acc, stepId) => [
              ...acc,
              ...flowContext.stepsCompleted.filter((step) => step.id == stepId)[0].outputs,
            ],
            []
          );
          outputsToDelete.forEach((it) => (flowContextUpdate[it] = undefined));
          flowContextUpdate["stepsCompleted"] = flowContext.stepsCompleted.filter(
            (step) => !previousStepIds.map((id) => id.valueOf()).includes(step.id)
          );
          setFlowContext((ctx) => ({ ...ctx, ...flowContextUpdate }));

          setActiveStep((step) => ({
            id: previousStepIds[previousStepIds.length - 1],
            reset: true,
          }));
          CurrentStep.outputs;
        },
        label: i18n["Form.Back"],
      };
    } else {
      backLink = undefined;
    }
  }

  return (
    <div className={buildClasses({ none: () => !active })}>
      {isRootFlow && flowContext.isNotAuthenticated && flowContext.selectedPractice && (
        <>
          {flowContext.selectedPractice.patientPortalEnabled && (
            <AlreadyHaveAnAccount
              practiceToken={practiceToken}
              authenticatedFlowUrl={flowContext.urlAuthenticatedFlow}
            />
          )}
          {showLoginPopup && (
            <ForceLoginPopupModal
              goToLogin={() =>
                openAuthenticatedFlow(flowContext.urlAuthenticatedFlow, practiceToken)
              }
              onClose={() => setShowLoginPopup(false)}
            />
          )}
        </>
      )}
      {isRootFlow && active && (
        <ProgressBar
          steps={flowContext.steps}
          stepsCompleted={flowContext.stepsCompleted.map((step) => step.id)}
        />
      )}
      <CurrentStep
        flowContext={flowContext}
        setFlowContext={setFlowContext}
        onInit={(flowContextUpdate) => {
          setFlowContext((ctx) => ({ ...ctx, ...flowContextUpdate }));
          setIsLoading(false);
        }}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showLoginPopupState={showLoginPopupState}
        backLink={backLink}
        onComplete={async (flowContextUpdate, outputs, autoCompleted) => {
          setIsLoading(true);
          setFlowContext((ctx) => {
            if (!ctx.stepsCompleted.map((step) => step.id).includes(activeStep.id)) {
              ctx = { ...ctx };
              ctx.stepsCompleted = [
                ...ctx.stepsCompleted,
                new CompletedStep(activeStep.id, outputs, autoCompleted),
              ];
            }
            return ctx;
          });

          let activeId;
          const nextStepIndex = stepIds.indexOf(activeStep.id) + 1;

          const actions: DeferredAction[] = [
            ...flowContext.plannedDeferredActions,
            ...(flowContextUpdate.plannedDeferredActions ?? []),
          ];
          if (nextStepIndex === stepIds.length) {
            if (deferredAction && !actions.includes(deferredAction)) {
              actions.push(deferredAction);
              flowContextUpdate.plannedDeferredActions = actions;
            }
            if (postComplete) {
              postComplete(flowContextUpdate, []);
              return;
            }
            activeId = activeStep.id;
          } else {
            activeId = stepIds[nextStepIndex];
          }
          const nextCtx = { ...flowContext, ...flowContextUpdate };
          await Promise.all(
            actions
              .filter(
                (action) =>
                  action.getInputs(flowContext).every((it) => nextCtx[it]) &&
                  !nextCtx.alreadyRunDeferredActions.includes(action.name)
              )
              .map(async (action) => {
                const result = await action.action(nextCtx);
                setFlowContext((ctx) => {
                  ctx = { ...ctx, ...result };
                  ctx.plannedDeferredActions = [...ctx.plannedDeferredActions];
                  ctx.plannedDeferredActions.splice(ctx.plannedDeferredActions.indexOf(action), 1);
                  if (!ctx.alreadyRunDeferredActions.includes(action.name)) {
                    ctx.alreadyRunDeferredActions.push(action.name);
                  }
                  return ctx;
                });
                return result;
              })
          );

          setFlowContext((ctx) => ({ ...ctx, ...flowContextUpdate }));
          setActiveStep({ id: activeId });
        }}
        preventLSOnComplete={preventLSOnComplete}
      />
      {isRootFlow &&
        !flowContext.isNotAuthenticated &&
        flowContext.selectedPractice &&
        !flowContext.hidePracticeReminder && (
          <div className="practice-reminder-container">
            <div className="practice-reminder">
              <PracticeDetailsCard practiceDetails={flowContext.selectedPractice} />
            </div>
          </div>
        )}
      {isLoading && <LoadingSpinner />}
    </div>
  );
}

export interface ActiveStep {
  id: StepId;
}

const stepComponents: Record<StepId, any> = {
  LOCATION_STEP: LocationStep,
  PRACTICE_SELECTOR_STEP: PracticeSelectorStep,
  PATIENT_SELECTOR_STEP: PatientSelectorStep,
  VIRTUAL_CONSULTATION_STEP: VirtualConsultationStep,
  QUESTIONS_SUB_STEP: PatientExpectationsStep,
  PHOTOS_FACE_SUB_STEP: FacePhotosStep,
  PHOTOS_OPENED_SUB_STEP: OpenedMouthPhotosStep,
  PHOTOS_CLOSED_SUB_STEP: ClosedMouthPhotosStep,
  PATIENT_STEP: PatientStep,
  VIRTUAL_CONSULTATION_CONFIRMATION_STEP: VirtualConsultationConfirmationStep,
};
