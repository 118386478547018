import * as React from "react";
import FormInput from "./FormInput";
import InputProps from "../../../model/form/InputProps";
import { buildClasses } from "../../../utils/classes";
import InputState from "../../../model/form/InputState";
import SelectableOption from "../../../model/SelectableOption";
import { sanitize } from "../../../utils/sanitize";

export interface MultiSelectCheckboxInputProps extends InputProps {
  options: SelectableOption[];
  columnView?: boolean;
  showSelectAllButton?: boolean;
}

export default function MultiSelectCheckboxInput(props: MultiSelectCheckboxInputProps) {
  return (
    <FormInput
      {...props}
      template={(state, input) => MultiSelectCheckBoxInputTemplate({ ...props, state, input })}
      initialValue={props.options.filter((option) => option.selected).map((option) => option.value)}
    />
  );
}

interface MultiSelectCheckboxInputTemplateProps extends MultiSelectCheckboxInputProps {
  state: InputState;
  input;
}

export function MultiSelectCheckBoxInputTemplate(props: MultiSelectCheckboxInputTemplateProps) {
  const { state, input } = props;

  const hasSelectedItem = (value) => value.size > 0;

  const invertSelection = (state: InputState, input) => {
    if (hasSelectedItem(state)) {
      input.setValue([]);
    } else {
      input.setValue(props.options.map((option) => option.value));
    }
  };

  const changeCheckbox = (e) => {
    if (e.target.checked) {
      input.setValue([...state.value, e.target.id]);
    } else {
      const valueSet = new Set(state.value);
      valueSet.delete(e.target.id);
      input.setValue(Array.from(valueSet));
    }
    input.dirty();
  };

  const selection = new Set(state.value);
  return (
    <div
      className={buildClasses(
        {
          [props.additionalClass]: () => !!props.additionalClass,
          invalid: () => state.status.invalid && !state.status.pristine,
        },
        "input-group"
      )}
    >
      <div className={"multi-checkboxes-container" + (state.status.disabled ? " disabled" : "")}>
        {props.label ? (
          <label htmlFor={props.name}>
            <span>{props.label}</span>
            <span className="detail">{props.detail}</span>
          </label>
        ) : null}
        {props.showSelectAllButton && props.options.length > 1 ? (
          <button
            className="button-secondary small selection-button"
            type="button"
            onClick={() => invertSelection(state, input)}
          >
            {hasSelectedItem(state)
              ? i18n["Page.CustomerSupport.UnselectAll"]
              : i18n["Page.CustomerSupport.SelectAll"]}
          </button>
        ) : null}
        <div
          className={props.columnView ? " v-flex multi-checkboxes-container-vertical" : "h-flex"}
        >
          {props.options.map((option) => (
            <div className="checkbox-container" key={option.value}>
              <input
                type="checkbox"
                name={props.name}
                id={option.value}
                onChange={(e) => {
                  changeCheckbox(e);
                }}
                onBlur={input.dirty}
                checked={selection.has(option.value)}
                disabled={state.status.disabled}
              />
              <label htmlFor={option.value}>
                {typeof option.label == "string" ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitize(option.label),
                    }}
                  />
                ) : (
                  option.label
                )}
              </label>
            </div>
          ))}
        </div>
        <div className="error-message">{state.status.errorMessage}</div>
      </div>
    </div>
  );
}
