function closeVerticalDropdown(container, target) {
  const nextHeight = container.dataset.maxHeight
    ? container.dataset.maxHeight
    : "0px";
  const delta = computeDelta(nextHeight, container.clientHeight + "px");
  container.style.maxHeight = nextHeight;
  container.classList.remove("active");
  target.classList.remove("active");
  triggerVerticalResize(container, delta);
}

function openVerticalDropdown(container, target) {
  const nextHeight = getHeightToDisplay(container) + "px";
  const delta = computeDelta(nextHeight, container.clientHeight + "px");
  container.style.maxHeight = nextHeight;
  container.classList.add("active");
  target.classList.add("active");
  triggerVerticalResize(container, delta);
}

function toggleVerticalDropdown(container, target) {
  if (container.classList.contains("active")) {
    closeVerticalDropdown(container, target);
  } else {
    openVerticalDropdown(container, target);
  }
}

function createVerticalDropdown(target) {
  const container = target.querySelector(".container-vertical-dropdown");
  const verticalResizeHandler = function (event) {
    if (event.target !== container) {
      container.style.maxHeight = applyDelta(
        container.style.maxHeight,
        event.detail.delta
      );
    }
  };
  container.listeners = { "vertical-resize": verticalResizeHandler };
  const verticalDropdownToggle = target.querySelector(
    ".vertical-dropdown-toggle"
  );
  if (verticalDropdownToggle) {
    // A toggle is set in the DOM. We will use click mode.
    verticalDropdownToggle.listeners = {
      click: function () {
        toggleVerticalDropdown(container, target);
      },
    };
  } else {
    // If there is no toggle, we will use hover to activate the dropdown menu.
    target.listeners = {
      mouseover: function () {
        openVerticalDropdown(container, target);
      },
      mouseout: function () {
        closeVerticalDropdown(container, target);
      },
    };
  }
  addEventListeners(target);
  addEventListeners(container);
  addEventListeners(verticalDropdownToggle);
}

function removeVerticalDropdown(target) {
  removeEventListeners(target);
  removeEventListeners(target.querySelector(".container-vertical-dropdown"));
  removeEventListeners(target.querySelector(".vertical-dropdown-toggle"));
}

function addEventListeners(element) {
  if (element && element.listeners) {
    Object.keys(element.listeners).forEach(function (event) {
      element.addEventListener(event, element.listeners[event]);
    });
  }
}

function removeEventListeners(element) {
  if (element && element.listeners) {
    Object.keys(element.listeners).forEach(function (event) {
      element.removeEventListener(event, element.listeners[event]);
    });
  }
}

function computeDelta(nextHeight, currentHeight) {
  return removeSuffix(nextHeight) - removeSuffix(currentHeight) + "px";
}

function applyDelta(delta, currentHeight) {
  return removeSuffix(delta) + removeSuffix(currentHeight) + "px";
}

function removeSuffix(dim) {
  return Number(dim.slice(0, -2));
}

function computeHeight(elt) {
  if (elt.offsetParent) {
    const maxHeight = elt.dataset.maxHeight;
    const contentSize = getHeightToDisplay(elt);
    if (maxHeight && contentSize > getSizeAsPixelInt(maxHeight)) {
      elt.classList.add("max-height-reached");
    } else {
      elt.classList.remove("max-height-reached");
    }

    if (elt.classList.contains("active")) {
      elt.style.maxHeight = contentSize + "px";
    } else {
      elt.style.maxHeight = maxHeight || "0px";
    }
  }
}

function getSizeAsPixelInt(size) {
  if (size.endsWith("px")) {
    return size.substring(0, size.length - 2);
  } else if (size.endsWith("rem")) {
    return (
      parseFloat(getComputedStyle(document.documentElement).fontSize) *
      size.substring(0, size.length - 3)
    );
  }
  return size;
}

function getHeightToDisplay(elt) {
  let height = 0;
  elt.childNodes.forEach(function (child) {
    height += child.clientHeight || 0;
    if (child.nodeType === document.ELEMENT_NODE) {
      const style = window.getComputedStyle(child);
      if (style.marginTop) {
        height += removeSuffix(style.marginTop);
      }
      if (style.marginBottom) {
        height += removeSuffix(style.marginBottom);
      }
    }
  });
  const style = window.getComputedStyle(elt);
  if (style.paddingTop) {
    height += removeSuffix(style.paddingTop);
  }
  if (style.paddingBottom) {
    height += removeSuffix(style.paddingBottom);
  }
  if (style.borderTopWidth) {
    const borderTopWidth = +style.borderTopWidth.replace("px", "");
    height += borderTopWidth;
  }
  height = height && height + 1;
  return height;
}

function triggerVerticalResize(elt, delta) {
  const verticalResizeEvent = document.createEvent("CustomEvent");
  verticalResizeEvent.initCustomEvent("vertical-resize", true, false, {
    delta: delta,
  });
  elt.dispatchEvent(verticalResizeEvent);
}

function triggerResize(elt) {
  const resizeEvent = document.createEvent("Event");
  resizeEvent.initEvent("resize", true, false);
  elt.dispatchEvent(resizeEvent);
}

function reAttach() {
  document.querySelectorAll(".vertical-dropdown").forEach(function (dropdown) {
    removeVerticalDropdown(dropdown);
    createVerticalDropdown(dropdown);
  });
}

window.addEventListener("resize", function () {
  document
    .querySelectorAll(".container-vertical-dropdown")
    .forEach(computeHeight);
});

document.addEventListener("DOMContentLoaded", function () {
  reAttach();
  setTimeout(function () {
    const transitions = document.createElement("style");
    transitions.innerHTML =
      '.container-vertical-dropdown {transition: .5s ease;} .vertical-dropdown-toggle [class="ic_chevron_down"] {transition: transform .3s;}';
    document.head.appendChild(transitions);
  });
});

document.addEventListener("click", function (e) {
  let node = e.target,
    closestDropdown;
  do {
    if (node.classList.contains("vertical-dropdown")) {
      closestDropdown = node;
      break;
    }
    node = node.parentNode;
  } while (node && node.classList);
  document.querySelectorAll(".vertical-dropdown").forEach(function (dropdown) {
    if (
      !dropdown.classList.contains("auto-locked") &&
      dropdown !== closestDropdown
    ) {
      closeVerticalDropdown(
        dropdown.querySelector(".container-vertical-dropdown"),
        dropdown
      );
    }
  });
});

const VerticalDropdownManager = {
  triggerVerticalResize,
  triggerResize,
  computeDelta,
  reAttach,
};

window["verticalDropdownUtil"] = VerticalDropdownManager;

export default VerticalDropdownManager;
