import * as React from "react";
import { useEffect, useRef, useState } from "react";
import FormInput from "./FormInput";
import InputProps from "../../../model/form/InputProps";
import InputState from "../../../model/form/InputState";
import {
  defaultContainerHeight,
  setContainerHeight,
} from "../../../../flow/utils/iframeManagement";
import { buildClasses } from "../../../utils/classes";
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import "./GooglePlaceAutocompleteInput.scss";
import Country from "../../../model/address/Country";

interface GooglePlaceAutocompleteInputProps extends InputProps {
  googleApiKey: string;
  countries: Country[];
  countryRestriction?: Country;
  usage: Usage;
  selectedLabel: string;
}

export enum Usage {
  COORDINATES = "COORDINATES",
  ADDRESS = "ADDRESS",
}

const GoogleFields = {
  [Usage.COORDINATES]: ["geometry", "formatted_address"],
  [Usage.ADDRESS]: ["formatted_address", "address_components", "geometry"],
};

const GoogleTypes = {
  [Usage.COORDINATES]: ["(regions)"],
  [Usage.ADDRESS]: ["address"],
};

export default function GooglePlaceAutocompleteInput(props: GooglePlaceAutocompleteInputProps) {
  return (
    <FormInput
      {...props}
      template={(state: InputState, input) => (
        <GooglePlaceAutocompleteTemplate {...props} state={state} input={input} />
      )}
    />
  );
}

interface GooglePlaceAutocompleteTemplateProps extends GooglePlaceAutocompleteInputProps {
  state: InputState;
  input: any;
}

function GooglePlaceAutocompleteTemplate(props: GooglePlaceAutocompleteTemplateProps) {
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const [placeNode, setPlaceNode] = useState<HTMLInputElement>();
  const [scriptReady, setScriptReady] = useState<boolean>();

  useEffect(() => {
    if (!window?.google?.maps?.places?.Autocomplete) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${props.googleApiKey}&libraries=places`;
      script.async = true;
      script.addEventListener("load", () => setScriptReady(true));
      document.body.appendChild(script);
    } else {
      setScriptReady(true);
    }
    return () => setContainerHeight(defaultContainerHeight);
  }, []);

  useEffect(() => {
    if (scriptReady && placeNode) {
      const options: AutocompleteOptions = {
        fields: GoogleFields[props.usage],
        types: GoogleTypes[props.usage],
      };
      if (props.countryRestriction) {
        options.componentRestrictions = {
          country: props.countryRestriction.code,
        };
      }
      autoCompleteRef.current = new google.maps.places.Autocomplete(placeNode, options);
      autoCompleteRef.current.addListener("place_changed", onChange);
    }
  }, [scriptReady, placeNode]);

  const [displayValue, setDisplayValue] = useState<string>(props.state.value?.displayAddress);

  const onChange = () => {
    props.input.dirty();
    const place = autoCompleteRef.current.getPlace();
    switch (props.usage) {
      case Usage.COORDINATES:
        props.input.setValue({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
        placeNode.value = "";
        setDisplayValue(place.formatted_address);
        break;
      case Usage.ADDRESS:
        const countryCode = findValueFromGooglePlace(place, "country").short_name.toLowerCase();
        const country = props.countries.find((it) => it.code.toLowerCase() === countryCode);
        if (country) {
          // Maintain the mapping between Google and our data in the back: GoogleGeocodingResultConverter.kt
          props.input.setValue({
            number: findValueFromGooglePlace(place, "street_number")?.long_name,
            route:
              findValueFromGooglePlace(place, "route")?.long_name ??
              findValueFromGooglePlace(place, "street_address")?.long_name,
            city:
              findValueFromGooglePlace(place, "locality")?.long_name ??
              findValueFromGooglePlace(place, "postal_town")?.long_name,
            zipCode: findValueFromGooglePlace(place, "postal_code")?.long_name,
            state: findValueFromGooglePlace(place, "administrative_area_level_1")?.long_name,
            country,
            displayAddress: place.formatted_address,
            coordinates: {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            },
          });
          placeNode.value = "";
          setDisplayValue(place.formatted_address);
        } else {
          props.input.setInvalid(true, i18n["Form.Error.UnsupportedCountry"]);
        }
        break;
    }
  };

  return (
    <div
      className={buildClasses(
        {
          invalid: () => props.state.status.invalid && !props.state.status.pristine,
          "with-value": () => !!displayValue,
        },
        "input-group"
      )}
    >
      {props.label && (
        <label htmlFor={props.name}>
          <span>{props.label}</span>
          <span className="detail">{props.detail}</span>
        </label>
      )}
      <div className="group">
        <input
          type="text"
          ref={setPlaceNode}
          placeholder={props.placeholder}
          onChange={() => {
            if (placeNode.value) {
              setContainerHeight(() => document.documentElement.scrollHeight);
            } else {
              setContainerHeight(defaultContainerHeight);
            }
          }}
          onBlur={() => setContainerHeight(defaultContainerHeight)}
        />
      </div>
      <div className="input-accessories">
        <div className="error-message">{props.state.status.errorMessage}</div>
      </div>
      {displayValue && (
        <div className="google-autocomplete-value-field">
          <label>
            <span>{props.selectedLabel}</span>
          </label>
          <div>{displayValue}</div>
        </div>
      )}
    </div>
  );
}

function findValueFromGooglePlace(place: google.maps.places.PlaceResult, key: string) {
  return place.address_components.find((it) => it.types.includes(key));
}
