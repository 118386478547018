import * as React from "react";
import "./ToggleButton.scss";

interface ToggleButtonProps {
  value: boolean;
  onChange: (newValue: boolean) => any;
  onChangeDisabled?: () => any;
  disabled?: boolean;
  label?: string;
  id?: string;
}

export default function ToggleButton({
  value,
  onChange = () => 1,
  onChangeDisabled,
  disabled = false,
  label,
  id,
}: ToggleButtonProps) {
  const isDisabled = disabled ? " disabled" : "";

  return (
    <div className="toggle-button-container">
      <button
        id={id}
        type="button"
        onClick={() => (disabled && onChangeDisabled ? onChangeDisabled() : onChange(!value))}
        className={"toggle-button" + (value ? " on" : "") + isDisabled}
        disabled={disabled}
      />
      <span className={isDisabled}>
        {label || (value ? i18n["ToggleButton.On"] : i18n["ToggleButton.Off"])}
      </span>
    </div>
  );
}
