import * as Sentry from "@sentry/browser";
import { getEventForPhoto } from "../utils/events";
import sfetch from "../../commons/utils/super-fetch";
import { v7 as uuidv7 } from "uuid";
import { technicalFlowError } from "../FlowLauncher";
import FlowContext, { getPracticeToken } from "../FlowContext";

export default function getUploadPhoto(flowContext: FlowContext) {
  let uploadPhotoPromise = Promise.resolve();

  return async function uploadPhoto(photoBytes: string | Blob, photoName: string) {
    try {
      const body = new FormData();
      body.append("data", photoBytes);
      const promise = uploadPhotoPromise.then(async () => {
        await sfetch(
          `${flowContext.urlReportRoot}/${
            flowContext.reportId
          }/photos/${photoName}?practiceToken=${getPracticeToken(flowContext)}`,
          {
            method: "PUT",
            body,
            headers: {
              "X-Idempotency-Token": uuidv7(),
            },
          },
        );
        flowContext.sendEvent(getEventForPhoto(photoName));
      });
      uploadPhotoPromise = promise;
      return promise;
    } catch (e) {
      Sentry.captureException(e);
      flowContext.setError(technicalFlowError);
    }
  };
}
