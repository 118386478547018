import * as React from "react";
import FormInput from "./FormInput";
import InputProps from "../../../model/form/InputProps";
import InputState from "../../../model/form/InputState";
import GenericInput from "./GenericInput";

interface TextInputProps extends InputProps {
  maxLength?: number;
}

const TextInput = (props: TextInputProps) => {
  const template = (state: InputState, input: any) => (
    <GenericInput
      type="text"
      name={props.name}
      label={props.label}
      detail={props.detail}
      additionalClass={props.additionalClass}
      status={state.status}
      placeholder={props.placeholder}
      messageLevel={props.messageLevel}
      value={state.value}
      disabled={props.disabled}
      maxLength={props.maxLength > 1 ? props.maxLength : null}
      onChange={input.change}
      onBlur={input.dirty}
      customErrors={props.customErrors}
    />
  );
  return <FormInput {...props} template={template} />;
};

export default TextInput;
