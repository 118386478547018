import * as React from "react";
import Form from "../../commons/components/form/Form";
import { renderMultilineText } from "../../commons/utils/text-rendering";
import ScanImageInput from "../../commons/components/form/input/ScanImageInput";
import { required } from "../../commons/model/form/Validators";
import Step, { StepProps } from "../Step";
import Event from "../../../../api/Event";
import PhotoConfiguration from "../utils/PhotoConfiguration";
import ScanClosedMouthVideoThumbnail from "../../../resources/images/scan-example/closed_mouth_video_thumbnail.jpg";
import ScanExampleLeft from "../../../resources/images/scan-example/left.jpg";
import ScanExampleFront from "../../../resources/images/scan-example/front.jpg";
import ScanExampleRight from "../../../resources/images/scan-example/right.jpg";
import getUploadPhoto from "./getUploadPhoto";
import "./PhotosStep.scss";
import YoutubeVideoPanel from "../utils/YoutubeVideoPanel";
import StepId from "../StepId";

const closedMouthPhotoConfiguration: PhotoConfiguration[] = [
  {
    name: "CLOSED_FRONT",
    sample: ScanExampleFront,
    label: "Page.ScanUploader.ClosedMouthPhotos.Photo.Front",
  },
  {
    name: "CLOSED_RIGHT",
    sample: ScanExampleRight,
    label: "Page.ScanUploader.ClosedMouthPhotos.Photo.Right",
  },
  {
    name: "CLOSED_LEFT",
    sample: ScanExampleLeft,
    label: "Page.ScanUploader.ClosedMouthPhotos.Photo.Left",
  },
];

export default function ClosedMouthPhotosStep(props: StepProps) {
  const uploadPhoto = getUploadPhoto(props.flowContext);
  return (
    <Step outputs={["photoMouthClosedSubmittedTimestamp"]} {...props}>
      <Form
        className="form-max-width"
        submit={async () => {
          props.flowContext.sendEvent(Event.PHOTO_CLOSED_SUBMITTED);
          props.onComplete(
            {
              photoMouthClosedSubmittedTimestamp: new Date().getTime(),
            },
            ["photoMouthClosedSubmittedTimestamp"]
          );
        }}
        submitLabel={i18n["Global.Next"]}
        titleLabel={i18n["Page.ScanUploader.ClosedMouthPhotos.Title"]}
        backLink={props.backLink}
      >
        <div className="instruction">
          {renderMultilineText(i18n["Page.ScanUploader.ClosedMouthPhotos.Instruction"])}
        </div>
        {props.flowContext.closedMouthVideoUrl && (
          <YoutubeVideoPanel
            thumbnail={ScanClosedMouthVideoThumbnail}
            url={props.flowContext.closedMouthVideoUrl}
          />
        )}
        <div className="image-input-container">
          {closedMouthPhotoConfiguration.map((it) => (
            <ScanImageInput
              key={it.name}
              name={it.name}
              label={i18n[it.label]}
              sample={it.sample}
              uploadFunction={uploadPhoto}
              validators={[required()]}
            />
          ))}
        </div>
      </Form>
    </Step>
  );
}

ClosedMouthPhotosStep.initialSteps = [StepId.PHOTOS_CLOSED_SUB_STEP];
