import * as React from "react";
import FormInput from "./FormInput";
import InputProps from "../../../model/form/InputProps";
import { buildClasses } from "../../../utils/classes";
import InputState from "../../../model/form/InputState";

interface TextAreaInputProps extends InputProps {
  maxLength?: number;
  disabled?: boolean;
  rows?: number;
}

export default class TextAreaInput extends React.Component<TextAreaInputProps> {
  constructor(props: TextAreaInputProps) {
    super(props);
    this.template = this.template.bind(this);
  }

  template(state: InputState, input) {
    const extraProps: any = {};
    if (this.props.maxLength > 1) {
      extraProps.maxLength = this.props.maxLength;
    }
    if (this.props.disabled) {
      extraProps.disabled = this.props.disabled;
    }
    return (
      <div
        className={buildClasses(
          {
            [this.props.additionalClass]: () => !!this.props.additionalClass,
            invalid: () => state.status.invalid && !state.status.pristine,
          },
          "input-group"
        )}
      >
        {this.props.label && (
          <label htmlFor={this.props.name}>
            <span>{this.props.label}</span>
            <span className="detail">{this.props.detail}</span>
          </label>
        )}
        <div className="group">
          <textarea
            name={this.props.name}
            id={this.props.name}
            placeholder={this.props.placeholder}
            onChange={input.change}
            onBlur={input.dirty}
            value={state.value}
            rows={this.props.rows || 4}
            {...extraProps}
          />
        </div>
        <div className="input-accessories">
          <div className="error-message">{state.status.errorMessage}</div>
          {this.props.maxLength && (
            <div className="size-limit">
              {state.value.length}/{this.props.maxLength}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return <FormInput {...this.props} template={this.template} />;
  }
}
