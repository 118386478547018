import * as React from "react";
import InputProps from "../../../model/form/InputProps";
import FormInput from "./FormInput";
import DatePicker from "react-date-picker";
import { buildClasses } from "../../../utils/classes";
import InputState from "../../../model/form/InputState";

export default class DateInput extends React.Component<InputProps> {
  constructor(props: InputProps) {
    super(props);
    this.template = this.template.bind(this);
  }

  private isValid = true;
  private dateValidator = () => !this.isValid;

  private value: Date;

  private onChange(date, input) {
    this.value = date;
    if (
      date == null ||
      (date instanceof Date && date < new Date() && date > new Date(1900, 0, 1))
    ) {
      this.isValid = true;
      input.setValue(date);
    } else {
      this.isValid = false;
    }
  }

  private onInvalidChange() {
    this.isValid = false;
  }

  // LongTermFix error should not be displayed while the input is focused
  template(state: InputState, input) {
    return (
      <div
        className={buildClasses(
          {
            [this.props.additionalClass]: () => !!this.props.additionalClass,
            invalid: () =>
              (state.status.invalid && !state.status.pristine) || this.props.customErrors?.isErrors,
            none: () => state.status.hidden,
          },
          "input-group date-calendar-input-group"
        )}
      >
        <label htmlFor={this.props.name}>
          <span>{this.props.label}</span>
          <span className="detail">{this.props.detail}</span>
        </label>
        <div
          className="group h-flex"
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget as Node)) {
              if (this.isValid) {
                input.dirty();
              } else if (this.value > new Date()) {
                input.setError(i18n["DatePicker.Validator.Maximum"], true);
              } else {
                input.setError(i18n["DatePicker.Validator.Missing"], true);
              }
            }
          }}
        >
          <DatePicker
            onChange={(date) => this.onChange(date, input)}
            value={input.state.value}
            dayPlaceholder={i18n["DatePicker.Day.Placeholder"]}
            monthPlaceholder={i18n["DatePicker.Month.Placeholder"]}
            yearPlaceholder={i18n["DatePicker.Year.Placeholder"]}
            className={"date-calendar" + (this.props.disabled ? " disabled" : "")}
            locale={navigator.language}
            calendarIcon={null}
            clearIcon={null}
            openCalendarOnFocus={false}
            disabled={this.props.disabled}
            onInvalidChange={() => this.onInvalidChange()}
          />
          {!state.status.invalid &&
            this.isValid &&
            !this.props.customErrors?.isErrors &&
            !state.status.pristine &&
            !this.props.disabled && (
              <div className="h-flex centered valid">
                <i className="ic_check" />
              </div>
            )}
        </div>
        {this.props.customErrors?.isErrors && (
          <div className="error-message">{this.props.customErrors.message}</div>
        )}
        {!this.props.customErrors?.isErrors && (
          <div className="error-message">{state.status.errorMessage}</div>
        )}
      </div>
    );
  }

  render() {
    return (
      <FormInput
        {...this.props}
        template={this.template}
        validators={[...(this.props.validators || []), this.dateValidator]}
      />
    );
  }
}
