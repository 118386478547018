import * as React from "react";
import ModalPopup from "../../commons/components/modal/ModalPopup";

export default function ExpirationPopupModal({ onClose }: { onClose: () => any }) {
  return (
    <ModalPopup closeModal={onClose} class="confirmation-dialog">
      <div className="title">{i18n["Page.ScanUploader.Expiration.Popup.Title"]}</div>
      <div className="content">{i18n["Page.ScanUploader.Expiration.Popup.Content"]}</div>
      <div className="buttons centered">
        <button className="button-primary filled" onClick={onClose}>
          {i18n["Global.Refresh"]}
        </button>
      </div>
    </ModalPopup>
  );
}
