export function buildClasses(
  ...classes: ({ [className: string]: boolean | (() => boolean) } | string | null | undefined)[]
): string {
  return classes
    .filter((v) => v !== undefined && v !== null)
    .map((element) => {
      if (typeof element === "object") {
        return Object.keys(element).filter((className) => {
          const value = element[className];
          return value instanceof Function ? value() : value;
        });
      } else {
        return element;
      }
    })
    .flat()
    .join(" ");
}

export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
