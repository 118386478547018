import * as React from "react";
import { parsePhoneNumber } from "libphonenumber-js";

interface PhoneNumberProps {
  number?: string;
}

export default class PhoneNumber extends React.PureComponent<PhoneNumberProps> {
  render() {
    if (this.props.number)
      return <a href={`tel:${this.props.number}`}>{this.formatPhoneNumber(this.props.number)}</a>;
    else return null;
  }

  formatPhoneNumber(number: string): string {
    try {
      return parsePhoneNumber(number).formatNational();
    } catch (e) {
      return number;
    }
  }
}
