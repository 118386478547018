import * as React from "react";

interface RadioButtonProps {
  name: string;
  value: string;
  id: string;
  checked: boolean;
  label: string;
  onChange: (e) => void;
  disabled?: boolean;
}

export default function RadioButton({
  name,
  value,
  id,
  checked,
  label,
  onChange,
  disabled,
}: RadioButtonProps) {
  return (
    <div className={"radio-button" + (disabled ? " disabled" : "")}>
      <input
        type="radio"
        name={name}
        value={value}
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}
