import * as React from "react";
import Form from "../../commons/components/form/Form";
import { Practice } from "../../practice-management/Practice";
import { FormBackLinkProps } from "../../commons/components/form/FormBackLink";
import InputState from "../../commons/model/form/InputState";
import Field, { FieldKey } from "../../commons/model/form/Field";
import ConsentCheckboxInput from "../../legal/ConsentCheckboxInput";
import { LegalConsent } from "../../legal/LegalResource";
import Option from "../../commons/model/Option";
import Checkbox from "../../commons/components/noform/Checkbox";
import FormInput from "../../commons/components/form/input/FormInput";
import Country from "../../commons/model/address/Country";

interface ContactInfoFormProps {
  configuration: ContactInfoConfiguration;
  practice: Practice;
  countries: Country[];
  backLink?: FormBackLinkProps;
  initiatorCountry: Country;
  dsoPrivacyPolicyCustomText?: string;
}

export default function ContactInfoForm(props: ContactInfoFormProps) {
  const configuration = props.configuration;
  const extraProps = {
    ...configuration.extraProps
  };
  const contactInfoExtraProps = getContactInfoExtraProps(
    props.practice,
    props.countries,
    props.initiatorCountry
  );
  Object.keys(contactInfoExtraProps).forEach((prop) => {
    extraProps[prop] = {
      ...extraProps[prop],
      ...contactInfoExtraProps[prop]
    };
  });
  const inputFields = configuration.fields.filter(field => field.key != FieldKey.ACCEPT_MARKETING_EMAIL)
  const fields = Form.getFieldComponents(
    inputFields,
    extraProps,
    configuration.titleGetter
  );
  const [checked, setChecked] = React.useState(false);
  const acceptMarketingEmailField = configuration.fields.find(field => field.key == FieldKey.ACCEPT_MARKETING_EMAIL)
  const handleChange = (state: InputState) => {
    state.value = !checked
    setChecked(!checked);
  };
  const checkBoxFormInput =
    configuration.patientMarketingEmailConsentText &&
    acceptMarketingEmailField?.available &&
      <FormInput
      name = {acceptMarketingEmailField.name}
      template={(state: InputState) => {
        state.value = checked
        return (
            <Checkbox
              name={acceptMarketingEmailField.name}
              key={acceptMarketingEmailField.name}
              checked={checked}
              onChange={() => handleChange(state)}
              option={
                new Option(
                  acceptMarketingEmailField.name, configuration.patientMarketingEmailConsentText
                )
              }
            />
        )
      }}
      initialValue={checked}
    />

  return (
    <Form
      submit={configuration.onSubmit}
      submitLabel={configuration.labels.submitLabel}
      titleLabel={configuration.labels.titleLabel}
      backLink={props.backLink}
      className="text-form"
      onUpdate={configuration.onUpdate}
    >
      <>
        {fields}
        {configuration.legalConsent && (
          <ConsentCheckboxInput
            name="legalDocuments"
            legalResources={configuration.legalConsent.legalResources}
            country={getCountryToSetup(props.practice, props.countries, props.initiatorCountry)}
            practiceToken={configuration.legalConsent.practiceToken}
            onError={configuration.onLegalDocumentsLoadError}
            dsoPrivacyPolicyCustomText={props.dsoPrivacyPolicyCustomText}
          />
        )}
        {checkBoxFormInput}
      </>
    </Form>
  );
}

export interface ContactInfoConfiguration {
  onUpdate?: (changedInput: InputState, form: { [key: string]: InputState }) => any;
  onSubmit: (form: { [key: string]: InputState }) => Promise<any>;
  fields: Field[];
  labels: ContactInfoFormLabels;
  legalConsent?: LegalConsent;
  patientMarketingEmailConsentText?: string;
  titleGetter?: (string) => string;
  extraProps?: { [key: string]: {} };
  onLegalDocumentsLoadError?: () => any;
}

function getContactInfoExtraProps(
  practice: Practice,
  availableCountries: Country[],
  initiatorCountry: Country
) {

  let country = getCountryToSetup(practice, availableCountries, initiatorCountry);
  return {
    email: {
      practiceToken: practice?.practiceToken
    },
    cellPhoneNumber: {
      selectedCountry: country,
      availableCountries
    },
    zipCode: {
      country: country
    }
  };
}

function getCountryToSetup(
  practice: Practice,
  availableCountries: Country[],
  initiatorCountry: Country
) {
  let defaultCountry = new Country("", "", "");
  if (practice) {
    defaultCountry = practice.address.country;
  } else if (initiatorCountry) {
    defaultCountry = initiatorCountry;
  } else if (availableCountries.find((c) => c.code == navigator.language.split("-")[1])) {
    defaultCountry = availableCountries.find((c) => c.code == navigator.language.split("-")[1]);
  }
  return defaultCountry;
}

interface ContactInfoFormLabels {
  submitLabel: string;
  titleLabel: string;
}
