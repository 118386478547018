import * as React from "react";
import { useState } from "react";
import getUploadPhoto from "./getUploadPhoto";
import Step, { StepProps } from "../Step";
import Form from "../../commons/components/form/Form";
import ScanImageInput from "../../commons/components/form/input/ScanImageInput";
import { required } from "../../commons/model/form/Validators";
import ScanExampleFaceMouthSlightlyOpened from "../../../resources/images/scan-example/face_mouth_slightly_opened.jpg";
import ScanExampleSmiling from "../../../resources/images/scan-example/smile.jpg";
import Event from "../../../../api/Event";
import "./FacePhotosStep.scss";
import "./PhotosStep.scss";
import PhotoConfiguration from "../utils/PhotoConfiguration";
import PictureShotGuidelines from "../utils/PictureShotGuidelines";
import StepId from "../StepId";

export default function FacePhotosStep(props: StepProps) {
  const [smileSimulationPhotosRequested, setSmileSimulationPhotosRequested] = useState<boolean>();
  const uploadPhoto = getUploadPhoto(props.flowContext);

  const facePhotoConfiguration: PhotoConfiguration[] = [
    {
      name: "FACE_SMILING",
      sample: ScanExampleSmiling,
      label: "Page.ScanUploader.FacePhotos.Photo.Smiling",
      isRequested: () => props.flowContext.faceSmilingRequested || smileSimulationPhotosRequested,
    },
    {
      name: "FACE_MOUTH_SLIGHTLY_OPENED",
      sample: ScanExampleFaceMouthSlightlyOpened,
      label: "Page.ScanUploader.FacePhotos.Photo.SlightlyOpened",
      isRequested: () => smileSimulationPhotosRequested,
    },
  ];

  return (
    <Step
      init={async () => {
        setSmileSimulationPhotosRequested(
          props.flowContext.virtualConsultationConfiguration.smileSimulation
        );
      }}
      {...props}
      outputs={["photoFaceSubmittedTimestamp"]}
    >
      <Form
        className="form-max-width"
        submit={async () => {
          props.flowContext.sendEvent(Event.PHOTO_FACE_SUBMITTED);
          props.onComplete(
            {
              photoFaceSubmittedTimestamp: new Date().getTime(),
            },
            ["photoFaceSubmittedTimestamp"]
          );
        }}
        submitLabel={i18n["Global.Next"]}
        titleLabel={i18n["Page.ScanUploader.FacePhotos.Title"]}
        backLink={props.backLink}
      >
        {<PictureShotGuidelines />}
        <div className="image-input-container">
          {facePhotoConfiguration
            .filter((it) => it.isRequested())
            .map((it) => (
              <ScanImageInput
                key={it.name}
                name={it.name}
                label={i18n[it.label]}
                sample={it.sample}
                uploadFunction={uploadPhoto}
                validators={[required()]}
                portraitMode={true}
              />
            ))}
        </div>
      </Form>
    </Step>
  );
}

FacePhotosStep.initialSteps = [StepId.PHOTOS_FACE_SUB_STEP];
