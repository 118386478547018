import * as React from "react";
import "./LoadingSpinner.scss";

export default function LoadingSpinner() {
  return (
    <div>
      <div className={"loading-spinner"} />
    </div>
  );
}
