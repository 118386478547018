import * as React from "react";
import { buildClasses } from "../../utils/classes";
import Option from "../../model/Option";
import { sanitize } from "../../utils/sanitize";

export interface CheckboxProps {
  name: string;
  checked: boolean;
  option: Option;
  secondOption?: string;
  onChange: (boolean: boolean) => void;
  disabled?: boolean;
  additionalClass?: string;
  errorMessage?: string;
}

export default function Checkbox(props: CheckboxProps) {
  const option = props.option;
  return (
    <div
      className={buildClasses(
        { [props.additionalClass]: () => !!props.additionalClass },
        "input-group"
      )}
    >
      <div className={"multi-checkboxes-container" + (props.disabled ? " disabled" : "")}>
        <div className="h-flex">
          <div className="checkbox-container" key={option.value}>
            <input
              type="checkbox"
              name={props.name}
              id={props.name + "-" + option.value}
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.checked}
              disabled={props.disabled}
            />
            <label htmlFor={option.value}>
              {typeof option.label == "string" ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(option.label)
                  }}
                />
              ) : (
                option.label
              )}
            </label>
            {props.secondOption &&
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(props.secondOption, { ADD_ATTR: ['target'] })
                }}
              />
            }
          </div>
        </div>
        <div className="error-message">{props.errorMessage}</div>
      </div>
    </div>
  );
}
