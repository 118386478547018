import PostalCode from "postal-codes-js/postal-codes.js";
import { MessageLevel } from "./Field";

export function required(errorMessage?: string) {
  const validator = (value: any) => !value;
  validator.messageLevel = MessageLevel.ERROR;
  validator.errorMessage = errorMessage || i18n["Form.Error.Required"];
  return validator;
}

export function minLength(errorMessage: string, length: number) {
  const validator = (value: any) => value && value.length < length;
  validator.messageLevel = MessageLevel.ERROR;
  validator.errorMessage = errorMessage || i18n["Form.Error.MinLength"];
  return validator;
}

export function maxLength(errorMessage: string, length: number) {
  const validator = (value: any) => value && value.length > length;
  validator.messageLevel = MessageLevel.ERROR;
  validator.errorMessage = errorMessage || i18n["Form.Error.MaxLength"];
  return validator;
}

export function minChecked(errorMessage: string, length: number) {
  const validator = (values: Array<any>) =>
    values && Object.values(values).filter((value) => value).length < length;
  validator.messageLevel = MessageLevel.ERROR;
  validator.errorMessage = errorMessage;
  return validator;
}

export function zipCodeValidator(
  errorMessage: string,
  countryCode: string,
  messageLevel: MessageLevel
) {
  const validator = (value: any) => value && PostalCode.validate(countryCode, value) !== true;
  validator.messageLevel = messageLevel;
  validator.errorMessage = errorMessage || i18n["Form.Error.InvalidZipCode"];
  return validator;
}

export function emailValidator(errorMessage: string, messageLevel: MessageLevel) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validator = (value: any) => value && !regex.test(String(value).toLowerCase());
  validator.messageLevel = messageLevel || MessageLevel.ERROR;
  validator.errorMessage = errorMessage || i18n["Form.Error.InvalidEmail"];
  return validator;
}
