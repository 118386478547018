import { renderToString } from "react-dom/server";

export default class Option {
  value: string;
  label: any;
  labelAsString: string;

  constructor(value: string, label: any) {
    this.value = value;
    this.label = label;
    this.labelAsString = renderToString(label);
  }

  static byName(o1: Option, o2: Option) {
    const o1LabelString = o1.labelAsString.toLowerCase();
    const o2LabelString = o2.labelAsString.toLowerCase();
    if (o1LabelString < o2LabelString) return -1;
    else if (o1LabelString > o2LabelString) return 1;
    else return 0;
  }
}
