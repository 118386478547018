import * as React from "react";
import { ReactNode } from "react";
import FormContext from "./FormContext";
import FormBackLink, { FormBackLinkProps } from "./FormBackLink";
import FormTitle from "./FormTitle";
import InputState from "../../model/form/InputState";
import AsyncAction from "../../../AsyncAction";
import Field, { FieldKey, FieldType, getInput } from "../../model/form/Field";
import { required } from "../../model/form/Validators";
import { buildClasses } from "../../utils/classes";
import { sanitize } from "../../utils/sanitize";

export interface FormProps {
  children: ReactNode;
  submitLabel: string;
  submit: (form: { [key: string]: InputState }) => Promise<any>;
  submitDisabled?: (form: { [key: string]: InputState }) => boolean;
  submitIcon?: string;
  onSubmitSuccess?: (result) => void;
  formValidator?: (form: { [key: string]: InputState }) => boolean;
  generalErrorMsg?: string;
  secondaryActionLabel?: string;
  secondaryAction?: () => Promise<any>;
  secondaryActionDisabled?: boolean;
  secondaryActionClassName?: string;
  onUpdate?: (changedInput: InputState, form: { [key: string]: InputState }) => any;
  titleLabel?: string;
  backLink?: FormBackLinkProps;
  className?: string;
}

interface FormState {
  form: { [key: string]: InputState };
  invalid: boolean;
}

export default class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props);
    this.state = {
      form: {},
      invalid: true,
    };
    this.onInputUpdate = this.onInputUpdate.bind(this);
    this.onInputUnmount = this.onInputUnmount.bind(this);
    this.setStateAsync = this.setStateAsync.bind(this);
    this.submit = this.submit.bind(this);
  }

  render() {
    return (
      <form
        className={"react-form " + (this.props.className ? this.props.className : "")}
        noValidate={true}
      >
        {this.props.titleLabel && <FormTitle title={this.props.titleLabel} />}
        {this.props.backLink && <FormBackLink {...this.props.backLink} />}

        {this.props.generalErrorMsg && (
          <div
            className={
              "form-general-error message danger" + (!this.props.generalErrorMsg ? " none" : "")
            }
            dangerouslySetInnerHTML={{
              __html: sanitize(this.props.generalErrorMsg),
            }}
          />
        )}
        <FormContext.Provider
          value={{
            onInputUpdate: this.onInputUpdate,
            onInputUnmount: this.onInputUnmount,
          }}
        >
          <div>{this.props.children}</div>
        </FormContext.Provider>
        <div className={`action-container${this.props.secondaryAction ? " space-between" : ""}`}>
          {this.props.secondaryActionLabel && this.props.secondaryAction && (
            <AsyncAction
              disabled={this.props.secondaryActionDisabled}
              promise={this.props.secondaryAction}
              buttonType={
                "button-secondary" +
                (this.props.secondaryActionClassName
                  ? " " + this.props.secondaryActionClassName
                  : "")
              }
              buttonLabel={this.props.secondaryActionLabel}
            />
          )}
          {this.props.submitLabel && (
            <AsyncAction
              promise={this.submit}
              buttonLabel={this.props.submitLabel}
              disabled={
                Object.values(this.state.form).some((input) => input.status.invalid) ||
                (this.props.submitDisabled && this.props.submitDisabled(this.state.form))
              }
              fontIcon={this.props.submitIcon}
              onSuccess={this.props.onSubmitSuccess}
            />
          )}
        </div>
      </form>
    );
  }

  private submit(): Promise<any> {
    return this.setStateAsync((state) => {
      if (this.props.formValidator) {
        return {
          invalid: this.props.formValidator(state.form),
        };
      } else {
        return {
          invalid: false,
        };
      }
    }).then(() => {
      if (!this.state.invalid) {
        return this.props.submit(this.state.form);
      } else {
        return Promise.reject();
      }
    });
  }

  private setStateAsync(state: (prevState: Readonly<FormState>) => any): Promise<any> {
    return new Promise((resolve) => {
      this.setState(state, () => resolve(true));
    });
  }

  private onInputUpdate(inputState: InputState) {
    if (this.props.onUpdate) this.props.onUpdate(inputState, this.state.form);

    this.setState((state) => ({
      form: {
        ...state.form,
        [inputState.name]: inputState,
      },
    }));
  }

  private onInputUnmount(inputKey: string) {
    this.setState((state) => {
      const form = { ...state.form };
      delete form[inputKey];
      return { form };
    });
  }

  static getFieldComponents(
    fields: Field[],
    extraProps: { [fieldName: string]: {} } = {},
    titleGetter: (field: Field) => string = FieldKey.getTitle,
    inputGetter: (field: Field) => any = getInput,
    patientId?: string
  ) {
    return fields.map((field) => {
      const FieldComponent = inputGetter(field);
      //@ts-ignore
      return (
        <FieldComponent
          hidden={field.hidden}
          key={field.key}
          name={field.name}
          label={i18n[titleGetter(field)]}
          placeholder={i18n[FieldKey.getPlaceholder(field)]}
          detail={field.mandatory ? null : i18n["Global.OptionalField"]}
          validators={
            // TODO lmartelli 2021/05/25: either input components should all know what to do when they are mandatory
            // (HealthIssueCheckboxInput already does that), or we should make required() handle array values to work in all cases.
            field.mandatory && field.type != FieldType.HEALTH_ISSUE_CHECKBOX ? [required()] : []
          }
          messageLevel={field.messageLevel}
          disabled={field.disabled}
          {...extraProps[field.name]}
          patientId={patientId}
        />
      );
    });
  }
}

export function FormPanel(
  props: React.PropsWithChildren<{
    titleLabel?: string;
    backLink?: FormBackLinkProps;
    className?: string;
  }>
) {
  return (
    <div className={buildClasses({}, "react-form", props.className)}>
      {props.titleLabel && <FormTitle title={props.titleLabel} />}
      {props.backLink && <FormBackLink {...props.backLink} />}
      <div>{props.children}</div>
    </div>
  );
}
