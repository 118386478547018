import * as React from "react";
import { buildClasses } from "../../utils/classes";

export interface FormBackLinkProps {
  onClick: () => any;
  label: string;
  disabled?: boolean;
}

export default function FormBackLink(props: FormBackLinkProps) {
  return (
    <a
      className={buildClasses({ disabled: () => props.disabled }, "back-link")}
      onClick={() => props.onClick()}
    >
      <i className="ic_chevron_left" />
      {props.label}
    </a>
  );
}
