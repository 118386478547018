import * as React from "react";
import { RefObject } from "react";
import loadImage from "blueimp-load-image";
import InputProps from "../../../model/form/InputProps";
import FormInput from "./FormInput";
import FaceOverlay from "../../../../../resources/images/face-overlay.svg";
import "./ScanImageInput.scss";
import heic2any from "heic2any";
import { buildClasses } from "../../../utils/classes";
import * as Sentry from "@sentry/browser";
import InputState from "../../../model/form/InputState";

interface ScanImageInputProps extends InputProps {
  sample: any;
  uploadFunction: (bytes, name) => Promise<any>;
  portraitMode?: boolean;
}

interface ScanImageInputState {
  preview?: boolean;
  processing?: boolean;
}

export default class ScanImageInput extends React.Component<
  ScanImageInputProps,
  ScanImageInputState
> {
  private inputFileRef: RefObject<any> = React.createRef();
  private previewRef: RefObject<any> = React.createRef();
  private lastUploadPromise;

  constructor(props: ScanImageInputProps) {
    super(props);
    this.state = {};
    this.template = this.template.bind(this);
    this.loadImage = this.loadImage.bind(this);
    this.openFileDialog = this.openFileDialog.bind(this);
  }

  template(state: InputState, input) {
    return (
      <div
        className={`scan-image-input${
          state.status.invalid && !state.status.pristine ? " invalid" : ""
        }${this.props.portraitMode ? " portrait" : ""}`}
      >
        <label>{this.props.label}</label>
        <div className="group">
          <div className="image-container">
            <img src={this.props.sample} alt="image sample" />
          </div>
          <div
            className={"image-container clickable" + (!this.state.preview ? " empty" : "")}
            onClick={(e) => this.openFileDialog(e)}
          >
            <div className={"group" + (this.state.preview ? "" : " none")}>
              <div
                ref={this.previewRef}
                className={"group preview" + (this.state.processing ? " processing" : "")}
              />
              {this.props.portraitMode && (
                <img className="face-overlay" src={FaceOverlay} alt="face overlay" />
              )}
              <div
                className={"remove-action h-flex centered" + (this.state.processing ? " none" : "")}
                onClick={(e) => this.clearField(e, input)}
              >
                <i className="ic_trash" />
              </div>
              {this.state.processing && (
                <div className="processing-filter h-flex centered">
                  <div className="loader" />
                </div>
              )}
            </div>
            <div className={"h-flex centered full-height" + (this.state.preview ? " none" : "")}>
              <div>
                <i className="ic_plus" />
                <div>{i18n["ScanImageInput.Action.UploadPhoto"]}</div>
              </div>
            </div>
          </div>
        </div>
        <input
          ref={this.inputFileRef}
          type="file"
          className="none"
          onChange={(e) => this.loadImage(e, state, input)}
          //TODO: Chrome with Android 14 bug workaround to be able to select both camera and medias when uploading
          accept="image/*, applications/workaround"
        />
        <div
          className={buildClasses(
            { invalid: () => state.status.invalid && !state.status.pristine },
            "input-group"
          )}
        >
          <div className="error-message">{state.status.errorMessage}</div>
        </div>
      </div>
    );
  }

  private openFileDialog(event) {
    event.preventDefault();
    this.inputFileRef.current.click();
  }

  private loadImage(event, state, input) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type == "image/heic") {
        heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 1,
        })
          .then((resultBlob) => {
            this.uploadImage(input, state, resultBlob);
          })
          .catch((errorObject) => {
            input.setError(i18n["Form.Error.ImageFormatNotSupported"], true);
            Sentry.captureException(errorObject);
          });
      } else {
        this.uploadImage(input, state, file);
      }
    }
  }

  private uploadImage(input, state, image) {
    loadImage(image, {
      maxWidth: 3000,
      maxHeight: 3000,
      orientation: true,
      canvas: true,
    }).then((result) => {
      input.setValue(null);
      this.inputFileRef.current.value = null;
      this.setState({ preview: true, processing: true });
      const preview: Element = this.previewRef.current;
      preview.childNodes.forEach((it) => it.remove());
      preview.appendChild(result.image);
      if ("toBlob" in result.image) {
        result.image.toBlob((blob) => {
          const uploadPromise = this.props.uploadFunction(blob, state.name);
          this.lastUploadPromise = uploadPromise;
          uploadPromise
            .then(() => {
              if (uploadPromise === this.lastUploadPromise) {
                input.setValue(blob);
                this.setState({ processing: false });
              }
            })
            .catch((e) => {
              this.setState({ processing: false, preview: false });
              Sentry.captureException(e);
              throw e;
            });
        }, "image/jpeg");
      }
    });
  }

  private clearField(event, input) {
    event.stopPropagation();
    input.setValue(null);
    this.setState({ preview: false });
  }

  render() {
    return <FormInput {...this.props} template={this.template} />;
  }
}
