import * as React from "react";
import { useEffect, useState } from "react";
import Step, { StepProps } from "../Step";
import FormTitle from "../../commons/components/form/FormTitle";
import GeoCoordinates from "../../commons/model/address/GeoCoordinates";
import { Distance, formatDistance } from "../../commons/utils/distance";
import sfetch from "../../commons/utils/super-fetch";
import { fillTemplateString } from "../../commons/utils/text-rendering";
import { Practice } from "../../practice-management/Practice";
import { PracticeDetailsCard } from "../../practice-management/PracticeDetailsCard";
import FormBackLink, { FormBackLinkProps } from "../../commons/components/form/FormBackLink";
import PracticeMap from "../utils/PracticeMap";
import StepId from "../StepId";
import { FormPanel } from "../../commons/components/form/Form";
import Event from "../../../../api/Event";
import AsyncAction from "../../AsyncAction";
import { technicalFlowError } from "../FlowLauncher";
import FlowContext from "../FlowContext";
import RadioButton from "../../commons/components/buttons/RadioButton";
import { sanitize } from "../../commons/utils/sanitize";

export default function PracticeSelectorStep(props: StepProps) {
  const [practicesWithDoctors, setPracticesWithDoctors] = useState<PracticeWithDoctors[]>([]);
  const [selectedPractice, selectPractice] = useState<Practice>();
  const [selectedDoctor, selectDoctor] = useState<Doctor>();
  const [autoCompleted, setAutoCompleted] = useState(false);

  useEffect(() => {
    if (
      props.flowContext.practiceSearchConfiguration.mode === PracticeSearchMode.PRACTICE &&
      props.flowContext.availablePractices?.length === 1
    ) {
      selectPractice(props.flowContext.availablePractices[0]);
      setAutoCompleted(true);
    }
  }, []);

  useEffect(() => {
    if (practicesWithDoctors?.length === 1) {
      const selectedPracticeWithDoctors = practicesWithDoctors[0];
      selectPractice(selectedPracticeWithDoctors.practice);
      selectedPracticeWithDoctors.doctors.length &&
        selectDoctor(selectedPracticeWithDoctors.doctors[0]);
      if (selectedPracticeWithDoctors.doctors.length === 1) {
        setAutoCompleted(true);
      }
    }
  }, [practicesWithDoctors]);

  useEffect(() => {
    if (autoCompleted) {
      confirmSelection(autoCompleted);
    }
  }, [autoCompleted]);

  const selectPracticeAndFirstDoctor = (practice) => {
    selectPractice(practice);
    const doctors =
      practicesWithDoctors && practicesWithDoctors.length
        ? practicesWithDoctors.filter(
            (practiceWithDoctors) =>
              practiceWithDoctors.practice.practiceToken === practice.practiceToken
          )[0].doctors
        : [];
    doctors.length && selectDoctor(doctors[0]);
  };

  let possibleNextSteps: (keyof FlowContext)[];
  switch (props.flowContext.practiceSearchConfiguration.mode) {
    case PracticeSearchMode.PRACTICE_AND_DOCTOR_BY_RADIUS:
      possibleNextSteps = ["selectedPractice", "selectedDoctor"];
      break;
    case PracticeSearchMode.PRACTICE:
      possibleNextSteps = ["selectedPractice"];
      break;
  }

  const confirmSelection = (autoCompleted?: boolean) => {
    let flowContextUpdate: Partial<FlowContext>;
    let events: Array<Event>;
    switch (props.flowContext.practiceSearchConfiguration.mode) {
      case PracticeSearchMode.PRACTICE_AND_DOCTOR_BY_RADIUS:
        flowContextUpdate = { selectedPractice, selectedDoctor };
        events = [Event.SELECT_PRACTICE, Event.SELECT_DOCTOR];
        break;
      case PracticeSearchMode.PRACTICE:
        flowContextUpdate = { selectedPractice };
        events = [Event.SELECT_PRACTICE];
        break;
    }
    props.onComplete(flowContextUpdate, possibleNextSteps, autoCompleted);
    events.forEach((event) => props.flowContext.sendEvent(event));
  };

  const init = async function () {
    if (
      props.flowContext.practiceSearchConfiguration.mode ===
      PracticeSearchMode.PRACTICE_AND_DOCTOR_BY_RADIUS
    ) {
      try {
        setPracticesWithDoctors(await findPracticesWithDoctors());
      } catch (e) {
        props.flowContext.setError(technicalFlowError);
        throw e;
      }
    }
  };

  const findPracticesWithDoctors = async () => {
    const searchConfiguration: PracticeSearchConfiguration =
      props.flowContext.practiceSearchConfiguration;
    let params = new URLSearchParams();
    const coordinates: GeoCoordinates = props.flowContext.patientGeoCoordinates;
    params.append("dsoToken", props.flowContext.practiceToken);
    params.append("latitude", coordinates.latitude.toString());
    params.append("longitude", coordinates.longitude.toString());
    params.append(
      "radius",
      searchConfiguration.radius.value + searchConfiguration.radius.unit.toLocaleLowerCase()
    );

    const res = await sfetch(`${props.flowContext.urlPracticeRoot}?${params.toString()}`, {
      method: "GET",
    });

    const practicesWithDoctors: PracticeWithDoctors[] = await res.json();
    practicesWithDoctors.forEach((practiceWithDoctors) => {
      setPracticeCountry(practiceWithDoctors.practice);
    });
    return practicesWithDoctors;
  };

  // LongTermFix sjahan 24/03/21: Objects prefilled from the page server are not the same than objects from the API. This recomposes view objects but it is really ugly.
  // LongTermFix sjahan 11/06/21: Check if we can get rid of this because we use directly the displayAddress now? Or should we?
  const setPracticeCountry = (practice: Practice) => {
    practice.address.country =
      props.flowContext.countries.find(
        (country) => country.name.toUpperCase() === String(practice.address.country).toUpperCase()
      ) ?? practice.address.country;
  };

  const practices: Practice[] =
    props.flowContext.practiceSearchConfiguration.mode === PracticeSearchMode.PRACTICE
      ? props.flowContext.availablePractices
      : practicesWithDoctors.map((practiceWithDoctors) => practiceWithDoctors.practice);

  const formConfiguration = formConfigurationByMode.get(
    props.flowContext.practiceSearchConfiguration.mode
  );

  return (
    <Step init={init} {...props} outputs={possibleNextSteps}>
      {practices.length ? (
        <FormPanel
          titleLabel={formConfiguration.title}
          backLink={props.backLink}
          className="text-form form-medium-width"
        >
          {props.flowContext.googleApiKey && practices && (
            <PracticeMap apiKey={props.flowContext.googleApiKey} practices={practices} />
          )}
          <div className="practice-details-container">
            {practices.map((practice) => (
              <PracticeDetailsCard
                key={practice.practiceToken}
                practiceDetails={practice}
                onSelect={
                  !(selectedPractice?.practiceToken === practice.practiceToken)
                    ? (practice) => selectPracticeAndFirstDoctor(practice)
                    : undefined
                }
                selected={selectedPractice?.practiceToken === practice.practiceToken}
                displayOnMap={!!props.flowContext.googleApiKey}
                distanceDisplay={
                  formConfiguration.displayDistance && {
                    fallbackMaxDistance: props.flowContext.practiceSearchConfiguration.radius,
                  }
                }
              >
                {practicesWithDoctors &&
                  practicesWithDoctors
                    .filter(
                      (practiceWithDoctor) =>
                        practiceWithDoctor.practice.practiceToken === practice.practiceToken
                    )
                    .map((practiceWithDoctor) => (
                      <DoctorsList
                        practiceWithDoctor={practiceWithDoctor}
                        practice={practice}
                        selectedPractice={selectedPractice}
                        selectedDoctor={selectedDoctor}
                        selectDoctor={selectDoctor}
                      />
                    ))}
              </PracticeDetailsCard>
            ))}
          </div>
          <div className="action-container">
            <AsyncAction
              promise={async () => confirmSelection()}
              buttonLabel={formConfiguration.confirmSelectionLabel}
              disabled={!selectedPractice}
            />
          </div>
        </FormPanel>
      ) : (
        <NoPracticeFound
          backLink={props.backLink}
          formConfiguration={formConfiguration}
          practiceSearchConfiguration={props.flowContext.practiceSearchConfiguration}
          showDsoPhoneNumber={props.flowContext.showDsoPhoneNumber}
        />
      )}
    </Step>
  );
}

function DoctorsList(props: {
  practice: Practice;
  practiceWithDoctor: PracticeWithDoctors;
  selectedPractice: Practice;
  selectedDoctor: Doctor;
  selectDoctor: (doctor: Doctor) => void;
}) {
  return (
    <div className="doctors-container">
      {props.practiceWithDoctor.doctors.map((doctor) => (
        <RadioButton
          name="scansSelection"
          value={doctor.doctorToken}
          id={props.practice.practiceToken + "-" + doctor.doctorToken}
          checked={
            props.selectedPractice?.practiceToken === props.practice.practiceToken &&
            props.selectedDoctor?.doctorToken === doctor.doctorToken
          }
          label={doctor.doctorName}
          onChange={(e) => {
            if (e.target.checked) {
              props.selectDoctor(doctor);
            }
          }}
          key={props.practice.practiceToken + "-" + doctor.doctorToken}
        />
      ))}
    </div>
  );
}

function NoPracticeFound({
  backLink,
  formConfiguration,
  practiceSearchConfiguration,
  showDsoPhoneNumber,
}: {
  backLink?: FormBackLinkProps;
  formConfiguration: FormConfiguration;
  practiceSearchConfiguration?: PracticeSearchConfiguration;
  showDsoPhoneNumber?: boolean;
}) {
  return (
    <div className="react-form form-max-width">
      <FormTitle title={i18n["Page.ScanUploader.SelectPractice.Title"]} />
      <FormBackLink {...backLink} />
      <div className="text-align-center">
        <p>{formConfiguration.noPracticeFoundErrorMessage(practiceSearchConfiguration)}</p>
        {showDsoPhoneNumber && (
          <p
            dangerouslySetInnerHTML={{
              __html: sanitize(i18n["Page.ScanUploader.SelectPractice.NoPracticeFound2"]),
            }}
          />
        )}
      </div>
    </div>
  );
}

PracticeSelectorStep.initialSteps = [StepId.PRACTICE_SELECTOR_STEP];

export interface PracticeSearchConfiguration {
  mode: PracticeSearchMode;
  radius?: Distance;
}

enum PracticeSearchMode {
  PRACTICE_AND_DOCTOR_BY_RADIUS = "PRACTICE_AND_DOCTOR_BY_RADIUS",
  PRACTICE = "PRACTICE",
}

interface FormConfiguration {
  title: string;
  confirmSelectionLabel: string;
  noPracticeFoundErrorMessage: (
    practiceSearchConfiguration?: PracticeSearchConfiguration
  ) => string;
  displayDistance: Boolean;
}

const formConfigurationByMode: Map<PracticeSearchMode, FormConfiguration> = new Map([
  [
    PracticeSearchMode.PRACTICE_AND_DOCTOR_BY_RADIUS,
    {
      title: i18n["Page.ScanUploader.SelectPractice.Title.Doctor"],
      confirmSelectionLabel: i18n["Page.ScanUploader.SelectPractice.Choose.Doctor"],
      noPracticeFoundErrorMessage: (practiceSearchConfiguration: { radius: Distance }) =>
        fillTemplateString(i18n["Page.ScanUploader.SelectPractice.NoPracticeFound1"], {
          distance: formatDistance(practiceSearchConfiguration.radius),
        }),
      displayDistance: Boolean(true),
    },
  ],
  [
    PracticeSearchMode.PRACTICE,
    {
      title: i18n["Page.ScanUploader.SelectPractice.Title.Location"],
      confirmSelectionLabel: i18n["Page.ScanUploader.SelectPractice.Choose.Location"],
      noPracticeFoundErrorMessage: i18n["Page.ScanUploader.SelectPractice.NoPracticeFound3"],
      displayDistance: Boolean(false),
    },
  ],
]);

export class PracticeWithDoctors {
  practice: Practice;
  doctors: Doctor[];
}

export class Doctor {
  doctorToken: string;
  doctorName: string;
}
