import * as React from "react";
import TextAction, { TextActionProps } from "./TextAction";

interface RemoveActionProps extends TextActionProps {
  danger?: boolean;
  id?: string;
}

export default function RemoveAction(props: RemoveActionProps) {
  let additionalClass: string;
  if (props.danger === undefined || props.danger) {
    additionalClass = "danger ";
  }
  additionalClass += props.additionalClass || "";

  return (
    <TextAction
      {...props}
      icon="ic_trash"
      label={props.label || i18n["Global.Remove"]}
      additionalClass={additionalClass}
    />
  );
}
