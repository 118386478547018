import * as React from "react";
import { ReactNode, useEffect } from "react";
import { buildClasses } from "../commons/utils/classes";
import { FormBackLinkProps } from "../commons/components/form/FormBackLink";
import FlowContext from "./FlowContext";
import { getLocalStorageAccess } from "./utils/localStorage";
import useHidePracticeReminder from "./utils/useHidePracticeReminder";

export interface StepProps {
  backLink?: FormBackLinkProps;
  flowContext: FlowContext;
  setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>;
  onComplete: (
    flowContextUpdate: Partial<FlowContext>,
    outputs: (keyof FlowContext)[],
    autoCompleted?: boolean
  ) => any;
  onInit: (flowContextUpdate?: Partial<FlowContext>) => any;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reset: boolean;
  showAlreadyHaveAnAccount?: boolean;
  showLoginPopupState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  preventLSOnComplete: boolean;
}

interface InternalStepProps extends StepProps {
  init?: () => Promise<Partial<FlowContext> | void>;
  children: ReactNode;
  outputs: (keyof FlowContext)[];
}

export default function Step({
  children,
  flowContext,
  setFlowContext,
  init,
  onComplete,
  outputs,
  isLoading,
  onInit,
  preventLSOnComplete,
}: InternalStepProps) {
  if (!outputs) useHidePracticeReminder(setFlowContext);
  const shouldComplete = () =>
    outputs === null ? false : outputs.every((data) => flowContext[data]);

  useEffect(() => {
    const flowContextUpdate = {};
    if (shouldComplete()) {
      onComplete(flowContextUpdate, outputs, true);
    } else {
      init ? init().then(onInit) : onInit();
    }
  }, []);

  useEffect(() => {
    if (!isLoading && outputs === null && (!preventLSOnComplete || flowContext.isPrimaryActive)) {
      getLocalStorageAccess(flowContext.flowId).resetLocalStorageContext();
    }
  }, [isLoading]);

  return <div className={buildClasses({ none: () => isLoading })}>{!isLoading && children}</div>;
}
