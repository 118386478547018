import FlowContext from "../FlowContext";

export default class CompletedStep {
  id: string;
  outputs: (keyof FlowContext)[];
  autoCompleted?: boolean;

  constructor(id: string, outputs: (keyof FlowContext)[], autoCompleted?: boolean) {
    this.id = id;
    this.outputs = outputs;
    this.autoCompleted = autoCompleted;
  }
}
