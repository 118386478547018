export default class Country {
  i18n: string;
  code: string;
  name: string;

  constructor(i18n: string, code: string, name: string) {
    this.i18n = i18n;
    this.code = code;
    this.name = name;
  }
}
