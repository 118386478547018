import InputProps from "../../../model/form/InputProps";
import FormInput from "./FormInput";
import * as React from "react";
import { RefObject } from "react";
import "./FileInput.scss";
import { buildClasses } from "../../../utils/classes";
import sfetch from "../../../utils/super-fetch";
import InputState from "../../../model/form/InputState";
import ViewAction from "../../action/ViewAction";
import RemoveAction from "../../action/RemoveAction";

interface FileInputProps extends InputProps {
  uploadLabel: string;
  accept?: string;
  errorMessage: string;
}

export class FileInputValue {
  fileName: string;
  fileUrl: string;
  file: ArrayBuffer;
}

export default class FileInput extends React.PureComponent<FileInputProps> {
  private inputFileRef: RefObject<any> = React.createRef();

  constructor(props: FileInputProps) {
    super(props);
    this.template = this.template.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.openFileDialog = this.openFileDialog.bind(this);
  }

  template(state: InputState, input) {
    const value = state.value as FileInputValue;
    return (
      <div
        className={buildClasses(
          {
            [this.props.additionalClass]: () => !!this.props.additionalClass,
            invalid: () => state.status.invalid && !state.status.pristine,
          },
          "file-input input-group"
        )}
      >
        {value?.fileUrl ? (
          <>
            <label htmlFor={this.props.name}>
              <span>{this.props.label}</span>
            </label>
            <span className="file-name">{value.fileName}</span>
            {value.fileUrl && (
              <span className="actions">
                <ViewAction
                  key="view"
                  label="View file"
                  onClick={() => window.open(value.fileUrl, "_blank")}
                />
                <RemoveAction key="remove" onClick={() => this.removeFile(input)} />
              </span>
            )}
          </>
        ) : (
          <div className="text-align-center">
            <button
              key="upload"
              type="button"
              className="button-secondary"
              onClick={(e) => this.openFileDialog(e, input)}
            >
              {this.props.uploadLabel}
            </button>
          </div>
        )}

        <input
          name={this.props.name}
          id={this.props.name}
          ref={this.inputFileRef}
          type="file"
          className="none"
          onChange={(e) => this.loadFile(e, input)}
          accept={this.props.accept}
        />

        <div className="error-message">{state.status.errorMessage}</div>
      </div>
    );
  }

  static async preloadImage(inputState: InputState, fileName: string, fileUrl: string) {
    const response = await sfetch(fileUrl, {
      method: "GET",
    });
    const blob = await response.blob();
    inputState.setValue({
      fileName: fileName,
      fileUrl: URL.createObjectURL(blob),
      file: new File([blob], fileName),
    });
  }

  private openFileDialog(event, input) {
    event.preventDefault();
    setTimeout(() => input.dirty(), 1000);
    this.inputFileRef.current.click();
  }

  private loadFile(e, input) {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      let maxSize = 5_242_880;
      if (selectedFile.size > maxSize) {
        input.setError(this.props.errorMessage, true);
        return;
      }
      const reader = new FileReader();
      reader.onload = (fileEvent) => {
        this.inputFileRef.current.value = null;
      };
      reader.readAsDataURL(selectedFile);

      input.setValue({
        fileName: selectedFile.name,
        fileUrl: URL.createObjectURL(selectedFile),
        file: selectedFile,
      });
    }
  }

  private removeFile(input) {
    input.setValue(null);
  }

  render() {
    return <FormInput {...this.props} template={this.template} />;
  }
}
