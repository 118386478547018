import * as React from "react";
import { useEffect, useState } from "react";
import Step, { StepProps } from "../Step";
import Form from "../../commons/components/form/Form";
import StepId from "../StepId";
import UserCardRegular from "../../commons/components/user/UserCardRegular";
import RadioInput from "../../commons/components/form/input/RadioInput";
import DefaultAvatar from "../../../resources/images/default-avatar.svg";
import "./PatientSelectorStep.scss";
import { PatientCard } from "../../commons/model/patient/PatientCard";
import { fillTemplateString, unescapePathOf } from "../../commons/utils/text-rendering";
import IconTooltip from "../../commons/components/IconWithTooltip";
import sfetch from "../../commons/utils/super-fetch";
import SelectableOption from "../../commons/model/SelectableOption";
import ContactInfoForm, { ContactInfoConfiguration } from "../utils/ContactInfoForm";
import FlowContext, { getPracticeToken } from "../FlowContext";
import { technicalFlowError } from "../FlowLauncher";
import * as Sentry from "@sentry/browser";
import Event from "../../../../api/Event";
import Field, { FieldKey } from "../../commons/model/form/Field";
import { updateFlowContext } from "./FormProcessor";
import InputState from "../../commons/model/form/InputState";
import getPatientFieldTitle = FieldKey.getPatientFieldTitle;

export default function PatientSelectorStep(props: StepProps) {
  const sortPatient = (a: PatientCard, b: PatientCard) => {
    return a.name.localeCompare(b.name);
  };

  const [patients, setPatients] = useState(props.flowContext.availablePatients.sort(sortPatient));
  const [selectedPatientId, setSelectedPatientId] = useState<string>();
  const [displayDependentForm, setDisplayDependentForm] = useState<boolean>(false);

  function getPatientFormConfiguration(
    flowContext: FlowContext,
    setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>
  ): ContactInfoConfiguration {
    return {
      fields: flowContext.patientFields,
      titleGetter: getPatientFieldTitle,
      legalConsent: flowContext.patientLegalConsent,
      onLegalDocumentsLoadError: () => {
        flowContext.setError({
          ...technicalFlowError,
          content: i18n["Legal.Consent.Error"],
        });
      },
      labels: {
        titleLabel: i18n["Page.ScanUploader.SelectPatient.NewDependant.Title"],
        submitLabel: i18n["Global.Next"],
      },
      onSubmit: onDependentContactInfoSubmit(
        flowContext.patientFields,
        "patientContactInfo",
        flowContext,
        setFlowContext,
        props.onComplete
      ),
    };
  }

  const onDependentContactInfoSubmit =
    (
      fields: Field[],
      contextEntry: keyof FlowContext,
      flowContext: FlowContext,
      setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>,
      onComplete: (newFlowContext, outputs) => any
    ) =>
    (form: { [key: string]: InputState }) => {
      const contextValue = updateFlowContext(form, fields, setFlowContext, contextEntry);
      return processDependentContactInfo(
        {
          ...flowContext,
          [contextEntry]: { ...flowContext[contextEntry], ...contextValue },
        },
        setFlowContext,
        onComplete
      );
    };

  async function processDependentContactInfo(
    flowContext: FlowContext,
    setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>,
    onComplete: (newFlowContext, outputs) => any
  ): Promise<any> {
    let res, jsonRes;
    try {
      const payload = {
        ...flowContext.patientContactInfo,
      };
      res = await sfetch(
        `${flowContext.urlPatientRoot}/${flowContext.currentPatientUid}/dependent?practiceToken=${getPracticeToken(props.flowContext)}`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (e) {
      Sentry.captureException(e);
      flowContext.setError(technicalFlowError);
      e.alreadyHandled = true;
      throw e;
    }
    if (res.status == 200) {
      jsonRes = await res.json();
      flowContext.sendEvent(Event.CONTACT_INFO_SUBMITTED);
      let newAvailablePatients = await getAvailablePatients();
      onComplete(
        {
          availablePatients: [...newAvailablePatients],
          selectedPatientId: jsonRes.patientUid,
          patientCreationTimestamp: new Date().getTime(),
        },
        ["selectedPatientId"]
      );
    } else {
      console.error("Unsupported response status for patient creation : " + res.status);
    }
  }

  const contactInfoConfiguration = getPatientFormConfiguration(
    props.flowContext,
    props.setFlowContext
  );

  const selectPatient = (selectedPatientId: string) => {
    if (selectedPatientId == "newDependent") {
      setDisplayDependentForm(true);
    } else {
      props.onComplete(
        {
          selectedPatientId,
        },
        ["selectedPatientId"]
      );
    }
  };

  useEffect(() => {
    Promise.all(
      props.flowContext.availablePatients.map(async (patient) => ({
        ...patient,
        canBeAssociated: await canBeAssociated(patient.uid),
      }))
    ).then((patients) => setPatients([...patients].sort(sortPatient)));
  }, [props.flowContext.availablePatients]);

  const canBeAssociated = async (patientUid: string): Promise<Boolean> => {
    const url = fillTemplateString(unescapePathOf(props.flowContext.patientCanBeAssociatedUrl), {
      uid: patientUid,
    });
    return await sfetch(`${url}?practiceToken=${getPracticeToken(props.flowContext)}`, {
      method: "GET",
    }).then((res) => res.json());
  };

  const getAvailablePatients = async (): Promise<PatientCard[]> => {
    return await sfetch(
      `${props.flowContext.urlPatientRoot}/${props.flowContext.currentPatientUid}/available-patients`,
      { method: "GET" }
    ).then((res) => res.json());
  };

  let body;
  if (displayDependentForm) {
    body = (
      <div>
        <ContactInfoForm
          configuration={contactInfoConfiguration}
          practice={props.flowContext.selectedPractice}
          countries={props.flowContext.countries}
          backLink={props.backLink}
          initiatorCountry={props.flowContext.initiatorCountry}
        />
      </div>
    );
  } else {
    let patientOptions = [
      ...patients.map((patient) => {
        return new SelectableOption(
          patient.uid,
          <UserCardRegular userInfo={patient} />,
          false,
          patient.canBeAssociated === false
        );
      }),
    ];
    if (props.flowContext.canUserAddDependent) {
      patientOptions = [
        ...patientOptions,
        new SelectableOption(
          "newDependent",
          (
            <UserCardRegular
              userInfo={{
                name: i18n["Page.ScanUploader.SelectPatient.AddDependent"],
                avatarHref: DefaultAvatar,
                isGrayBackground: true,
              }}
            />
          ),
          false,
          false
        ),
      ];
    }
    body = (
      <Form
        submit={async () => selectPatient(selectedPatientId)}
        submitLabel={patients.length > 0 ? i18n["Page.ScanUploader.SelectPatient.Submit"] : null}
        submitDisabled={() => !selectedPatientId}
        titleLabel={i18n["Page.ScanUploader.SelectPatient.Title"]}
        backLink={props.backLink}
        className="text-form select-patient-step"
      >
        <p>{i18n["Page.ScanUploader.SelectPatient.Instructions"]}</p>
        <div className="patient-details-container">
          <RadioInput
            flexOrientation="vertical"
            options={patientOptions}
            name="patient"
            onChange={(inputState) => {
              setSelectedPatientId(inputState.value);
            }}
            tooltip={
              props.flowContext.selectedPractice && (
                <IconTooltip
                  icon={"ic_information_circled"}
                  tooltipText={fillTemplateString(
                    i18n["Page.Portal.PatientAppointment.InvalidStatus.Description.Html"],
                    { phone: props.flowContext.selectedPractice.phoneNumber }
                  )}
                />
              )
            }
          />
        </div>
      </Form>
    );
  }

  return (
    <Step {...props} outputs={["selectedPatientId"]}>
      {body}
    </Step>
  );
}

export interface PatientCardWithCapabilities extends PatientCard {
  canBeAssociated: Boolean;
}

PatientSelectorStep.initialSteps = [StepId.PATIENT_SELECTOR_STEP];
