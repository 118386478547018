import Event from "./Event";

enum GTMEvent {
  CONTACT_INFO_SUBMITTED = "contactInfoSubmitted",
  PATIENT_QUESTIONNAIRE_SUBMITTED = "patientQuestionnaireSubmitted",
  PHOTO_CLOSED_FRONT_UPLOADED = "photoClosedFrontUploaded",
  PHOTO_CLOSED_LEFT_UPLOADED = "photoClosedLeftUploaded",
  PHOTO_CLOSED_RIGHT_UPLOADED = "photoClosedRightUploaded",
  PHOTO_CLOSED_SUBMITTED = "photoClosedSubmitted",
  PHOTO_OPENED_TOP_UPLOADED = "photoOpenedTopUploaded",
  PHOTO_OPENED_BOTTOM_UPLOADED = "photoOpenedBottomUploaded",
  PHOTO_OPENED_SUBMITTED = "photoOpenedSubmitted",
  PHOTO_FACE_SMILING_UPLOADED = "photoFaceSmilingUploaded",
  PHOTO_FACE_MOUTH_SLIGHTLY_OPENED_UPLOADED = "photoFaceMouthSlightlyOpenedUploaded",
  PHOTO_FACE_SUBMITTED = "photoFaceSubmitted",
  CONSULTATION_SUBMITTED = "consultationSubmitted",
  LOCATION_SEARCH = "locationSearch",
  SELECT_PRACTICE = "selectPractice",
  LEAD_INFO = "leadInfo",
  LEAD_CONTACT_INFO = "leadContactInfo",
  GUARDIAN_CONTACT_INFO = "guardianContactInfo",
  CONSULTATION_TYPE_VIRTUAL = "virtual-consultationType",
  CONSULTATION_TYPE_IN_CLINIC = "inClinic-consultationType",
  SELECT_TIME_DATE_VIRTUAL = "virtual-selectTimeDate",
  SELECT_TIME_DATE_IN_CLINIC = "inClinic-selectTimeDate",
  CONSULTATION_SCHEDULED_VIRTUAL = "virtual-consultationScheduled",
  CONSULTATION_SCHEDULED_IN_CLINIC = "inClinic-consultationScheduled",
}

export enum GTMEventPrefix {
  SMILE_SIMULATION = "simulation-",
  SMILE_ASSESSMENT = "assessment-",
  FULL_ASSESSMENT = "full-",
}

export function getGtmEventFromEvent(event: Event): GTMEvent {
  switch (event) {
    case Event.CONTACT_INFO_SUBMITTED:
      return GTMEvent.CONTACT_INFO_SUBMITTED;
    case Event.PATIENT_QUESTIONNAIRE_SUBMITTED:
      return GTMEvent.PATIENT_QUESTIONNAIRE_SUBMITTED;
    case Event.PHOTO_CLOSED_FRONT_UPLOADED:
      return GTMEvent.PHOTO_CLOSED_FRONT_UPLOADED;
    case Event.PHOTO_CLOSED_LEFT_UPLOADED:
      return GTMEvent.PHOTO_CLOSED_LEFT_UPLOADED;
    case Event.PHOTO_CLOSED_RIGHT_UPLOADED:
      return GTMEvent.PHOTO_CLOSED_RIGHT_UPLOADED;
    case Event.PHOTO_CLOSED_SUBMITTED:
      return GTMEvent.PHOTO_CLOSED_SUBMITTED;
    case Event.PHOTO_OPENED_TOP_UPLOADED:
      return GTMEvent.PHOTO_OPENED_TOP_UPLOADED;
    case Event.PHOTO_OPENED_BOTTOM_UPLOADED:
      return GTMEvent.PHOTO_OPENED_BOTTOM_UPLOADED;
    case Event.PHOTO_OPENED_SUBMITTED:
      return GTMEvent.PHOTO_OPENED_SUBMITTED;
    case Event.PHOTO_FACE_SMILING_UPLOADED:
      return GTMEvent.PHOTO_FACE_SMILING_UPLOADED;
    case Event.PHOTO_FACE_MOUTH_SLIGHTLY_OPENED_UPLOADED:
      return GTMEvent.PHOTO_FACE_MOUTH_SLIGHTLY_OPENED_UPLOADED;
    case Event.PHOTO_FACE_SUBMITTED:
      return GTMEvent.PHOTO_FACE_SUBMITTED;
    case Event.CONSULTATION_SUBMITTED:
      return GTMEvent.CONSULTATION_SUBMITTED;
    case Event.LOCATION_SEARCH:
      return GTMEvent.LOCATION_SEARCH;
    case Event.SELECT_PRACTICE:
      return GTMEvent.SELECT_PRACTICE;
    case Event.LEAD_INFO:
      return GTMEvent.LEAD_INFO;
    case Event.LEAD_CONTACT_INFO:
      return GTMEvent.LEAD_CONTACT_INFO;
    case Event.GUARDIAN_CONTACT_INFO:
      return GTMEvent.GUARDIAN_CONTACT_INFO;
    case Event.CONSULTATION_TYPE_VIRTUAL:
      return GTMEvent.CONSULTATION_TYPE_VIRTUAL;
    case Event.CONSULTATION_TYPE_IN_CLINIC:
      return GTMEvent.CONSULTATION_TYPE_IN_CLINIC;
    case Event.SELECT_TIME_DATE_VIRTUAL:
      return GTMEvent.SELECT_TIME_DATE_VIRTUAL;
    case Event.SELECT_TIME_DATE_IN_CLINIC:
      return GTMEvent.SELECT_TIME_DATE_IN_CLINIC;
    case Event.CONSULTATION_SCHEDULED_VIRTUAL:
      return GTMEvent.CONSULTATION_SCHEDULED_VIRTUAL;
    case Event.CONSULTATION_SCHEDULED_IN_CLINIC:
      return GTMEvent.CONSULTATION_SCHEDULED_IN_CLINIC;
  }
}
