import { Distance } from "../commons/utils/distance";
import Address from "../commons/model/address/Address";

export class PracticeDetails {
  name: string;
  address: Address;
  phoneNumber: string;
}

export class Practice extends PracticeDetails {
  practiceToken: string;
  patientPortalEnabled: boolean;
}

export class PracticeWithDistance extends Practice {
  distance?: Distance;

  static hasDistance(p: PracticeDetails): p is PracticeWithDistance {
    return "distance" in p && Boolean(p["distance"]);
  }
}
