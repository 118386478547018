import Field from "../../commons/model/form/Field";
import FlowContext from "../FlowContext";
import { getPayload } from "../../commons/utils/form-utils";
import InputState from "../../commons/model/form/InputState";

export function updateFlowContext(
  form: { [key: string]: InputState },
  fields: Field[],
  setFlowContext: (value: ((prevState: FlowContext) => FlowContext) | FlowContext) => void,
  contextEntry: keyof FlowContext
) {
  const contextValue = getPayload(
    form,
    fields.map((f) => f.name)
  );
  setFlowContext((ctx) => ({
    ...ctx,
    [contextEntry]: { ...ctx[contextEntry], ...contextValue },
  }));
  return contextValue;
}
