import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import StepId from "./StepId";
import FlowContext from "./FlowContext";
import { getLocalStorageAccess } from "./utils/localStorage";
import useAutoResizeIframe from "./utils/useAutoResizeIframe";
import "./FlowLauncher.scss";
import ModalAction from "../commons/components/modal/ModalAction";
import Event from "../../../api/Event";
import { sendEvent } from "./utils/events";
import Flow from "./Flow";
import { createRoot } from "react-dom/client";

export interface FlowLauncherProps {
  id: string;
  flow: StepId[];
  initialContext: Partial<FlowContext>;
}

export default function FlowLauncher({ id, flow, initialContext }: FlowLauncherProps) {
  useAutoResizeIframe();
  const localStorage = getLocalStorageAccess(id);
  const lsContext = localStorage.getLocalStorageContext();
  const [error, setError] = useState<FlowError>();
  const [flowContext, setFlowContext] = useState<FlowContext>({
    ...initialContext,
    ...lsContext,
    closedMouthVideoUrl: initialContext.closedMouthVideoUrl,
    openedMouthVideoUrl: initialContext.openedMouthVideoUrl,
    plannedDeferredActions:
      lsContext?.plannedDeferredActions || initialContext.plannedDeferredActions || [],
    isPrimaryActive: lsContext?.isPrimaryActive ?? true,
    setError,
    flowId: id,
    stepsCompleted: lsContext?.stepsCompleted ?? [],
    backupStepsCompleted: lsContext?.backupStepsCompleted ?? [],
    alreadyRunDeferredActions:
      lsContext?.alreadyRunDeferredActions || initialContext.alreadyRunDeferredActions || [],
    sendEvent: (event: Event) => sendEvent(event, id),
    availablePatients: initialContext.availablePatients,
    availablePractices: initialContext.availablePractices,
    steps: initialContext.steps,
  });
  const [mainFlowStep, setMainFlowStep] = useState<StepId>(flow[0]);

  useEffect(() => {
    if (!flowContext.stepsCompleted.find(
      (step) => step.id === StepId.VIRTUAL_CONSULTATION_CONFIRMATION_STEP)) {
      localStorage.setLocalStorageContext(flowContext, flowContext.expirationTimeoutInSeconds);
    }
  }, [flowContext]);

  return (
    <div className="flow-launcher">
      <Flow
        active={flowContext.isPrimaryActive}
        stepIds={flow}
        flowContext={flowContext}
        setFlowContext={setFlowContext}
        onStepChange={setMainFlowStep}
      />
      {error && (
        <ModalAction
          action={() => error.action(flowContext)}
          hideCloseIcon={true}
          title={error.title}
          iconElement={"ic_exclamation_circled"}
          dialogClass={"v-flex delete-dialog"}
          actionButtonClass={"button-secondary cancel filled"}
          actionButtonLabel={i18n["Global.Close"]}
        >
          {error.content}
        </ModalAction>
      )}
    </div>
  );
}

export interface FlowError {
  action: (flowContext: FlowContext) => Promise<any>;
  title: string;
  content: ReactNode;
}

export const technicalFlowError: FlowError = {
  title: i18n["Global.TechnicalError.Title"],
  action: async (flowContext: FlowContext) => {
    getLocalStorageAccess(flowContext.flowId).resetLocalStorageContext();
    document.location.reload();
  },
  content: i18n["Global.TechnicalError"],
};

FlowLauncher.loadComponent = (elementId: string, props: FlowLauncherProps) => {
  const container = document.getElementById(elementId);
  const root = createRoot(container!);
  root.render(<FlowLauncher {...props} />);
};
