import * as React from "react";
import InputProps from "../../../model/form/InputProps";
import PhoneInput, { getCountries, isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import de from "react-phone-number-input/locale/de.json";
import el from "react-phone-number-input/locale/el.json";
import en from "react-phone-number-input/locale/en.json";
import es from "react-phone-number-input/locale/es.json";
import fi from "react-phone-number-input/locale/fi.json";
import fr from "react-phone-number-input/locale/fr.json";
import it from "react-phone-number-input/locale/it.json";
import nb from "react-phone-number-input/locale/nb.json";
import pl from "react-phone-number-input/locale/pl.json";
import pt from "react-phone-number-input/locale/pt.json";
import ru from "react-phone-number-input/locale/ru.json";
import sv from "react-phone-number-input/locale/sv.json";
import vi from "react-phone-number-input/locale/vi.json";
import { buildClasses } from "../../../utils/classes";
import Country from "../../../model/address/Country";
import FormInput from "./FormInput";
import InputState from "../../../model/form/InputState";

export interface TelInputProps extends InputProps {
  selectedCountry?: Country;
  availableCountries: Country[];
}

export default class TelInput extends React.Component<TelInputProps> {
  // Locals from project => https://gitlab.com/catamphetamine/react-phone-number-input/tree/master/locale
  private locales = {
    de: de,
    el: el,
    en: en,
    es: es,
    fi: fi,
    fr: fr,
    it: it,
    nb: nb,
    pl: pl,
    pt: pt,
    ru: ru,
    sv: sv,
    vi: vi,
  };

  private phoneNumberValidator = (value: string) => value && !isPossiblePhoneNumber(value);
  private readonly currentLanguage: any;

  constructor(props: TelInputProps) {
    super(props);
    this.template = this.template.bind(this);
    this.currentLanguage = this.locales[lang.toLocaleLowerCase()] || this.locales["en"];
    this.phoneNumberValidator["errorMessage"] = i18n["Form.Error.InvalidPhoneNumber"];
    this.phoneNumberValidator["messageLevel"] = this.props.messageLevel;
  }

  template(state: InputState, input) {
    return (
      <div
        className={buildClasses(
          {
            [this.props.additionalClass]: () => !!this.props.additionalClass,
            invalid: () =>
              (state.status.invalid && !state.status.pristine) || this.props.customErrors?.isErrors,
            none: () => state.status.hidden,
          },
          "tel-input input-group"
        )}
      >
        <label htmlFor={this.props.name}>
          <span>{this.props.label}</span>
          <span className="detail">{this.props.detail}</span>
        </label>
        <div id={this.props.name} className="group">
          <PhoneInput
            defaultCountry={getCountries().find(
              (countryCode) => countryCode === this.props.selectedCountry?.code?.toUpperCase()
            )}
            countries={getCountries().filter((countryCode) =>
              this.props.availableCountries
                .map((country) => country.code.toUpperCase())
                .includes(countryCode)
            )}
            labels={this.currentLanguage}
            value={this.props.initialValue}
            onChange={(value) => {
              input.setValue(value);
              input.dirty();
            }}
            onBlur={input.dirty}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
          />
          {!state.status.invalid &&
            !this.props.customErrors?.isErrors &&
            !state.status.pristine &&
            !this.props.disabled && (
              <div className="h-flex centered valid">
                <i className="ic_check" />
              </div>
            )}
        </div>
        {this.props.customErrors?.isErrors && (
          <div className="error-message"> {this.props.customErrors.message}</div>
        )}
        {!this.props.customErrors?.isErrors && (
          <div className="error-message">{state.status.errorMessage}</div>
        )}
      </div>
    );
  }

  render() {
    return (
      <FormInput
        {...this.props}
        validators={[...(this.props.validators || []), this.phoneNumberValidator]}
        template={this.template}
      />
    );
  }
}
