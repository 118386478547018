// Polyfill.
import "core-js/stable";
import "regenerator-runtime/runtime";
// Top components.
import FlowLauncher from "./react/flow/FlowLauncher";
// Style.
import "./styles/base/flow.scss";
// Misc scripts.
import * as VerticalDropDown from "./js/vertical-dropdown-manager";

export default {
  FlowLauncher,
  VerticalDropDown,
};
