enum Event {
  CONTACT_INFO_SUBMITTED = "CONTACT_INFO_SUBMITTED",
  PATIENT_QUESTIONNAIRE_SUBMITTED = "PATIENT_QUESTIONNAIRE_SUBMITTED",
  PHOTO_CLOSED_FRONT_UPLOADED = "PHOTO_CLOSED_FRONT_UPLOADED",
  PHOTO_CLOSED_LEFT_UPLOADED = "PHOTO_CLOSED_LEFT_UPLOADED",
  PHOTO_CLOSED_RIGHT_UPLOADED = "PHOTO_CLOSED_RIGHT_UPLOADED",
  PHOTO_CLOSED_SUBMITTED = "PHOTO_CLOSED_SUBMITTED",
  PHOTO_OPENED_TOP_UPLOADED = "PHOTO_OPENED_TOP_UPLOADED",
  PHOTO_OPENED_BOTTOM_UPLOADED = "PHOTO_OPENED_BOTTOM_UPLOADED",
  PHOTO_OPENED_SUBMITTED = "PHOTO_OPENED_SUBMITTED",
  PHOTO_FACE_SMILING_UPLOADED = "PHOTO_FACE_SMILING_UPLOADED",
  PHOTO_FACE_MOUTH_SLIGHTLY_OPENED_UPLOADED = "PHOTO_FACE_MOUTH_SLIGHTLY_OPENED_UPLOADED",
  PHOTO_FACE_SUBMITTED = "PHOTO_FACE_SUBMITTED",
  CONSULTATION_SUBMITTED = "CONSULTATION_SUBMITTED",
  LOCATION_SEARCH = "LOCATION_SEARCH",
  SELECT_PRACTICE = "SELECT_PRACTICE",
  SELECT_DOCTOR = "SELECT_DOCTOR",
  LEAD_INFO = "LEAD_INFO",
  LEAD_CONTACT_INFO = "LEAD_CONTACT_INFO",
  GUARDIAN_CONTACT_INFO = "GUARDIAN_CONTACT_INFO",
  CONSULTATION_TYPE_VIRTUAL = "CONSULTATION_TYPE_VIRTUAL",
  CONSULTATION_TYPE_IN_CLINIC = "CONSULTATION_TYPE_IN_CLINIC",
  SELECT_TIME_DATE_VIRTUAL = "SELECT_TIME_DATE_VIRTUAL",
  SELECT_TIME_DATE_IN_CLINIC = "SELECT_TIME_DATE_IN_CLINIC",
  CONSULTATION_SCHEDULED_VIRTUAL = "CONSULTATION_SCHEDULED_VIRTUAL",
  CONSULTATION_SCHEDULED_IN_CLINIC = "CONSULTATION_SCHEDULED_IN_CLINIC",
  FLOW_COMPLETE = "FLOW_COMPLETE",
}

export default Event;
