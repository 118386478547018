import * as React from "react";
import { ReactNode } from "react";

interface ModalProps {
  children: ReactNode;
  closeModal: () => void;
}

export default class Modal extends React.Component<ModalProps> {
  constructor(props: ModalProps) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount(): void {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount(): void {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.defaultPrevented) {
      return;
    }
    if (e.key == "Escape") {
      this.props.closeModal();
      e.preventDefault();
    }
  }

  render() {
    return (
      <div tabIndex={-1} className="modal-background h-flex centered">
        {this.props.children}
      </div>
    );
  }
}
