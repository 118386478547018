import * as React from "react";
import ModalPopup from "../../commons/components/modal/ModalPopup";
import "./YoutubeVideoPanel.scss";

interface YoutubeVideoPanelProps {
  url: string;
  thumbnail: string;
}

interface YoutubeVideoPanelState {
  videoOpened: boolean;
}

export default class YoutubeVideoPanel extends React.PureComponent<
  YoutubeVideoPanelProps,
  YoutubeVideoPanelState
> {
  state = { videoOpened: false };

  render() {
    return (
      <>
        <div
          className="h-flex centered clickable mini-video-container"
          onClick={() => this.setState({ videoOpened: true })}
        >
          <img src={this.props.thumbnail} className="youtube-mini-video" alt="youtube-video" />
          <div className="youtube-side-text">
            <div className="title">{i18n["Page.ScanUploader.Video.Title"]}</div>
            <div className="content">{i18n["Page.ScanUploader.Video.Content"]}</div>
          </div>
        </div>
        {this.state.videoOpened && (
          <ModalPopup
            closeModal={() => this.setState({ videoOpened: false })}
            class="youtube-modal"
          >
            <div className="video-container">
              <iframe
                src={this.props.url + "?autoplay=1"}
                frameBorder="0"
                allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
                allowFullScreen
              />
            </div>
          </ModalPopup>
        )}
      </>
    );
  }
}
