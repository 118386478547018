import * as React from "react";
import * as Sentry from "@sentry/browser";
import InputState from "../../commons/model/form/InputState";
import Field, { FieldKey } from "../../commons/model/form/Field";
import Step, { StepProps } from "../Step";
import EmailConfirmationForm, { ConflictResolution } from "../utils/EmailConfirmationForm";
import {
  getPayload,
  getValue,
  readInitialValuesFromQueryString,
} from "../../commons/utils/form-utils";
import sfetch from "../../commons/utils/super-fetch";
import Event from "../../../../api/Event";
import ContactInfoForm, { ContactInfoConfiguration } from "../utils/ContactInfoForm";
import { FlowError, technicalFlowError } from "../FlowLauncher";
import FlowContext, { getPracticeToken } from "../FlowContext";
import { updateFlowContext } from "./FormProcessor";
import CompletedStep from "../utils/CompletedStep";
import { ErrorCode } from "../../commons/utils/errorCode";
import getPatientFieldTitle = FieldKey.getPatientFieldTitle;
import getResponsiblePartyFieldTitle = FieldKey.getResponsiblePartyFieldTitle;

export enum PatientSubStepId {
  PATIENT_FORM = "PATIENT_FORM",
  PATIENT_CONTACT_FORM = "PATIENT_CONTACT_FORM",
  LEGAL_GUARDIAN_CONTACT_FORM = "LEGAL_GUARDIAN_CONTACT_FORM",
  EMAIL_CONFIRMATION_FORM = "EMAIL_CONFIRMATION_FORM",
}

export default function PatientStep(props: StepProps) {
  let subStep = undefined;
  const contactInfoConfiguration = getPatientContactInfoFormConfiguration(
    props.flowContext,
    props.setFlowContext,
    props.showLoginPopupState,
    props.onComplete
  );

  if (props.flowContext.patientSubStepId) {
    switch (props.flowContext.patientSubStepId) {
      case PatientSubStepId.PATIENT_FORM:
        subStep = (
          <ContactInfoForm
            key="patientForm"
            configuration={contactInfoConfiguration}
            practice={props.flowContext.selectedPractice}
            countries={props.flowContext.countries}
            backLink={props.backLink}
            initiatorCountry={props.flowContext.initiatorCountry}
          />
        );
        break;
      case PatientSubStepId.PATIENT_CONTACT_FORM:
        subStep = (
          <ContactInfoForm
            key="patientContactInfoForm"
            configuration={contactInfoConfiguration}
            practice={props.flowContext.selectedPractice}
            countries={props.flowContext.countries}
            backLink={getBackLink(
              PatientSubStepId.PATIENT_CONTACT_FORM,
              PatientSubStepId.PATIENT_FORM,
              props.setFlowContext
            )}
            initiatorCountry={props.flowContext.initiatorCountry}
            dsoPrivacyPolicyCustomText={props.flowContext.dsoPrivacyPolicyCustomText}
          />
        );
        break;
      case PatientSubStepId.LEGAL_GUARDIAN_CONTACT_FORM: {
        const backLink = getBackLink(
          PatientSubStepId.LEGAL_GUARDIAN_CONTACT_FORM,
          PatientSubStepId.PATIENT_FORM,
          props.setFlowContext
        );
        const resetSteps = backLink.onClick;
        backLink.onClick = () => {
          resetSteps();
          props.setFlowContext((ctx) => ({
            ...ctx,
            responsiblePartyContactInfo: undefined,
          }));
        };
        subStep = (
          <ContactInfoForm
            key="responsiblePartyContactInfoForm"
            configuration={contactInfoConfiguration}
            practice={props.flowContext.selectedPractice}
            countries={props.flowContext.countries}
            backLink={backLink}
            initiatorCountry={props.flowContext.initiatorCountry}
            dsoPrivacyPolicyCustomText={props.flowContext.dsoPrivacyPolicyCustomText}
          />
        );
        break;
      }
      case PatientSubStepId.EMAIL_CONFIRMATION_FORM: {
        const backLink = getBackLink(
          PatientSubStepId.EMAIL_CONFIRMATION_FORM,
          props.flowContext.responsiblePartyContactInfo
            ? PatientSubStepId.LEGAL_GUARDIAN_CONTACT_FORM
            : PatientSubStepId.PATIENT_CONTACT_FORM,
          props.setFlowContext
        );
        const resetSteps = backLink.onClick;
        backLink.onClick = () => {
          resetSteps();
          props.setFlowContext((ctx) => ({
            ...ctx,
            patientObfuscatedEmails: undefined,
            selectedPatientId: undefined,
          }));
        };
        subStep = (
          <EmailConfirmationForm
            backLink={backLink}
            confirmEmail={(conflictResolution: ConflictResolution) =>
              processContactInfo(
                {
                  ...props.flowContext,
                  patientContactInfo: {
                    ...props.flowContext.patientContactInfo,
                    conflictResolution,
                  },
                },
                props.setFlowContext,
                props.showLoginPopupState[1],
                props.onComplete
              )
            }
            obfuscatedEmails={props.flowContext.patientObfuscatedEmails}
          />
        );
        break;
      }
    }
  }

  return (
    <Step
      {...props}
      outputs={["patientCreationTimestamp"]}
      init={async () => {
        if (!props.flowContext.patientSubStepId)
          return { patientSubStepId: PatientSubStepId.PATIENT_FORM };
      }}
    >
      {subStep}
    </Step>
  );
}

PatientStep.initialSteps = [PatientSubStepId.PATIENT_FORM];

function getBackLink(
  fromStep: PatientSubStepId,
  toStep: PatientSubStepId,
  setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>
) {
  return {
    onClick: () =>
      setFlowContext((ctx) => ({
        ...ctx,
        patientSubStepId: toStep,
        steps: ctx.steps.filter((it) => it !== fromStep),
        stepsCompleted: ctx.stepsCompleted.filter((step) => step.id !== toStep),
      })),
    label: i18n["Form.Back"],
  };
}

function getPatientContactInfoFormConfiguration(
  flowContext: FlowContext,
  setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>,
  showLoginPopupState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  onComplete
): ContactInfoConfiguration {
  switch (flowContext.patientSubStepId) {
    case PatientSubStepId.PATIENT_FORM:
      // Auto-fill extra props.
      const fields = flowContext.patientFields;
      const initialValues = readInitialValuesFromQueryString(fields);
      const initOnChange = () => {
        let init = false;
        return (inputState: InputState) => {
          if (!init) {
            const field = fields.find((it) => it.name === inputState.name);
            if (initialValues[field.key]) {
              if (inputState.status.invalid) {
                inputState.setValue("");
              } else {
                inputState.hide(true);
              }
            }
            init = true;
          }
        };
      };
      const extraProps = Object.keys(initialValues).reduce(
        (acc, name) => (
          (acc[name] = {
            ...acc[name],
            initialValue: initialValues[name],
            onChange: initOnChange(),
          }),
          acc
        ),
        {}
      );
      return {
        fields: [...fields],
        titleGetter: getPatientFieldTitle,
        onSubmit: onPatientFormSubmit(flowContext, setFlowContext),
        labels: {
          titleLabel: i18n["Page.ScanUploader.PatientInfos.Title"],
          submitLabel: i18n["Global.Next"],
        },
        extraProps,
      };
    case PatientSubStepId.PATIENT_CONTACT_FORM:
      return {
        fields: flowContext.patientContactInfoFields,
        titleGetter: getPatientFieldTitle,
        legalConsent: flowContext.patientLegalConsent,
        patientMarketingEmailConsentText: flowContext.patientMarketingEmailConsentText,
        onLegalDocumentsLoadError: () => {
          flowContext.setError({
            ...technicalFlowError,
            content: i18n["Legal.Consent.Error"],
          });
        },
        labels: {
          titleLabel: i18n["Page.ScanUploader.PatientInfos.Title"],
          submitLabel: i18n["Global.Next"],
        },
        onSubmit: onContactInfoSubmit(
          flowContext.patientContactInfoFields,
          "patientContactInfo",
          flowContext,
          setFlowContext,
          showLoginPopupState[1],
          onComplete,
          Event.LEAD_CONTACT_INFO
        ),
      };
    case PatientSubStepId.LEGAL_GUARDIAN_CONTACT_FORM:
      return {
        legalConsent: flowContext.patientLegalConsent,
        patientMarketingEmailConsentText: flowContext.patientMarketingEmailConsentText,
        onLegalDocumentsLoadError: () => {
          flowContext.setError({
            ...technicalFlowError,
            content: i18n["Legal.Consent.Error"],
          });
        },
        fields: flowContext.responsiblePartyFields,
        titleGetter: getResponsiblePartyFieldTitle,
        onSubmit: onContactInfoSubmit(
          flowContext.responsiblePartyFields,
          "responsiblePartyContactInfo",
          flowContext,
          setFlowContext,
          showLoginPopupState[1],
          onComplete,
          Event.GUARDIAN_CONTACT_INFO
        ),
        labels: {
          titleLabel: i18n["Page.ScanUploader.GuardianInfos.Title"],
          submitLabel: i18n["Global.Next"],
        },
        async onUpdate(changedInput: InputState) {
          if (changedInput.name === "birthDate" && !changedInput.status.invalid) {
            if (
              changedInput.value &&
              (await isResponsiblePartyMandatory(flowContext, getValue(changedInput)))
            ) {
              changedInput.status.setError(i18n["Form.Error.LegalGuardian.Minor"]);
            }
          }
        },
      };
  }
}

const onPatientFormSubmit =
  (flowContext: FlowContext, setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>) =>
  async (form) => {
    const patientContactInfo = getPayload(
      form,
      flowContext.patientFields.map((f) => f.name)
    );
    setFlowContext((ctx) => ({
      ...ctx,
      patientContactInfo: { ...ctx.patientContactInfo, ...patientContactInfo },
    }));
    flowContext.sendEvent(Event.LEAD_INFO);
    if (
      form["hasLegalGuardian"]?.value?.length ||
      (await isResponsiblePartyMandatory(
        flowContext,
        patientContactInfo["birthDate"] || flowContext.patientContactInfo["birthDate"]
      ))
    ) {
      setFlowContext((ctx) => ({
        ...ctx,
        patientSubStepId: PatientSubStepId.LEGAL_GUARDIAN_CONTACT_FORM,
        steps: [...ctx.steps, PatientSubStepId.LEGAL_GUARDIAN_CONTACT_FORM],
        stepsCompleted: [
          ...ctx.stepsCompleted,
          new CompletedStep(PatientSubStepId.PATIENT_FORM, []),
        ],
      }));
    } else {
      setFlowContext((ctx) => ({
        ...ctx,
        patientSubStepId: PatientSubStepId.PATIENT_CONTACT_FORM,
        steps: [...ctx.steps, PatientSubStepId.PATIENT_CONTACT_FORM],
        stepsCompleted: [
          ...ctx.stepsCompleted,
          new CompletedStep(PatientSubStepId.PATIENT_FORM, []),
        ],
      }));
    }
  };

const isResponsiblePartyMandatory = async (flowContext: FlowContext, birthDate) => {
  try {
    const res = await sfetch(
      `${flowContext.urlPatientRoot}/legal-guardian-mandatory?practiceToken=${getPracticeToken(flowContext)}&birthDate=${birthDate}`,
      { method: "GET" }
    );
    return await res.json();
  } catch (e) {
    Sentry.captureException(e);
    flowContext.setError(technicalFlowError);
    e.alreadyHandled = true;
    throw e;
  }
};

const onContactInfoSubmit =
  (
    fields: Field[],
    contextEntry: keyof FlowContext,
    flowContext: FlowContext,
    setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>,
    setShowLoginPopup: React.Dispatch<React.SetStateAction<boolean>>,
    onComplete: (newFlowContext) => any,
    event?: Event
  ) =>
  (form: { [key: string]: InputState }) => {
    const contextValue = updateFlowContext(form, fields, setFlowContext, contextEntry);
    return processContactInfo(
      {
        ...flowContext,
        [contextEntry]: { ...flowContext[contextEntry], ...contextValue },
      },
      setFlowContext,
      setShowLoginPopup,
      onComplete,
      event
    );
  };

async function processContactInfo(
  flowContext: FlowContext,
  setFlowContext: React.Dispatch<React.SetStateAction<FlowContext>>,
  setShowLoginPopup: React.Dispatch<React.SetStateAction<boolean>>,
  onComplete: (newFlowContext, outputs) => any,
  event?: Event
): Promise<any> {
  let res, jsonRes;
  try {
    const payload = {
      ...flowContext.patientContactInfo,
      legalGuardian: flowContext.responsiblePartyContactInfo,
      selectedPracticeToken:
        flowContext.selectedPractice?.practiceToken || flowContext.practiceToken,
      selectedDoctorToken: flowContext.selectedDoctor?.doctorToken,
    };
    res = await sfetch(`${flowContext.urlPatientRoot}?practiceToken=${flowContext.practiceToken}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    try {
      jsonRes = await e.json();
    } catch (e) {
      jsonRes = {};
    }
    if (e.status === 409 && jsonRes.obfuscatedEmails) {
      setFlowContext((ctx) => ({
        ...ctx,
        selectedPatientId: jsonRes.patientUid,
        patientObfuscatedEmails: jsonRes.obfuscatedEmails,
        patientSubStepId: PatientSubStepId.EMAIL_CONFIRMATION_FORM,
        steps: [...ctx.steps, PatientSubStepId.EMAIL_CONFIRMATION_FORM],
        stepsCompleted: [...ctx.stepsCompleted, new CompletedStep(ctx.patientSubStepId, [])],
      }));
    } else if (e.status === 409) {
      switch (jsonRes.cause) {
        case "USER_ALREADY_REGISTERED":
          setShowLoginPopup(true);
          break;
        case "USER_EMAIL_DOES_NOT_MATCH":
          flowContext.setError({
            title: i18n["Global.TechnicalError.Title"],
            content: i18n["Form.VirtualConsultation.EmailConfirmation.Email.Not.Match.Error"],
            action: async () => document.location.reload(),
          } as FlowError);
          break;
      }
    } else {
      if (jsonRes.code == ErrorCode.BAD_PHONE_NUMBER) {
        flowContext.setError({
          ...technicalFlowError,
          content: jsonRes.message,
        });
      } else {
        flowContext.setError(technicalFlowError);
      }
      Sentry.captureException(e);
      e.alreadyHandled = true;
      throw e;
    }
  }
  if (res.status == 200) {
    jsonRes = await res.json();
    flowContext.sendEvent(Event.CONTACT_INFO_SUBMITTED);
    if (event) flowContext.sendEvent(event);
    setFlowContext((ctx) => ({
      ...ctx,
      stepsCompleted: [
        ...ctx.stepsCompleted,
        new CompletedStep(ctx.patientSubStepId, ["patientCreationTimestamp"]),
      ],
    }));
    onComplete(
      {
        selectedPatientId: jsonRes.patientUid,
        patientCreationTimestamp: new Date().getTime(),
      },
      ["patientCreationTimestamp"]
    );
  } else {
    console.error("Unsupported response status for patient creation : " + res.status);
  }
}
