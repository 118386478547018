import DateInput from "../../components/form/input/DateInput";
import TelInput from "../../components/form/input/TelInput";
import ZipCodeInput from "../../components/form/input/ZipCodeInput";
import RadioInput from "../../components/form/input/RadioInput";
import TextInput from "../../components/form/input/TextInput";
import EmailInput from "../../components/form/input/EmailInput";
import BirthDateUpdateInput from "../../components/form/input/BirthDateUpdateInput";
import FileInput from "../../components/form/input/FileInput";
import TextAreaInput from "../../components/form/input/TextAreaInput";
import HealthIssueCheckboxInput from "../../components/form/input/HealthIssueCheckboxInput";
import MultiSelectCheckboxInput from "../../components/form/input/MultiSelectCheckboxInput";
import ToggleButton from "../../components/buttons/ToggleButton";

export default interface Field {
  key: FieldKey;
  type: FieldType;
  name: string;
  mandatory: boolean;
  available: boolean;
  hidden: boolean;
  messageLevel: MessageLevel;
  disabled: boolean;
}

export enum FieldKey {
  NAME = "NAME",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  EMAIL = "EMAIL",
  ZIP_CODE = "ZIP_CODE",
  CELL_PHONE_NUMBER = "CELL_PHONE_NUMBER",
  WORK_PHONE_NUMBER = "WORK_PHONE_NUMBER",
  HOME_PHONE_NUMBER = "HOME_PHONE_NUMBER",
  BIRTH_DATE = "BIRTH_DATE",
  EXTERNAL_USER_ID = "EXTERNAL_USER_ID",
  MIDDLE_NAME = "MIDDLE_NAME",
  PREFERRED_NAME = "PREFERRED_NAME",
  GENDER = "GENDER",
  SSN = "SSN",
  ADDRESS = "ADDRESS",
  CITY = "CITY",
  STATE = "STATE",
  EMPLOYER = "EMPLOYER",
  INSURANCE_ID = "INSURANCE_ID",
  EMPLOYER_ID = "EMPLOYER_ID",
  COMPANY_NAME = "COMPANY_NAME",
  COMPANY_PHONE = "COMPANY_PHONE",
  COMPANY_GROUP_NUMBER = "COMPANY_GROUP_NUMBER",
  INSURANCE_CARD = "INSURANCE_CARD",
  PRACTICE_NAME = "PRACTICE_NAME",
  DENTIST_NAME = "DENTIST_NAME",
  LAST_DENTAL_CLEANING = "LAST_DENTAL_CLEANING",
  PENDING_TREATMENT = "PENDING_TREATMENT",
  PENDING_TREATMENT_DESCRIPTION = "PENDING_TREATMENT_DESCRIPTION",
  HEALTH_ISSUES = "HEALTH_ISSUES",
  CURRENT_MEDICATIONS = "CURRENT_MEDICATIONS",
  DRUG_ALLERGIES = "DRUG_ALLERGIES",
  TAKEN_OSTEOPOROSIS_MEDICATIONS = "TAKEN_OSTEOPOROSIS_MEDICATIONS",
  ADDITIONAL_HEALTH_INFORMATION = "ADDITIONAL_HEALTH_INFORMATION",
  HAS_AUTHORIZATION = "HAS_AUTHORIZATION",
  SMILE_SATISFACTION = "SMILE_SATISFACTION",
  HAS_HOUSEHOLD = "HAS_HOUSEHOLD",
  HAS_LEGAL_GUARDIAN = "HAS_LEGAL_GUARDIAN",
  ACCEPT_MARKETING_EMAIL = "ACCEPT_MARKETING_EMAIL"
}

export enum MessageLevel {
  NONE = "NONE",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

export class CustomErrors {
  isErrors: boolean = false;
  message?: String;

  constructor(isError: boolean, message?: string) {
    this.isErrors = isError;
    this.message = message;
  }
}

export namespace FieldKey {
  export function getTitle(field: Field): string {
    switch (field.key) {
      case FieldKey.NAME:
        return "Form.Field.Title.Name";
      case FieldKey.FIRST_NAME:
        return "Form.Field.Title.FirstName";
      case FieldKey.LAST_NAME:
        return "Form.Field.Title.LastName";
      case FieldKey.EMAIL:
        return "Form.Field.Title.Email";
      case FieldKey.ZIP_CODE:
        return "Form.Field.Title.ZipCode";
      case FieldKey.CELL_PHONE_NUMBER:
        return "Form.Field.Title.PhoneNumber.Cell";
      case FieldKey.WORK_PHONE_NUMBER:
        return "Form.Field.Title.PhoneNumber.Work";
      case FieldKey.HOME_PHONE_NUMBER:
        return "Form.Field.Title.PhoneNumber.Home";
      case FieldKey.BIRTH_DATE:
        return "Form.Field.Title.BirthDate";
      case FieldKey.EXTERNAL_USER_ID:
        return "Form.Field.Title.ExternalUserId";
      case FieldKey.MIDDLE_NAME:
        return "Form.Field.Title.MiddleName";
      case FieldKey.PREFERRED_NAME:
        return "Form.Field.Title.PreferredName";
      case FieldKey.GENDER:
        return "Form.Field.Title.Gender";
      case FieldKey.SSN:
        return "Form.Field.Title.SSN";
      case FieldKey.ADDRESS:
        return "Form.Field.Title.Address";
      case FieldKey.CITY:
        return "Form.Field.Title.City";
      case FieldKey.STATE:
        return "Form.Field.Title.State";
      case FieldKey.EMPLOYER:
        return "Form.Field.Title.Employer";
      case FieldKey.INSURANCE_ID:
        return "Form.Field.Title.InsuranceId";
      case FieldKey.EMPLOYER_ID:
        return "Form.Field.Title.EmployerId";
      case FieldKey.COMPANY_NAME:
        return "Form.Field.Title.CompanyName";
      case FieldKey.COMPANY_PHONE:
        return "Form.Field.Title.CompanyPhone";
      case FieldKey.COMPANY_GROUP_NUMBER:
        return "Form.Field.Title.CompanyGroupNumber";
      case FieldKey.INSURANCE_CARD:
        return "Form.Field.Title.InsuranceCard";
      case FieldKey.PRACTICE_NAME:
        return "Form.Field.Title.PracticeName";
      case FieldKey.DENTIST_NAME:
        return "Form.Field.Title.DentistName";
      case FieldKey.LAST_DENTAL_CLEANING:
        return "Form.Field.Title.LastDentalCleaning";
      case FieldKey.PENDING_TREATMENT:
        return "Form.Field.Title.PendingTreatment";
      case FieldKey.PENDING_TREATMENT_DESCRIPTION:
        return "Form.Field.Title.PendingTreatmentDescription";
      case FieldKey.HEALTH_ISSUES:
        return "Form.Field.Title.HealthIssues";
      case FieldKey.CURRENT_MEDICATIONS:
        return "Form.Field.Title.CurrentMedications";
      case FieldKey.DRUG_ALLERGIES:
        return "Form.Field.Title.DrugAllergies";
      case FieldKey.TAKEN_OSTEOPOROSIS_MEDICATIONS:
        return "Form.Field.Title.TakenOsteoporosisMedications";
      case FieldKey.ADDITIONAL_HEALTH_INFORMATION:
        return "Form.Field.Title.AdditionalHealthInformation";
      case FieldKey.HAS_AUTHORIZATION:
        return "Form.Authorization.ToggleButton.Title";
      case FieldKey.SMILE_SATISFACTION:
        return "Form.DentalInformation.Field.SmileSatisfaction.Title";
    }
  }

  export function getPlaceholder(field: Field) {
    switch (field.key) {
      case FieldKey.NAME:
        return "Form.Field.Placeholder.Name";
      case FieldKey.FIRST_NAME:
        return "Form.Field.Placeholder.FirstName";
      case FieldKey.LAST_NAME:
        return "Form.Field.Placeholder.LastName";
      case FieldKey.EMAIL:
        return "Form.Field.Placeholder.Email";
      case FieldKey.ZIP_CODE:
        return "Form.Field.Placeholder.ZipCode";
      case FieldKey.CELL_PHONE_NUMBER:
        return "Form.Field.Placeholder.CellPhoneNumber";
      case FieldKey.WORK_PHONE_NUMBER:
        return "Form.Field.Placeholder.WorkPhoneNumber";
      case FieldKey.HOME_PHONE_NUMBER:
        return "Form.Field.Placeholder.HomePhoneNumber";
      case FieldKey.BIRTH_DATE:
        return "Form.Field.Placeholder.BirthDate";
      case FieldKey.EXTERNAL_USER_ID:
        return "Form.Field.Placeholder.ExternalUserId";
      case FieldKey.MIDDLE_NAME:
        return "Form.Field.Placeholder.MiddleName";
      case FieldKey.PREFERRED_NAME:
        return "Form.Field.Placeholder.PreferredName";
      case FieldKey.SSN:
        return "Form.Field.Placeholder.SSN";
      case FieldKey.ADDRESS:
        return "Form.Field.Placeholder.Address";
      case FieldKey.CITY:
        return "Form.Field.Placeholder.City";
      case FieldKey.STATE:
        return "Form.Field.Placeholder.State";
      case FieldKey.EMPLOYER:
        return "Form.Field.Placeholder.Employer";
      case FieldKey.INSURANCE_ID:
        return "Form.Field.Placeholder.InsuranceId";
      case FieldKey.EMPLOYER_ID:
        return "Form.Field.Placeholder.EmployerId";
      case FieldKey.COMPANY_NAME:
        return "Form.Field.Placeholder.CompanyName";
      case FieldKey.COMPANY_GROUP_NUMBER:
        return "Form.Field.Placeholder.CompanyGroupNumber";
      case FieldKey.COMPANY_PHONE:
        return "Form.Field.Placeholder.CompanyPhone";
      case FieldKey.PRACTICE_NAME:
        return "Form.Field.Placeholder.PracticeName";
      case FieldKey.DENTIST_NAME:
        return "Form.Field.Placeholder.DentistName";
      case FieldKey.LAST_DENTAL_CLEANING:
        return "Form.Field.Placeholder.LastDentalCleaning";
      case FieldKey.PENDING_TREATMENT_DESCRIPTION:
        return "Form.Field.Placeholder.PendingTreatmentDescription";
      case FieldKey.CURRENT_MEDICATIONS:
        return "Form.Field.Placeholder.CurrentMedications";
      case FieldKey.DRUG_ALLERGIES:
        return "Form.Field.Placeholder.DrugAllergies";
      case FieldKey.ADDITIONAL_HEALTH_INFORMATION:
        return "Form.Field.Placeholder.AdditionalHealthInformation";
      case FieldKey.INSURANCE_CARD:
      case FieldKey.PENDING_TREATMENT:
      case FieldKey.GENDER:
      case FieldKey.HEALTH_ISSUES:
      case FieldKey.TAKEN_OSTEOPOROSIS_MEDICATIONS:
      case FieldKey.HAS_AUTHORIZATION:
      case FieldKey.SMILE_SATISFACTION:
        return null;
    }
  }
  export function getPatientFieldTitle(field: Field): string {
    switch (field.key) {
      case FieldKey.FIRST_NAME:
        return "Form.Patient.Field.Title.FirstName";
      case FieldKey.LAST_NAME:
        return "Form.Patient.Field.Title.LastName";
      case FieldKey.BIRTH_DATE:
        return "Form.Patient.Field.Title.BirthDate";
      case FieldKey.CELL_PHONE_NUMBER:
        return "Form.Field.Title.PhoneNumber";
      default:
        return getTitle(field);
    }
  }

  export function getResponsiblePartyFieldTitle(field: Field): string {
    switch (field.key) {
      case FieldKey.CELL_PHONE_NUMBER:
        return "Form.Field.Title.PhoneNumber";
      default:
        return getTitle(field);
    }
  }
}

export enum FieldType {
  TEXT = "TEXT",
  EMAIL = "EMAIL",
  DATE = "DATE",
  PHONE_NUMBER = "PHONE_NUMBER",
  ZIP_CODE = "ZIP_CODE",
  RADIO = "RADIO",
  FILE = "FILE",
  TEXTAREA = "TEXTAREA",
  CHECKBOX = "CHECKBOX",
  HEALTH_ISSUE_CHECKBOX = "HEALTH_ISSUE_CHECKBOX",
  TOGGLE = "TOGGLE",
}

export function getInput(field: Field) {
  switch (field.type) {
    case FieldType.EMAIL:
      return EmailInput;
    case FieldType.DATE:
      return DateInput;
    case FieldType.PHONE_NUMBER:
      return TelInput;
    case FieldType.ZIP_CODE:
      return ZipCodeInput;
    case FieldType.RADIO:
      return RadioInput;
    case FieldType.FILE:
      return FileInput;
    case FieldType.TEXTAREA:
      return TextAreaInput;
    case FieldType.CHECKBOX:
      return MultiSelectCheckboxInput;
    case FieldType.HEALTH_ISSUE_CHECKBOX:
      return HealthIssueCheckboxInput;
    case FieldType.TOGGLE:
      return ToggleButton;
    default:
      return TextInput;
  }
}

export function getInputForUpdate(field: Field) {
  switch (field.type) {
    case FieldType.EMAIL:
      return EmailInput;
    case FieldType.DATE:
      if (field.key == FieldKey.BIRTH_DATE) {
        return BirthDateUpdateInput;
      } else {
        return DateInput;
      }
    default:
      return getInput(field);
  }
}
