import * as React from "react";
import { ReactElement } from "react";
import Avatar, { AvatarSize, AvatarUserInfo } from "./Avatar";
import { buildClasses } from "../../utils/classes";
import "./UserCardRegular.scss";
import { NavLink } from "react-router-dom";

interface UserCardRegularInfo extends AvatarUserInfo {
  email?: string;
}

export default function UserCardRegular({
  userInfo,
  onClick,
  selected,
  upperSection,
  lowerSection,
}: {
  userInfo: UserCardRegularInfo;
  onClick?: () => any;
  selected?: boolean;
  upperSection?: ReactElement;
  lowerSection?: ReactElement;
}) {
  return (
    <div
      className={buildClasses(
        { clickable: !!onClick, selected: selected },
        "user-card-regular h-flex no-wrap"
      )}
      onClick={onClick}
    >
      <InternalUserCardRegular
        userInfo={userInfo}
        upperSection={upperSection}
        lowerSection={lowerSection}
      />
    </div>
  );
}

export function NavigableUserCardRegular({
  userInfo,
  path,
}: {
  userInfo: UserCardRegularInfo;
  path: string;
}) {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        ["clickable", "user-card-regular", "h-flex", "no-wrap", isActive ? "myActiveClass" : null]
          .filter(Boolean)
          .join(" ")
      }
      onClick={() => setTimeout(() => window.location.reload(), 100)}
    >
      <InternalUserCardRegular userInfo={userInfo} />
    </NavLink>
  );
}

function InternalUserCardRegular({
  userInfo,
  upperSection,
  lowerSection,
}: {
  userInfo: UserCardRegularInfo;
  upperSection?: ReactElement;
  lowerSection?: ReactElement;
}) {
  return (
    <>
      <Avatar size={AvatarSize.REGULAR} userInfo={userInfo} />
      <div className={"user-identity-details"}>
        {upperSection}
        <div>{userInfo.name}</div>
        {lowerSection}
      </div>
    </>
  );
}
