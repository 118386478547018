import * as React from "react";
import { PracticeDetails, PracticeWithDistance } from "./Practice";
import { formatDistance } from "../commons/utils/distance";
import { fillTemplateString } from "../commons/utils/text-rendering";
import "./PracticeDetailsCard.scss";
import PhoneNumber from "../commons/components/PhoneNumber";
import { Info } from "../commons/components/Alerts";
import { Distance } from "../commons/model/address/Distance";
import { sanitize } from "../commons/utils/sanitize";

export function PracticeDetailsCard<P extends PracticeDetails>({
  practiceDetails,
  displayOnMap,
  distanceDisplay,
  onSelect,
  selected,
  children,
}: {
  practiceDetails: P;
  displayOnMap?: boolean;
  distanceDisplay?: {
    fallbackMaxDistance: Distance;
  };
  onSelect?: (practice: P) => void;
  selected?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={
        "practice-details-card" + (onSelect ? " clickable " : "") + (selected ? " selected" : "")
      }
      onClick={() => onSelect && onSelect(practiceDetails)}
    >
      <PracticeDetailsCardContent
        practiceDetails={practiceDetails}
        displayOnMap={displayOnMap}
        distanceDisplay={distanceDisplay}
      />
      {children}
    </div>
  );
}

function PracticeDetailsCardContent({
  practiceDetails,
  displayOnMap,
  distanceDisplay,
}: {
  practiceDetails: PracticeDetails;
  displayOnMap?: boolean;
  distanceDisplay?: {
    fallbackMaxDistance: Distance;
  };
}) {
  const fullAddress = practiceDetails.address.displayAddress;
  return (
    <>
      <div className="h-flex space-between no-wrap">
        <div className="name">{practiceDetails.name}</div>
        {distanceDisplay && (
          <DistanceFromPatient
            distance={
              PracticeWithDistance.hasDistance(practiceDetails) ? practiceDetails.distance : null
            }
            fallbackMaxDistance={distanceDisplay?.fallbackMaxDistance}
          />
        )}
      </div>
      <ul>
        <li className="ic_pin">{fullAddress}</li>
        <li className="ic_phone">
          <PhoneNumber number={practiceDetails.phoneNumber} />
        </li>
      </ul>
      {displayOnMap && !practiceDetails.address.coordinates && (
        <Info className={"no-position"}>{i18n["Page.ScanUploader.SelectPractice.NoPosition"]}</Info>
      )}
    </>
  );
}

function DistanceFromPatient({
  distance,
  fallbackMaxDistance,
}: {
  distance?: Distance;
  fallbackMaxDistance: Distance;
}) {
  return (
    <div
      className="distance"
      dangerouslySetInnerHTML={{
        __html: sanitize(
          distance
            ? fillTemplateString(i18n["Page.ScanUploader.SelectPractice.DistanceFromPatient"], {
                distance: formatDistance(distance),
              })
            : fillTemplateString(i18n["Page.ScanUploader.SelectPractice.MaxDistanceFromPatient"], {
                maxDistance: formatDistance(fallbackMaxDistance),
              })
        ),
      }}
    />
  );
}
