import * as React from "react";
import FormInput from "./FormInput";
import InputProps from "../../../model/form/InputProps";
import { buildClasses } from "../../../utils/classes";
import { emailValidator } from "../../../model/form/Validators";
import { MessageLevel } from "../../../model/form/Field";
import InputState from "../../../model/form/InputState";

export default class EmailInput extends React.Component<InputProps> {
  constructor(props: InputProps) {
    super(props);
    this.template = this.template.bind(this);
  }

  template(state: InputState, input) {
    return (
      <div
        className={buildClasses(
          {
            [this.props.additionalClass]: () => !!this.props.additionalClass,
            invalid: () =>
              (state.status.invalid && !state.status.pristine) || this.props.customErrors?.isErrors,
            none: () => state.status.hidden,
          },
          "input-group"
        )}
      >
        <label htmlFor={this.props.name}>
          <span>{this.props.label}</span>
          <span className="detail">{this.props.detail}</span>
        </label>
        <div className="group">
          <input
            type="email"
            name={this.props.name}
            id={this.props.name}
            placeholder={this.props.placeholder}
            onChange={input.change}
            onBlur={input.dirty}
            value={state.value}
            readOnly={this.props.readOnly}
            disabled={this.props.disabled}
          />
          {!state.status.invalid &&
            !this.props.customErrors?.isErrors &&
            !state.status.pristine &&
            !this.props.disabled && (
              <div className="h-flex centered valid">
                <i className="ic_check" />
              </div>
            )}
        </div>
        <div className="input-accessories">
          {this.props.customErrors?.isErrors && (
            <div className="error-message"> {this.props.customErrors.message}</div>
          )}
          {!this.props.customErrors?.isErrors && (
            <div className="error-message">{state.status.errorMessage}</div>
          )}
          {!state.status.pristine &&
            !state.status.invalid &&
            (this.props.messageLevel == MessageLevel.INFO ||
              this.props.messageLevel == MessageLevel.WARN) && (
              <div
                className={
                  this.props.messageLevel == MessageLevel.WARN ? "warning-message" : "info-message"
                }
              >
                {state.status.errorMessage}
              </div>
            )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <FormInput
        {...this.props}
        validators={[
          ...(this.props.validators || []),
          emailValidator(i18n["Form.Error.InvalidEmail"], this.props.messageLevel),
        ]}
        template={this.template}
      />
    );
  }
}
