import * as React from "react";
import * as Sentry from "@sentry/browser";
import {
  DocumentDescriptor,
  getConsentLink,
  getDocumentHref,
  getLegalDocuments,
  LegalDocumentVersion,
  LegalResource,
  LoadedDocumentInfo,
  ResourceDescriptor,
} from "./LegalResource";
import Checkbox from "../commons/components/noform/Checkbox";
import sfetch from "../commons/utils/super-fetch";
import "./ConsentCheckboxInput.scss";
import FormInput from "../commons/components/form/input/FormInput";
import InputProps from "../commons/model/form/InputProps";
import { associateBy, equals } from "../../ts/arrays";
import { MessageLevel } from "../commons/model/form/Field";
import { LegalDocumentConsent } from "./UserConsentRequest";
import InputState from "../commons/model/form/InputState";
import Country from "../commons/model/address/Country";
import Option from "../commons/model/Option";

interface ConsentCheckboxInputProps extends InputProps {
  legalResources: LegalResource[];
  country: Country;
  practiceToken?: string;
  onError: (err) => any;
  dsoPrivacyPolicyCustomText?: string;
}

interface ConsentCheckboxInputState {
  loadedDocuments: Map<DocumentDescriptor, LoadedDocumentInfo>;
  acceptedResources: ResourceDescriptor[];
}

export default class ConsentCheckboxInput extends React.PureComponent<
  ConsentCheckboxInputProps,
  ConsentCheckboxInputState
> {
  constructor(props: ConsentCheckboxInputProps) {
    super(props);
    this.template = this.template.bind(this);
    this.state = {
      loadedDocuments: new Map<DocumentDescriptor, LoadedDocumentInfo>(),
      acceptedResources: []
    };
    this.preLoadLegalDocument = this.preLoadLegalDocument.bind(this);
    this.validator = this.validator.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validator["messageLevel"] = MessageLevel.ERROR;
  }

  componentDidMount() {
    this.loadDocuments();
  }

  componentDidUpdate(prevProps: Readonly<ConsentCheckboxInputProps>) {
    if (prevProps.country != this.props.country) {
      this.loadDocuments();
    }
  }

  private async loadDocuments() {
    try {
      const documents: LoadedDocumentInfo[] = await Promise.all(
        getLegalDocuments(this.props.legalResources).map((legalDocument) =>
          this.preLoadLegalDocument(legalDocument, this.props.practiceToken)
        )
      );
      this.setState({
        loadedDocuments: associateBy(documents, (doc) => doc.descriptor)
      });
    } catch (err) {
      this.props.onError(err);
      Sentry.captureException(err);
    }
  }

  template(state: InputState, input) {
    return (
      <div className="consent-checkbox-container">
        {this.props.legalResources.map((resource) => (
            <Checkbox
              name={resource.descriptor}
              key={resource.descriptor}
              checked={this.state.acceptedResources.includes(resource.descriptor)}
              option={
                new Option(
                  resource.descriptor,
                  getConsentLink(resource, this.state.loadedDocuments)
                )
              }
              secondOption={this.props.dsoPrivacyPolicyCustomText}
              onChange={(checked) =>
                this.onChange(state, input, resource, checked)
              }
              disabled={
                !resource.documents.every(
                  // TODO: add function isLoaded()
                  (doc) => this.state.loadedDocuments.get(doc.descriptor)?.hash
                )
              }
            />
          )
        )
        }
      </div>
    );
  }

  private onChange(
    inputState: InputState,
    input,
    resource: LegalResource,
    checked: boolean
  ) {
    const newAcceptedResources = checked
      ? [...this.state.acceptedResources, resource.descriptor]
      : this.state.acceptedResources.filter((it) => it != resource.descriptor);

    inputState.setValue(this.computeValue(newAcceptedResources));
    input.dirty(); // TODO: can be removed ?
    this.setState({
      acceptedResources: newAcceptedResources
    });
  }

  private computeValue(acceptedResources: ResourceDescriptor[]): LegalDocumentConsent[] {
    return this.getLegalDocuments(acceptedResources).map((doc) => {
      const loadedDoc = this.state.loadedDocuments.get(doc.descriptor);
      return {
        descriptor: doc.descriptor,
        version: doc.version,
        hash: loadedDoc.hash
      };
    });
  }

  private getLegalDocuments(acceptedResources: ResourceDescriptor[]) {
    return getLegalDocuments(
      this.props.legalResources.filter((r) => acceptedResources.includes(r.descriptor))
    );
  }

  render() {
    return (
      <FormInput
        {...this.props}
        initialValue={[]}
        template={this.template}
        validators={[this.validator]}
      />
    );
  }

  private validator(value: LegalDocumentConsent[]): boolean {
    const allDocuments = getLegalDocuments(this.props.legalResources)
      .map((doc) => doc.descriptor)
      .sort();
    const acceptedDocuments = value.map((doc) => doc.descriptor).sort();
    return !equals(acceptedDocuments, allDocuments);
  }

  private async preLoadLegalDocument(
    legalDocument: LegalDocumentVersion,
    practiceToken: string
  ): Promise<LoadedDocumentInfo> {
    const url = getDocumentHref(
      legalDocument.descriptor,
      this.props.country,
      legalDocument.version,
      practiceToken
    );
    const response = await sfetch(url, { method: "GET" });
    return {
      descriptor: legalDocument.descriptor,
      hash: response.headers.get("X-DM-Hash-Sha256"),
      legalDocumentUrl: URL.createObjectURL(await response.blob())
    };
  }
}
