import { useEffect } from "react";

export default function useHidePracticeReminder(setFlowContext) {
  useEffect(() => {
    setFlowContext((ctx) => ({
      ...ctx,
      hidePracticeReminder: true,
    }));
  }, []);
}
