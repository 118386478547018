import * as React from "react";
import I18nAction from "../../commons/components/I18nAction";
import "./AlreadyHaveAnAccount.scss";
import openAuthenticatedFlow from "./openAuthenticatedFlow";

export default function AlreadyHaveAnAccount(props: {
  authenticatedFlowUrl: string;
  practiceToken: string;
}) {
  return (
    <>
      <div className="h-flex justify-center">
        <div className="log-in">
          <I18nAction
            action={() => openAuthenticatedFlow(props.authenticatedFlowUrl, props.practiceToken)}
          >
            {i18n["Page.ScanUploader.AlreadyHaveAnAccount"]}
          </I18nAction>
        </div>
      </div>
    </>
  );
}
