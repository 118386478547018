import * as React from "react";
import InputProps from "../../../model/form/InputProps";
import InputState from "../../../model/form/InputState";
import sfetch from "../../../utils/super-fetch";
import DateInput from "./DateInput";
import { formatDate } from "../../../utils/form-utils";

interface BirthDateUpdateInputProps extends InputProps {
  initialValue?: Date;
  patientId: string;
}

export default class BirthDateUpdateInput extends React.PureComponent<BirthDateUpdateInputProps> {
  private timeoutAction;

  private verifyBirthDate(state: InputState) {
    if (!this.props.initialValue || this.props.initialValue == state.value) {
      return;
    }
    if (!state.status.invalid) {
      clearTimeout(this.timeoutAction);
      state.status.setError("");
      this.timeoutAction = setTimeout(async () => {
        try {
          const compatible = await (
            await sfetch(
              `/internal-api/v1/patient/birth-dates-compatible?initialDate=${formatDate(
                this.props.initialValue
              )}&selectedDate=${formatDate(state.value)}&patientId=${this.props.patientId}`,
              { method: "get" }
            )
          ).json();
          if (compatible) {
            state.status.setError(null);
          } else {
            state.status.setError(i18n["Form.Error.BirthDate"]);
          }
        } catch (e) {
          state.status.setError(i18n["Global.TechnicalError"]);
        }
      }, 500);
    }
  }

  render() {
    return (
      <DateInput
        {...this.props}
        onChange={(state: InputState) => {
          this.verifyBirthDate(state);
          if (this.props.onChange) {
            this.props.onChange(state);
          }
        }}
      />
    );
  }
}
