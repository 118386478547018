import { useEffect } from "react";
import { requestContainerResizing } from "./events";

export default function useAutoResizeIframe() {
  useEffect(() => {
    const resizeHandler = () => requestContainerResizing(false);
    resizeHandler();
    const resizeInterval = setInterval(resizeHandler, 250);
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
      clearInterval(resizeInterval);
    };
  }, []);
}
