import * as React from "react";
import "./PictureShotGuidelines.scss";
import RemoveGlasses from "../../../resources/images/scan-example/guidelines/remove_glasses.svg";
import StayCloseToCamera from "../../../resources/images/scan-example/guidelines/stay_close_to_camera.svg";
import UseFlash from "../../../resources/images/scan-example/guidelines/use_flash.svg";

export default function PictureShotGuidelines() {
  interface GuidelinesConfiguration {
    icon: any;
    label: string;
  }

  const guidelinesConfiguration: GuidelinesConfiguration[] = [
    {
      icon: RemoveGlasses,
      label: "Page.ScanUploader.FacePhotos.Guidelines.RemoveGlasses",
    },
    {
      icon: StayCloseToCamera,
      label: "Page.ScanUploader.FacePhotos.Guidelines.StayCloseToCamera",
    },
    {
      icon: UseFlash,
      label: "Page.ScanUploader.FacePhotos.Guidelines.UseFlash",
    },
  ];

  return (
    <div className="h-flex justify-center">
      <div className="panel picture-shot-guidelines">
        {guidelinesConfiguration.map((it, index) => (
          <div key={index} className="v-flex">
            <img src={it.icon} alt={it.label} />
            <span>{i18n[it.label]}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
