import * as React from "react";
import "./Avatar.scss";

export interface AvatarUserInfo {
  name: string;
  avatarHref: string;
  isGrayBackground?: boolean;
}

export interface AvatarProps {
  userInfo: AvatarUserInfo;
  size: AvatarSize;
}

interface AvatarState {
  imageUrl?: string;
}

export enum AvatarSize {
  SMALL = "small",
  REGULAR = "regular",
  MEDIUM = "medium",
}

export default class Avatar extends React.PureComponent<AvatarProps, AvatarState> {
  state: AvatarState = {};

  render() {
    const regex = /(^| )(.)/g;
    let initials = "";
    let match;
    while ((match = regex.exec(this.props.userInfo.name))) {
      initials += match[2];
    }
    return (
      <div
        className={`avatar ${this.props.size}${
          this.state.imageUrl ? " available" : ""
        }${this.props.userInfo.isGrayBackground ? " gray-background" : ""}`}
      >
        <div>
          {this.state.imageUrl ? (
            <img src={this.state.imageUrl} alt="avatar" />
          ) : (
            initials.substring(0, 2).toUpperCase()
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.userInfo.avatarHref !== "") {
      this.fetchAvatar();
    }
  }

  componentDidUpdate(prevProps: Readonly<AvatarProps>) {
    if (
      prevProps.userInfo.avatarHref !== this.props.userInfo.avatarHref &&
      this.props.userInfo.avatarHref !== ""
    ) {
      this.fetchAvatar();
    }
  }

  private fetchAvatar() {
    fetch(this.props.userInfo.avatarHref, {})
      .then((res) => res.ok && res.blob())
      .then((blob) => blob && this.setState({ imageUrl: URL.createObjectURL(blob) }))
      .catch((e) => console.error(e));
  }
}
