import "whatwg-fetch";
import { getConfigurationWithCsrf } from "../../../ts/csrf";

async function sfetch(url: RequestInfo, configuration: RequestInit) {
  const configurationWithCsrf: RequestInit = await getConfigurationWithCsrf(url, configuration);
  return fetch(url, configurationWithCsrf).then((res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res;
    } else {
      if (res.status == 401) {
        document.location.reload();
      }
      throw res;
    }
  });
}

export default sfetch;
