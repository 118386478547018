enum StepId {
  LOCATION_STEP = "LOCATION_STEP",
  PRACTICE_SELECTOR_STEP = "PRACTICE_SELECTOR_STEP",
  PATIENT_SELECTOR_STEP = "PATIENT_SELECTOR_STEP",
  VIRTUAL_CONSULTATION_STEP = "VIRTUAL_CONSULTATION_STEP",
  QUESTIONS_SUB_STEP = "QUESTIONS_SUB_STEP",
  PHOTOS_FACE_SUB_STEP = "PHOTOS_FACE_SUB_STEP",
  PHOTOS_CLOSED_SUB_STEP = "PHOTOS_CLOSED_SUB_STEP",
  PHOTOS_OPENED_SUB_STEP = "PHOTOS_OPENED_SUB_STEP",
  PATIENT_STEP = "PATIENT_STEP",
  VIRTUAL_CONSULTATION_CONFIRMATION_STEP = "VIRTUAL_CONSULTATION_CONFIRMATION_STEP",
}

export default StepId;
