(global as any).isCsrfNeeded = true;

export async function getConfigurationWithCsrf(
  url: RequestInfo,
  configuration: RequestInit
): Promise<RequestInit> {
  if (isCsrfNeeded(url, configuration)) {
    let csrfCookie = getCsrfToken();
    if (!csrfCookie) {
      await fetch("/internal-api/v1/csrf", { method: "HEAD" });
      csrfCookie = getCsrfToken();
      (global as any).isCsrfNeeded = Boolean(csrfCookie);
    }
    return buildConfiguration(configuration, csrfCookie);
  } else {
    return configuration;
  }
}

function isCsrfNeeded(url: RequestInfo, configuration: RequestInit): boolean {
  return (
    (global as any).isCsrfNeeded &&
    url.toString().includes("/internal-api/") &&
    isCsrfMethod(configuration.method)
  );
}

function getCsrfToken(): string {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
}

function buildConfiguration(
  configuration: RequestInit,
  csrfToken: string
): RequestInit {
  if (csrfToken) {
    return {
      ...configuration,
      headers: { ...configuration?.headers, "X-XSRF-TOKEN": csrfToken },
    };
  } else return configuration;
}

function isCsrfMethod(method: string): boolean {
  return ["POST", "PUT", "DELETE", "PATCH"].includes(method.toUpperCase());
}