enum HealthIssue {
  ABNORMAL_BLEEDING = "ABNORMAL_BLEEDING",
  ALLERGIES_TO_ANY_DRUGS = "ALLERGIES_TO_ANY_DRUGS",
  ANOREXIA_OR_BULIMIA = "ANOREXIA_OR_BULIMIA",
  ASTHMA = "ASTHMA",
  CANCER = "CANCER",
  CONGENITAL_HEART_DEFECT = "CONGENITAL_HEART_DEFECT",
  CONVULSION_OR_EPILEPSY = "CONVULSION_OR_EPILEPSY",
  DIABETES = "DIABETES",
  FINGER_SUCKING = "FINGER_SUCKING",
  HANDICAPS_OR_DISABILITIES = "HANDICAPS_OR_DISABILITIES",
  HEARING_IMPAIRED = "HEARING_IMPAIRED",
  HEART_MURMUR = "HEART_MURMUR",
  HEMOPHILIA = "HEMOPHILIA",
  HEPATITIS_A_B_OR_C = "HEPATITIS_A_B_OR_C",
  HIV_POSITIVE_OR_AIDS = "HIV_POSITIVE_OR_AIDS",
  JAW_CLICKING = "JAW_CLICKING",
  KIDNEY_OR_LIVER_PROBLEMS = "KIDNEY_OR_LIVER_PROBLEMS",
  LATEX_ALLERGY = "LATEX_ALLERGY",
  METAL_ALLERGIES = "METAL_ALLERGIES",
  PREGNANT = "PREGNANT",
  RHEUMATIC_FEVER = "RHEUMATIC_FEVER",
  SCARLET_FEVER = "SCARLET_FEVER",
  SMOKING_OR_TOBACCO = "SMOKING_OR_TOBACCO",
  TUBERCULOSIS = "TUBERCULOSIS",
  NONE = "NONE",
}

export const healthIssuesOrdered = [
  HealthIssue.ABNORMAL_BLEEDING,
  HealthIssue.ALLERGIES_TO_ANY_DRUGS,
  HealthIssue.ANOREXIA_OR_BULIMIA,
  HealthIssue.ASTHMA,
  HealthIssue.CANCER,
  HealthIssue.CONGENITAL_HEART_DEFECT,
  HealthIssue.CONVULSION_OR_EPILEPSY,
  HealthIssue.DIABETES,
  HealthIssue.FINGER_SUCKING,
  HealthIssue.HANDICAPS_OR_DISABILITIES,
  HealthIssue.HEARING_IMPAIRED,
  HealthIssue.HEART_MURMUR,
  HealthIssue.HEMOPHILIA,
  HealthIssue.HEPATITIS_A_B_OR_C,
  HealthIssue.HIV_POSITIVE_OR_AIDS,
  HealthIssue.JAW_CLICKING,
  HealthIssue.KIDNEY_OR_LIVER_PROBLEMS,
  HealthIssue.LATEX_ALLERGY,
  HealthIssue.METAL_ALLERGIES,
  HealthIssue.PREGNANT,
  HealthIssue.RHEUMATIC_FEVER,
  HealthIssue.SCARLET_FEVER,
  HealthIssue.SMOKING_OR_TOBACCO,
  HealthIssue.TUBERCULOSIS,
];

export function getI18NKey(healthIssue: HealthIssue) {
  switch (healthIssue) {
    case HealthIssue.ABNORMAL_BLEEDING:
      return "HealthIssue.AbnormalBleeding";
    case HealthIssue.ALLERGIES_TO_ANY_DRUGS:
      return "HealthIssue.AllergiesToAnyDrugs";
    case HealthIssue.ANOREXIA_OR_BULIMIA:
      return "HealthIssue.AnorexiaOrBulimia";
    case HealthIssue.ASTHMA:
      return "HealthIssue.Asthma";
    case HealthIssue.CANCER:
      return "HealthIssue.Cancer";
    case HealthIssue.CONGENITAL_HEART_DEFECT:
      return "HealthIssue.CongenitalHeartDefect";
    case HealthIssue.CONVULSION_OR_EPILEPSY:
      return "HealthIssue.ConvulsionOrEpilepsy";
    case HealthIssue.DIABETES:
      return "HealthIssue.Diabetes";
    case HealthIssue.FINGER_SUCKING:
      return "HealthIssue.FingerSucking";
    case HealthIssue.HANDICAPS_OR_DISABILITIES:
      return "HealthIssue.HandicapsOrDisabilities";
    case HealthIssue.HEARING_IMPAIRED:
      return "HealthIssue.HearingImpaired";
    case HealthIssue.HEART_MURMUR:
      return "HealthIssue.HeartMurmur";
    case HealthIssue.HEMOPHILIA:
      return "HealthIssue.Hemophilia";
    case HealthIssue.HEPATITIS_A_B_OR_C:
      return "HealthIssue.HepatitisABOrC";
    case HealthIssue.HIV_POSITIVE_OR_AIDS:
      return "HealthIssue.HivPositiveOrAids";
    case HealthIssue.JAW_CLICKING:
      return "HealthIssue.JawClicking";
    case HealthIssue.KIDNEY_OR_LIVER_PROBLEMS:
      return "HealthIssue.KidneyOrLiverProblems";
    case HealthIssue.LATEX_ALLERGY:
      return "HealthIssue.LatexAllergy";
    case HealthIssue.METAL_ALLERGIES:
      return "HealthIssue.MetalAllergies";
    case HealthIssue.PREGNANT:
      return "HealthIssue.Pregnant";
    case HealthIssue.RHEUMATIC_FEVER:
      return "HealthIssue.RheumaticFever";
    case HealthIssue.SCARLET_FEVER:
      return "HealthIssue.ScarletFever";
    case HealthIssue.SMOKING_OR_TOBACCO:
      return "HealthIssue.SmokingOrTobacco";
    case HealthIssue.TUBERCULOSIS:
      return "HealthIssue.Tuberculosis";
    case HealthIssue.NONE:
      return "HealthIssue.None";
  }
}

export default HealthIssue;
