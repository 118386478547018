export enum DistanceUnit {
  KILOMETERS = "KILOMETERS",
  MILES = "MILES",
}

export interface Distance {
  value: number;
  unit: DistanceUnit;
}

export function toKm(d: Distance) {
  switch (d.unit) {
    case DistanceUnit.MILES:
      return milesToKm(d.value);
    case DistanceUnit.KILOMETERS:
      return d.value;
  }
}

export function displayValue(distanceUnit: DistanceUnit) {
  switch (distanceUnit) {
    case DistanceUnit.MILES:
      return "miles";
    case DistanceUnit.KILOMETERS:
      return "km";
  }
}

export function milesToKm(value: number) {
  return value * 1.60934;
}

export function formatDistance(d: Distance) {
  if (d) {
    if (d.value < 10) return `${d.value.toFixed(1)} ${displayValue(d.unit)}`;
    else return `${d.value.toFixed(0)} ${displayValue(d.unit)}`;
  }
}
