import * as React from "react";
import { ChangeEvent, ReactElement } from "react";
import FormContext from "../FormContext";
import InputProps from "../../../model/form/InputProps";
import InputState from "../../../model/form/InputState";
import InputStatus from "../../../model/form/InputStatus";
import { MessageLevel } from "../../../model/form/Field";

interface FormInputProps extends InputProps {
  template: (state: InputState, formInput) => ReactElement;
  onInputUpdate: (state: InputState) => void;
  onInputUnmount: (inputKey: string) => void;
}

class FormInput extends React.Component<FormInputProps, InputState> {
  private initialValue;

  constructor(props: FormInputProps) {
    super(props);
    this.change = this.change.bind(this);
    this.validate = this.validate.bind(this);
    this.dirty = this.dirty.bind(this);
    this.setError = this.setError.bind(this);
    this.setDisabled = this.setDisabled.bind(this);
    this.initialValue = this.props.initialValue || "";
    this.state = {
      name: props.name,
      value: this.initialValue,
      status: new InputStatus(
        this.setError,
        this.setDisabled,
        this.props.hidden,
        !this.props.dirty,
        this.props.disabled
      ),
      setValue: this.setValue.bind(this),
      hide: this.hide.bind(this),
    };
    this.props.onInputUpdate(this.state);
  }

  componentDidMount(): void {
    this.change();
  }

  componentWillUnmount() {
    this.props.onInputUnmount(this.state.name);
  }

  componentDidUpdate(
    prevProps: Readonly<FormInputProps>,
    prevState: Readonly<InputState>,
    snapshot?: any
  ) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.initialValue = this.props.initialValue || "";
      this.setState({
        ...this.state,
        value: this.initialValue,
      });
    }
  }

  render() {
    return this.props.template(this.state, this);
  }

  private validate(value) {
    if (this.props.validators) {
      for (const validator of this.props.validators) {
        if (validator(value)) {
          return validator;
        }
      }
    }
  }

  private change(e?: ChangeEvent<HTMLInputElement>) {
    if (!e) {
      this.setValue(this.initialValue);
    } else {
      this.setValue(e.target.value);
      this.dirty();
    }
  }

  private setValue(value) {
    const validation = this.validate(value);
    // Set to invalid if validation is not undefined and if the messageLevel is equal to ERROR
    // The errorMessage should not be set if the messageLevel is NONE
    this.customSetState(
      value,
      validation && validation["messageLevel"] === MessageLevel.ERROR, // LongTermFix add warning state, borders should be warning color
      validation && validation["messageLevel"] != MessageLevel.NONE && validation["errorMessage"]
    );
  }

  private hide(value) {
    const status = { ...this.state.status };
    status.hidden = value;
    this.setState({ status });
  }

  private setInvalid(invalid: boolean, errorMessage?: string) {
    this.setState(
      (state) => ({
        ...state,
        status: {
          ...state.status,
          invalid: invalid,
          errorMessage: errorMessage,
        },
      }),
      () => {
        this.props.onInputUpdate(this.state);
        if (this.props.onChange) {
          this.props.onChange(this.state);
        }
      }
    );
  }

  private customSetState(value, invalid: boolean, errorMessage?: string) {
    this.setState(
      (state) => ({
        ...state,
        value: value,
        status: {
          ...state.status,
          invalid: invalid,
          errorMessage: errorMessage,
        },
      }),
      () => {
        this.props.onInputUpdate(this.state);
        if (this.props.onChange) {
          this.props.onChange(this.state);
        }
      }
    );
  }

  private dirty() {
    this.setState((state) => ({
      ...state,
      status: {
        ...state.status,
        pristine: false,
      },
    }));
  }

  // LongTermFix error should not be displayed while the input is focused
  private setError(errorMessage?: string, forceDisplay?: boolean) {
    const patch =
      errorMessage === null
        ? { invalid: false, errorMessage: undefined }
        : { invalid: true, errorMessage: errorMessage };
    this.setState(
      (state) => {
        const status = {
          ...state.status,
          ...patch,
        };
        if (forceDisplay) {
          status.pristine = false;
        }
        return {
          ...state,
          status,
        };
      },
      () => this.props.onInputUpdate(this.state)
    );
  }

  private setDisabled(disabled: boolean) {
    this.setState((state) => ({
      ...state,
      status: { ...state.status, disabled },
    }));
  }
}

interface ConnectedFormInputProps extends InputProps {
  template: (state: InputState, formInput) => ReactElement;
}

class ConnectedFormInput extends React.Component<ConnectedFormInputProps, InputState> {
  render() {
    return (
      <FormContext.Consumer>
        {({ onInputUpdate, onInputUnmount }) => (
          <FormInput
            {...this.props}
            onInputUpdate={onInputUpdate}
            onInputUnmount={onInputUnmount}
          />
        )}
      </FormContext.Consumer>
    );
  }
}

export default ConnectedFormInput;
