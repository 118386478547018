import Event from "../../../../api/Event";
import { technicalFlowError } from "../FlowLauncher";
import sfetch from "../../commons/utils/super-fetch";
import FlowContext, { getPracticeToken } from "../FlowContext";

export default interface DeferredAction {
  getInputs: (flowContext: FlowContext) => (keyof FlowContext)[];
  action: (flowContext: FlowContext) => Promise<Partial<FlowContext>>;
  name: string;
}

const deferredActions: { [name: string]: DeferredAction } = [
  {
    name: "startVirtualConsultationAction",
    getInputs: (flowContext: FlowContext) => {
      let inputs = [
        "reportId",
        "urlReportRoot",
        "answersUploadedTimestamp",
        "photoFaceSubmittedTimestamp",
        "photoMouthClosedSubmittedTimestamp",
        "photoMouthOpenedSubmittedTimestamp",
      ];
      if (flowContext.patientCanSelectPractice) {
        inputs.push("selectedPractice");
      }
      return inputs;
    },
    action: async (flowContext: FlowContext) => {
      try {
        await sfetch(
          `${flowContext.urlReportRoot}/${flowContext.reportId}/start?practiceToken=${getPracticeToken(flowContext)}`,
          {
            method: "POST",
          }
        );
        flowContext.sendEvent(Event.CONSULTATION_SUBMITTED);
        return { analysisStartedTimestamp: new Date().getTime() };
      } catch (e) {
        flowContext.setError(technicalFlowError);
        throw e;
      }
    },
  },
  {
    name: "startSmileSimulationOnlyAction",
    getInputs: (flowContext: FlowContext) => {
      let inputs = ["reportId", "urlReportRoot", "photoFaceSubmittedTimestamp"];
      if (flowContext.patientCanSelectPractice) {
        inputs.push("selectedPractice");
      }
      return inputs;
    },
    action: async (flowContext: FlowContext) => {
      try {
        await sfetch(
          `${flowContext.urlReportRoot}/${flowContext.reportId}/start?practiceToken=${getPracticeToken(flowContext)}`,
          {
            method: "POST",
          }
        );
        flowContext.sendEvent(Event.CONSULTATION_SUBMITTED);
        return { analysisStartedTimestamp: new Date().getTime() };
      } catch (e) {
        flowContext.setError(technicalFlowError);
        throw e;
      }
    },
  },
].reduce((acc, action) => ((acc[action.name] = action), acc), {});
export { deferredActions };
