import * as React from "react";
import "./ProgressBar.scss";

interface ProgressBarProps {
  steps: string[];
  stepsCompleted: string[];
}

export default function ProgressBar({ steps, stepsCompleted }: ProgressBarProps) {
  const remainingSteps = stepsCompleted
    ? steps?.filter((it) => !stepsCompleted.includes(it))
    : steps;
  const progressInPercentage = `${
    remainingSteps?.length !== undefined
      ? 100 - Math.round((remainingSteps.length / steps.length) * 100)
      : 0
  }%`;

  return (
    <div className="progress-bar">
      <div className="bar">
        <div className="progression" style={{ width: `calc(0.5rem + ${progressInPercentage})` }} />
      </div>
      <div className="percentage">{progressInPercentage}</div>
    </div>
  );
}
