export const defaultContainerHeight = () => document.body.clientHeight + 1;
let containerHeight = defaultContainerHeight;

export function getContainerHeight() {
  return containerHeight;
}

export function setContainerHeight(height) {
  containerHeight = height;
}
