import * as React from "react";
import "./FormTitle.scss";

interface FormTitleProps {
  title: string;
}

export default function FormTitle({ title }: FormTitleProps) {
  return <p className="title form-title">{title}</p>;
}
