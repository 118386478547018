import * as React from "react";
import InputProps from "../../../model/form/InputProps";
import FormInput from "./FormInput";
import InputState from "../../../model/form/InputState";
import { buildClasses } from "../../../utils/classes";
import SelectableOption from "../../../model/SelectableOption";
import { sanitize } from "../../../utils/sanitize";

interface RadioInputProps extends InputProps {
  options: SelectableOption[];
  additionalClass?: string;
  flexOrientation: "vertical" | "horizontal";
  tooltip?: React.ReactElement;
}

export default class RadioInput extends React.PureComponent<RadioInputProps> {
  constructor(props: RadioInputProps) {
    super(props);
    this.template = this.template.bind(this);
  }

  template(state: InputState, input) {
    return (
      <div
        className={buildClasses(
          {
            [this.props.additionalClass]: () => !!this.props.additionalClass,
            invalid: () => state.status.invalid && !state.status.pristine,
          },
          "input-group",
        )}
      >
        <div className="radio-container">
          {this.props.label ? (
            <label htmlFor={this.props.name}>
              <span>{this.props.label}</span>
              <span className="detail">{this.props.detail}</span>
            </label>
          ) : null}

          <div
            className={
              (this.props.flexOrientation == "vertical" ? "v-flex" : "h-flex") + " space-between"
            }
          >
            {this.props.options.map((option) => (
              <div
                className={
                  "radio-button" + (this.props.disabled || option.disabled ? " disabled" : "")
                }
                key={option.value}
              >
                <input
                  type="radio"
                  name={this.props.name}
                  id={option.value}
                  onChange={(e) => (
                    input.setValue(e.target.checked ? e.target.id : null), input.dirty()
                  )}
                  onBlur={input.dirty}
                  checked={state.value && state.value == option.value}
                  disabled={this.props.disabled || option.disabled}
                />
                <label htmlFor={option.value}>
                  {typeof option.label == "string" ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sanitize(option.label),
                      }}
                    />
                  ) : (
                    option.label
                  )}
                </label>
                {option.disabled && this.props.tooltip}
              </div>
            ))}
          </div>
          <div className="error-message">{state.status.errorMessage}</div>
        </div>
      </div>
    );
  }

  render() {
    const selectedOption = this.props.options.find((opt) => opt.selected);
    return (
      <FormInput
        {...this.props}
        template={this.template}
        initialValue={selectedOption ? selectedOption.value : null}
      />
    );
  }
}
