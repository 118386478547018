export function groupBy<K, V>(xs: V[], f: (v: V) => K): Map<K, V[]> {
  return xs.reduce(function (groups: Map<K, V[]>, x: V) {
    const key = f(x);
    let group = groups.get(key);
    if (group) {
      group.push(x);
    } else {
      groups.set(key, [x]);
    }
    return groups;
  }, new Map<K, V[]>());
}

export function associateBy<K, V>(xs: V[], key: (v: V) => K): Map<K, V> {
  return xs.reduce(function (acc: Map<K, V>, x: V) {
    acc.set(key(x), x);
    return acc;
  }, new Map<K, V>());
}

export function equals<V>(array1: V[], array2: V[]): boolean {
  return (
    array1.length == array2.length && array1.every((x, i) => array2[i] === x)
  );
}
