import * as React from "react";
import FormInput from "./FormInput";
import HealthIssue, { getI18NKey, healthIssuesOrdered } from "../../../model/HealthIssue";
import InputState from "../../../model/form/InputState";
import { MultiSelectCheckBoxInputTemplate } from "./MultiSelectCheckboxInput";
import ToggleButton from "../../buttons/ToggleButton";
import InputProps from "../../../model/form/InputProps";
import Option from "../../../model/Option";
import SelectableOption from "../../../model/SelectableOption";

export default class HealthIssueCheckboxInput extends React.Component<InputProps> {
  readonly options: Option[];

  constructor(props: InputProps) {
    super(props);
    this.template = this.template.bind(this);
    this.validate = this.validate.bind(this);
    this.validate["errorMessage"] = i18n["Form.Error.AtLeastOneValueIsRequired"];
    this.validate["messageLevel"] = this.props.messageLevel;
  }

  template(state: InputState, input) {
    return (
      <>
        <MultiSelectCheckBoxInputTemplate
          {...this.props}
          options={healthIssuesOrdered.map(
            (healthIssue) =>
              new SelectableOption(
                healthIssue,
                i18n[getI18NKey(healthIssue)],
                state.value.includes(healthIssue)
              )
          )}
          showSelectAllButton={false}
          columnView={true}
          state={state}
          input={input}
        />
        <ToggleButton
          value={state.value.includes(HealthIssue.NONE)}
          label={i18n["Form.HealthHistoryInformation.Field.HealthIssue.None.Title"]}
          onChange={(newValue) => {
            if (newValue) {
              state.setValue([HealthIssue.NONE]);
            } else {
              this.removeNone(state); // To trigger validation
            }
            input.dirty();
          }}
        />
      </>
    );
  }

  validate(value: HealthIssue[]) {
    return this.props.mandatory && value.length === 0;
  }

  render() {
    return (
      <FormInput
        {...this.props}
        template={this.template}
        onChange={(state) => {
          // This condition is important to avoid the infinite loop due to the state.setValue in the onChange method
          if (state.value.includes(HealthIssue.NONE) && state.value.length > 1) {
            this.removeNone(state);
          }
        }}
        validators={[this.validate]}
      />
    );
  }

  private removeNone(state: InputState) {
    state.setValue(
      state.value.filter((healthIssue: HealthIssue) => healthIssue != HealthIssue.NONE)
    );
  }
}
