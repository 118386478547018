import * as React from "react";
import { useEffect, useRef } from "react";
import { sanitize } from "../utils/sanitize";

export default function I18nAction({
  action,
  children,
  className,
}: {
  action: (e) => any;
  children: string;
  className?: string;
}) {
  const ref = useRef<HTMLSpanElement>();
  useEffect(() => {
    ref.current
      .querySelectorAll(".action")
      .forEach((actionNode) => actionNode.addEventListener("click", action));
  }, [children]);

  return (
    <span
      ref={ref}
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitize(children) }}
    />
  );
}
