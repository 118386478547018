import Event from "../../../../api/Event";
import { getGtmEventFromEvent, GTMEventPrefix } from "../../../../api/GTMEvent";
import { getContainerHeight } from "./iframeManagement";

export function sendEvent(event: Event, flowId: String) {
  const prefix = getPrefix(flowId);
  const gtmEvent = getGtmEventFromEvent(event);
  prefix && gtmEvent && sendEventToGTM(`${prefix}${gtmEvent}`);
  prefix && sendEventToTopWindow({ type: `${prefix}event`, event });
}

export function requestContainerResizing(scroll: boolean) {
  sendEventToTopWindow({
    type: "resize",
    height: getContainerHeight()() + "px",
    scroll,
  });
}

function sendEventToGTM(event: String) {
  window["dataLayer"] && window["dataLayer"].push({ event });
}

function sendEventToTopWindow(event: any) {
  window.parent.postMessage(event, "*");
}

function getPrefix(flowId: String) {
  if (
    flowId.startsWith("flow-authenticated-virtual-consultation-full-assessment") ||
    flowId.startsWith("flow-virtual-consultation-full-assessment")
  ) {
    return GTMEventPrefix.FULL_ASSESSMENT;
  } else if (
    flowId.startsWith("flow-authenticated-virtual-consultation-smile-assessment") ||
    flowId.startsWith("flow-virtual-consultation-smile-assessment")
  ) {
    return GTMEventPrefix.SMILE_ASSESSMENT;
  } else if (
    flowId.startsWith("flow-authenticated-virtual-consultation-smile-simulation") ||
    flowId.startsWith("flow-virtual-consultation-smile-simulation")
  ) {
    return GTMEventPrefix.SMILE_SIMULATION;
  }
}

export function getEventForPhoto(photoName: string): Event {
  switch (photoName) {
    case "CLOSED_FRONT":
      return Event.PHOTO_CLOSED_FRONT_UPLOADED;
    case "CLOSED_RIGHT":
      return Event.PHOTO_CLOSED_RIGHT_UPLOADED;
    case "CLOSED_LEFT":
      return Event.PHOTO_CLOSED_LEFT_UPLOADED;
    case "OPENED_TOP":
      return Event.PHOTO_OPENED_TOP_UPLOADED;
    case "OPENED_BOTTOM":
      return Event.PHOTO_OPENED_BOTTOM_UPLOADED;
    case "FACE_SMILING":
      return Event.PHOTO_FACE_SMILING_UPLOADED;
    case "FACE_MOUTH_SLIGHTLY_OPENED":
      return Event.PHOTO_FACE_MOUTH_SLIGHTLY_OPENED_UPLOADED;
  }
}
