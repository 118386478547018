import * as React from "react";
import I18nAction from "../commons/components/I18nAction";
import { fillTemplateString } from "../commons/utils/text-rendering";
import Country from "../commons/model/address/Country";

export interface LegalResource {
  descriptor: ResourceDescriptor;
  documents: LegalDocumentVersion[];
}

export interface LegalDocumentVersion {
  descriptor: DocumentDescriptor;
  version?: string;
}

export interface LegalConsent {
  legalResources: LegalResource[];
  onError: (err) => any;
  country?: Country;
  practiceToken?: string;
}

export enum DocumentDescriptor {
  PRIVACY_POLICY = "PRIVACY_POLICY",
  TERMS_OF_USE = "TERMS_OF_USE",
  TERMS_OF_SALES = "TERMS_OF_SALES",
  INFORMED_CONSENT_AGREEMENT = "INFORMED_CONSENT_AGREEMENT",
  HIPAA_NOTICE = "HIPAA_NOTICE",
  AUTHORIZATION_FOR_RELEASE_OF_HEALTH_INFO = "AUTHORIZATION_FOR_RELEASE_OF_HEALTH_INFO",
}

export enum ResourceDescriptor {
  PRIVACY_POLICY = "PRIVACY_POLICY",
  TERMS_OF_USE = "TERMS_OF_USE",
  TERMS_OF_SALES = "TERMS_OF_SALES",
  INFORMED_CONSENT_AGREEMENT = "INFORMED_CONSENT_AGREEMENT",
  PRIVACY_POLICY_AND_INFORMED_CONSENT_AGREEMENT = "PRIVACY_POLICY_AND_INFORMED_CONSENT_AGREEMENT",
  PRIVACY_POLICY_AND_INFORMED_CONSENT_AGREEMENT_AND_HIPAA_NOTICE = "PRIVACY_POLICY_AND_INFORMED_CONSENT_AGREEMENT_AND_HIPAA_NOTICE",
  PRIVACY_POLICY_AND_INFORMED_CONSENT_AGREEMENT_AND_HIPAA_NOTICE_AND_AUTHORIZATION = "PRIVACY_POLICY_AND_INFORMED_CONSENT_AGREEMENT_AND_HIPAA_NOTICE_AND_AUTHORIZATION",
}

export interface LoadedDocumentInfo {
  descriptor: DocumentDescriptor;
  legalDocumentUrl: string;
  hash: string;
}

export function getConsentLink(
  resource: LegalResource,
  loadedDocuments: Map<DocumentDescriptor, LoadedDocumentInfo>
) {
  let params = {};
  resource.documents.forEach(({ descriptor }) => {
    const loadedDocument = loadedDocuments.get(descriptor);
    if (loadedDocument) {
      const { legalDocumentUrl } = loadedDocument;
      params[`${descriptor}_HREF`] = legalDocumentUrl ? `data-href="${legalDocumentUrl}"` : "";
    }
  });
  return (
    <I18nAction
      action={(e) => {
        window.open(e.target.attributes["data-href"].value, "_blank");
      }}
    >
      {fillTemplateString(i18n[getConsentI18NKey(resource.descriptor)], params)}
    </I18nAction>
  );
}

export function getDocumentHref(
  documentDescriptor: DocumentDescriptor,
  country?: Country,
  version?: string,
  practiceToken?: string
): string {
  return (
    documentUris[documentDescriptor] +
    consentLinkRequestParameters(documentDescriptor, country, version, practiceToken)
  );
}

export function getLegalDocuments(resources: LegalResource[]): LegalDocumentVersion[] {
  return resources.reduce((acc, it) => [...acc, ...it.documents], []);
}

// TODO laurent 2021-06-04: URLs should be provided by the backend
const documentUris = {
  [DocumentDescriptor.PRIVACY_POLICY]: "/legal/privacy-policy.pdf",
  [DocumentDescriptor.TERMS_OF_USE]: "/legal/terms-of-use.pdf",
  [DocumentDescriptor.TERMS_OF_SALES]: "/legal/terms-of-sales.pdf",
  [DocumentDescriptor.INFORMED_CONSENT_AGREEMENT]: "/legal/informed-consent-agreement.pdf",
  [DocumentDescriptor.HIPAA_NOTICE]: "/legal/hipaa-notice.pdf",
  [DocumentDescriptor.AUTHORIZATION_FOR_RELEASE_OF_HEALTH_INFO]:
    "/legal/authorization-for-release-of-health-info.pdf",
};

function consentLinkRequestParameters(
  descriptor: DocumentDescriptor,
  country: Country,
  version: string,
  practiceToken: string
) {
  const requestParams = new URLSearchParams();
  if (country && country.name) {
    requestParams.set("country", country.name);
  }
  if (version) {
    requestParams.set("version", version);
  }
  if (descriptor === DocumentDescriptor.AUTHORIZATION_FOR_RELEASE_OF_HEALTH_INFO && practiceToken) {
    requestParams.set("practiceToken", practiceToken);
  }
  return requestParams.toString() ? `?${requestParams.toString()}` : "";
}

function getConsentI18NKey(resourceDescriptor: ResourceDescriptor) {
  switch (resourceDescriptor) {
    case ResourceDescriptor.PRIVACY_POLICY:
      return "Legal.Consent.PrivacyPolicy";
    case ResourceDescriptor.TERMS_OF_USE:
      return "Legal.Consent.TermsOfUse";
    case ResourceDescriptor.TERMS_OF_SALES:
      return "Legal.Consent.TermsOfSales";
    case ResourceDescriptor.INFORMED_CONSENT_AGREEMENT:
      return "Legal.Consent.InformedConsentAgreement";
    case ResourceDescriptor.PRIVACY_POLICY_AND_INFORMED_CONSENT_AGREEMENT:
      return "Legal.Consent.PrivacyPolicyAndInformedConsentAgreement";
    case ResourceDescriptor.PRIVACY_POLICY_AND_INFORMED_CONSENT_AGREEMENT_AND_HIPAA_NOTICE:
      return "Legal.Consent.PrivacyPolicyAndInformedConsentAgreementAndHipaaNotice";
    case ResourceDescriptor.PRIVACY_POLICY_AND_INFORMED_CONSENT_AGREEMENT_AND_HIPAA_NOTICE_AND_AUTHORIZATION:
      return "Legal.Consent.PrivacyPolicyAndInformedConsentAgreementAndHipaaNoticeAndAuthorization";
  }
}
