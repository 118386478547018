import * as React from "react";
import ModalPopup from "../../commons/components/modal/ModalPopup";

export default function ForceLoginPopupModal({
  goToLogin,
  onClose,
}: {
  goToLogin: () => any;
  onClose: () => any;
}) {
  return (
    <ModalPopup closeModal={onClose} class="confirmation-dialog">
      <div className="title">{i18n["Page.ScanUploader.Login.Popup.Title"]}</div>
      <div className="content">{i18n["Page.ScanUploader.Login.Popup.Content"]}</div>
      <div className="buttons centered">
        <button className="button-primary filled" onClick={goToLogin}>
          {i18n["Page.ScanUploader.Login.Popup.Action"]}
        </button>
      </div>
    </ModalPopup>
  );
}
