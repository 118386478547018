import * as React from "react";
import { buildClasses } from "../../../utils/classes";
import { CustomErrors, MessageLevel } from "../../../model/form/Field";
import InputStatus from "../../../model/form/InputStatus";

interface GenericInputProps {
  name: string;
  type: string;
  value: string;
  label?: string;
  detail?: string;
  placeholder?: string;
  status: InputStatus;
  additionalClass?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  messageLevel?: MessageLevel;
  maxLength?: number;
  extraProps?: any;
  customErrors?: CustomErrors;
}

const GenericInput = (props: GenericInputProps) => {
  return (
    <div
      className={buildClasses(
        {
          [props.additionalClass]: () => !!props.additionalClass,
          invalid: () =>
            (props.status.invalid && !props.status.pristine) || props.customErrors?.isErrors,
          none: () => props.status.hidden,
        },
        "input-group"
      )}
    >
      {props.label && (
        <label htmlFor={props.name}>
          <span>{props.label}</span>
          <span className="detail">{props.detail}</span>
        </label>
      )}
      <div className="group">
        <input
          type="text"
          name={props.name}
          id={props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          value={props.value}
          maxLength={props.maxLength}
          {...props.extraProps}
        />
        {!props.status.invalid &&
          !props.customErrors?.isErrors &&
          !props.status.pristine &&
          !props.disabled && (
            <div className="h-flex centered valid">
              <i className="ic_check" />
            </div>
          )}
      </div>
      <div className="input-accessories">
        {props.customErrors?.isErrors && (
          <div className="error-message"> {props.customErrors.message}</div>
        )}
        {!props.customErrors?.isErrors && (
          <div className="error-message">{props.status.errorMessage}</div>
        )}
        {props.maxLength && (
          <div className="size-limit">
            {props.value.length}/{props.maxLength}
          </div>
        )}
        {!props.status.pristine &&
          !props.status.invalid &&
          (props.messageLevel == MessageLevel.INFO || props.messageLevel == MessageLevel.WARN) && (
            <div
              className={
                props.messageLevel == MessageLevel.WARN ? "warning-message" : "info-message"
              }
            >
              {props.status.errorMessage}
            </div>
          )}
      </div>
    </div>
  );
};

export default GenericInput;
