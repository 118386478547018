export default class InputStatus {
  constructor(
    setError: (errorMessage?: string, forceDisplay?: boolean) => void,
    setDisabled: (disabled: boolean) => void,
    hidden: boolean = false,
    pristine: boolean = true,
    disabled: boolean = false
  ) {
    this.setError = setError;
    this.setDisabled = setDisabled;
    this.hidden = hidden;
    this.pristine = pristine;
    this.disabled = disabled;
  }

  pristine: boolean = true;
  disabled: boolean = false;
  invalid: boolean = false;
  errorMessage?: string;
  hidden: boolean;
  setError: (errorMessage?: string, forceDisplay?: boolean) => void;
  setDisabled: (disabled: boolean) => void;
}
