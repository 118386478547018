import * as React from "react";
import "./EmailConfirmationForm.scss";
import { FormBackLinkProps } from "../../commons/components/form/FormBackLink";
import Form from "../../commons/components/form/Form";
import AsyncAction from "../../AsyncAction";
import EmailInput from "../../commons/components/form/input/EmailInput";
import { required } from "../../commons/model/form/Validators";

export default function EmailConfirmationForm({
  obfuscatedEmails,
  confirmEmail,
  backLink,
}: {
  obfuscatedEmails: string[];
  confirmEmail: (conflictResolution: ConflictResolution) => Promise<any>;
  backLink: FormBackLinkProps;
}) {
  return (
    <Form
      submitLabel={i18n["Global.Next"]}
      submit={(form) =>
        confirmEmail({
          email: form["email"].value,
          decision: ConflictResolutionDecision.EMAIL_ADJUSTED,
        })
      }
      titleLabel={i18n["Page.ScanUploader.PatientInfos.Title"]}
      className="text-form"
      backLink={backLink}
    >
      <div className="email-confirmation-form">
        <div className="description">
          {i18n["Form.VirtualConsultation.EmailConfirmation.Description"]}
        </div>
        <div className="content">
          <div className="emails">
            {obfuscatedEmails?.map((email) => <div key={email}>{email}</div>)}
          </div>
          <AsyncAction
            promise={() =>
              confirmEmail({
                decision: ConflictResolutionDecision.NOT_ME,
              })
            }
            buttonType="link"
            buttonLabel={i18n["Form.VirtualConsultation.EmailConfirmation.Action.NotMe"]}
          />
          <hr className="hr-secondary" />
          <EmailInput
            name="email"
            label={i18n["Form.VirtualConsultation.EmailConfirmation.Input.Label"]}
            placeholder={i18n["Global.EmailAddressPrompt"]}
            validators={[required()]}
          />
        </div>
      </div>
    </Form>
  );
}

export interface ConflictResolution {
  decision: ConflictResolutionDecision;
  email?: string;
}

enum ConflictResolutionDecision {
  NOT_ME = "NOT_ME",
  EMAIL_ADJUSTED = "EMAIL_ADJUSTED",
}
