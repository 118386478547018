import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { v7 as uuidv7 } from "uuid";
import Flow from "../Flow";
import StepId from "../StepId";
import Step, { StepProps } from "../Step";
import sfetch from "../../commons/utils/super-fetch";
import { deferredActions } from "../utils/DeferredAction";
import ExpirationPopupModal from "../utils/ExpirationPopupModal";
import { getLocalStorageAccess } from "../utils/localStorage";
import { technicalFlowError } from "../FlowLauncher";
import FlowContext from "../FlowContext";
import { ReportCreationRequest } from "../../reports/Report";

export default function VirtualConsultationStep(props: StepProps) {
  const [showExpirationPopup, setShowExpirationPopup] = useState<boolean>();
  const [virtualConsultationSteps, setVirtualConsultationSteps] = useState(smileSimSteps);
  const [virtualConsultationDeferredActions, setVirtualConsultationDeferredActions] = useState(
    deferredActions.startSmileSimulationOnlyAction
  );
  const timeoutRef = useRef<number>();
  const setExpirationPopupTimeout = (start: number, delay: number) => {
    const timeout = start + delay * 1000 - new Date().getTime();
    timeoutRef.current = window.setTimeout(() => setShowExpirationPopup(true), timeout);
  };

  useEffect(() => {
    if (props.flowContext.reportCreationTimestamp) {
      setExpirationPopupTimeout(
        props.flowContext.reportCreationTimestamp,
        props.flowContext.expirationTimeoutInSeconds
      );
    }
    if (props.flowContext.virtualConsultationConfiguration.smileAssessment) {
      setVirtualConsultationSteps(smileAndFullAssessmentSteps);
      setVirtualConsultationDeferredActions(deferredActions.startVirtualConsultationAction);
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, []);

  const createReport = async () => {
    try {
      const url = props.flowContext.urlReportRoot;
      const practiceToken = props.flowContext.practiceToken;
      const metadata = {
        "x-dm-browser-user-agent": navigator.userAgent,
        "x-dm-browser-language": navigator.language,
        "x-dm-browser-online": String(navigator.onLine),
        "x-dm-browser-cookie-enabled": String(navigator.cookieEnabled),
      };
      if (navigator["deviceMemory"]) {
        metadata["x-dm-browser-memory"] = navigator["deviceMemory"];
      }
      if (navigator["connection"] && navigator["connection"]["effectiveType"]) {
        metadata["x-dm-browser-connection"] = navigator["connection"]["effectiveType"];
      }
      const res = await sfetch(`${url}?practiceToken=${practiceToken}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Idempotency-Token": uuidv7(),
          ...metadata,
        },
        body: JSON.stringify({
          virtualConsultationConfiguration: props.flowContext.virtualConsultationConfiguration,
          contactInfoId: props.flowContext.selectedPatientId,
        } as ReportCreationRequest),
      });
      const reportId = await res.json();
      return {
        reportId: reportId,
        reportCreationTimestamp: new Date().getTime(),
      };
    } catch (e) {
      props.flowContext.setError(technicalFlowError);
      throw e;
    }
  };

  return (
    <Step
      init={props.flowContext.reportId ? null : createReport}
      {...props}
      outputs={["reportActionSetTimestamp"]}
      showAlreadyHaveAnAccount={true}
    >
      <Flow
        active={true}
        postComplete={props.onComplete}
        stepIds={virtualConsultationSteps}
        switchAction={props.backLink}
        deferredAction={virtualConsultationDeferredActions}
        flowContext={props.flowContext}
        setFlowContext={props.setFlowContext}
        isRootFlow={false}
      />
      {showExpirationPopup && (
        <ExpirationPopupModal
          onClose={() => {
            getLocalStorageAccess(props.flowContext.flowId).resetLocalStorageContext();
            document.location.reload();
          }}
        />
      )}
    </Step>
  );
}

const smileSimSteps = [StepId.PHOTOS_FACE_SUB_STEP];

const smileAndFullAssessmentSteps = [
  StepId.QUESTIONS_SUB_STEP,
  StepId.PHOTOS_FACE_SUB_STEP,
  StepId.PHOTOS_CLOSED_SUB_STEP,
  StepId.PHOTOS_OPENED_SUB_STEP,
];

VirtualConsultationStep.computeInitialSteps = (flowContext: FlowContext) => {
  if (
    flowContext.virtualConsultationConfiguration.smileSimulation &&
    !flowContext.virtualConsultationConfiguration.smileAssessment
  ) {
    return smileSimSteps;
  } else {
    return smileAndFullAssessmentSteps;
  }
};
