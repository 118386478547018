import * as React from "react";
import * as Sentry from "@sentry/browser";
import sfetch from "./commons/utils/super-fetch";
import { createRoot } from "react-dom/client";

interface AsyncActionProps {
  buttonId?: string;
  buttonLabel: string;
  promise: () => Promise<any>;
  onError?: (result) => void;
  onSuccess?: (result) => void;
  buttonType?: string;
  statusResultDurationInMillis?: number;
  disabled?: boolean;
  reset?: boolean;
  onClick?: (e) => any;
  fontIcon?: string;
}

interface AsyncActionState {
  status: Status;
}

enum Status {
  AVAILABLE = "available",
  AWAITING = "awaiting",
  SUCCESS = "success",
  ERROR = "error",
}

export default class AsyncAction extends React.Component<AsyncActionProps, AsyncActionState> {
  private mounted = false;

  static defaultProps = {
    statusResultDurationInMillis: 750,
    disabled: false,
    reset: true,
    buttonType: "button-primary",
  };

  constructor(props: AsyncActionProps) {
    super(props);
    this.state = { status: Status.AVAILABLE };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setState<K extends keyof AsyncActionState>(
    state:
      | ((
          prevState: Readonly<AsyncActionState>,
          props: Readonly<AsyncActionProps>
        ) => Pick<AsyncActionState, K> | AsyncActionState | null)
      | Pick<AsyncActionState, K>
      | AsyncActionState
      | null,
    callback?: () => void
  ) {
    this.mounted && super.setState(state, callback);
  }

  render() {
    return (
      <button
        id={this.props.buttonId}
        onClick={this.onClick}
        type="submit"
        className={`${this.props.buttonType} async-action ${this.state.status}`}
        disabled={this.props.disabled || this.state.status !== Status.AVAILABLE}
      >
        {this.props.fontIcon && <div className={"button-icon " + this.props.fontIcon} />}
        <span>{this.props.buttonLabel}</span>
        {this.state.status == Status.AWAITING && <div className="loader" />}
      </button>
    );
  }

  private onClick = this.props.onClick || ((event) => this.performAsyncAction(event));

  performAsyncAction(event?) {
    // Prevent submitting for form buttons
    if (event) event.preventDefault();
    if (this.state.status === Status.AVAILABLE && this.props.promise) {
      this.props
        .promise()
        .then((result) => {
          if (result && result.ok === false) {
            throw result;
          } else {
            this.setState({ status: Status.SUCCESS });
            return () => this.props.onSuccess && this.props.onSuccess(result);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          this.setState({ status: Status.ERROR });
          return () => this.props.onError && this.props.onError(error);
        })
        .then((callback) => {
          if (this.props.reset) {
            setTimeout(() => {
              this.mounted && this.setState({ status: Status.AVAILABLE }, callback);
            }, this.props.statusResultDurationInMillis);
          } else {
            callback();
          }
        });
      this.setState({ status: Status.AWAITING });
    }
  }

  static sfetch = sfetch;

  static loadComponent(elementId: string, props: AsyncActionProps) {
    const container = document.getElementById(elementId);
    const root = createRoot(container!);
    root.render(<AsyncAction {...props} />);
  }
}
